import { Component, Inject, OnInit, SecurityContext } from "@angular/core";
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Params } from '@angular/router';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BankFeedsServiceProxy } from 'src/shared/service-proxies/api-service-proxies';

@Component({
    selector: "app-configure-bank-feeds",
    templateUrl: "./configure-bank-feeds.component.html",
    styleUrls: ["./configure-bank-feeds.component.scss"],
})
export class ConfigureBankFeedsComponent implements OnInit {
    public feedsConfigurationUrl;
    public safeUrl: SafeUrl;
    public loading: boolean;

    constructor(private feedsServiceProxy: BankFeedsServiceProxy, 
      private _route: ActivatedRoute,
      private sanitizer: DomSanitizer,
      @Inject(MAT_DIALOG_DATA) public businessId: number) {
        this.loading = true;

       
    }

    ngOnInit(): void {
        this.feedsServiceProxy.init(this.businessId)
            .subscribe((data) => {
                this.feedsConfigurationUrl = 'https://apitest.bankfeeds.com.au' + data.iframeUrl;
                this.makeSafe(this.feedsConfigurationUrl);
                this.loading = false;
            });
    }

    makeSafe(url: string) {
      this.safeUrl =  this.sanitizer.bypassSecurityTrustResourceUrl(url);
    }
  
}
