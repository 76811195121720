<div *ngIf="!loading">
    <div class="top-row">
        <!-- <mat-form-field class="search-field">
            <input matInput type="text" placeholder="Search" appearance="outline"
                [(ngModel)]="filter.nameFilter" (keyup)="applyFilter()">
            <button mat-button *ngIf="filter.nameFilter" matSuffix mat-icon-button aria-label="Clear"
                (click)="filter.nameFilter=''; applyFilter();">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </mat-form-field> -->
    </div>
    <mat-card class="card horizontal-card">
        <mat-card-title>
            Bank Accounts
        </mat-card-title>
        <mat-card-actions [align]="'end'">
            <button mat-raised-button color="accent" *ngIf="isBankFeedsEnabled" (click)="configureBankFeedsDialog()">Configure Bank Feeds</button>
        </mat-card-actions>
    </mat-card>
    
            <table mat-table [dataSource]="accountList" multiTemplateDataRows matSort class="account-list mat-elevation-z8">
                <ng-container matColumnDef="name">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Account</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.Id">
                            <span class="account-name">{{ element.name }}</span>
                            <br />
                            <span class="bsb"
                                >BSB: {{ element.bsb }} Account Number:
                                {{ element.accountNumber }}</span
                            >
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Account Type</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.Id">
                            <span class="small-highlight">{{
                                element.typeName
                            }}</span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="bank">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Institution</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.Id">
                            <span class="small-highlight">{{ element.bank }}</span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="feedStatus">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Bank Feeds Status</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.Id">
                            <span class="small-highlight"></span>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="active">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="!element.Id">
                            <mat-checkbox disabled
                                class="example-margin"
                                [(ngModel)]="element.isActive"
                            ></mat-checkbox>
                        </div>
                    </td>
                    <td mat-footer-cell *matFooterCellDef></td>
                </ng-container>
                <ng-container matColumnDef="actionEdit">
                    <th mat-header-cell *matHeaderCellDef width="5%"><button mat-raised-button color="primary" (click)="toggleEditMode()">{{isEditMode ? 'Disable' : 'Enable'}} Edit Mode</button></th>
                    <td mat-cell *matCellDef="let element" class="action-link">
                      <button mat-mini-fab color="accent" *ngIf="isEditMode" (click)="openEditAccountDialog(element)"><fa-icon [icon]="faPencil">Add</fa-icon></button>
                    </td>
                    <td mat-footer-cell *matFooterCellDef>
                        <button mat-mini-fab color="primary" *ngIf="isEditMode" class="add-button" (click)="openNewAccountDialog()"><fa-icon [icon]="faPlus">Add</fa-icon></button>
                    </td>
                  </ng-container>
                  
                <ng-container matColumnDef="expandedDetail">
                    <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
                        <div class="account-detail" [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
                            <div class="grid-container">
                                <div class="apca-number-label">
                                    <span>APAC Number: </span>
                                </div>
                                <div class="eft-export-bank-label">
                                    <span>EFT Export Bank: </span>
                                </div>
                                <div class="is-wheat-loan-label">
                                    <span>Is Wheat Loan:</span>
                                </div>
                                <div class="open-label">
                                    <span>Open Date: </span>
                                </div>
                                <div class="close-label">
                                    <span>Close Date: </span>
                                </div>
                                <div class="apca-number-value">
                                    <span>{{element.apcaNumber}}</span>
                                </div>
                                <div class="eft-export-bank-value">
                                    <span>{{element.eftExportBank}}</span>
                                </div>
                                <div class="is-wheat-loan-value">
                                    <span>{{element.isWheatLoan}}</span>
                                </div>
                                <div class="open-value">
                                    <span>{{ element.openDate | date:'dd/MM/yyyy'}}</span>
                                </div>
                                <div class="close-value">
                                    <span>{{ element.closeDate | date:'dd/MM/yyyy'}}</span>
                                </div>
                            </div>
                        </div>
                    </td>
                </ng-container>
    
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let element; columns: displayedColumns" class="account-row"
                [class.preview-expanded-row]="expandedElement === element"
                (click)="expandedElement = expandedElement === element ? null : element"></tr>
                <tr mat-footer-row *matFooterRowDef="displayedColumns"  [hidden]="!isEditMode"></tr>
                <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="account-detail-row"></tr>
                
            </table>
       
</div>
<div *ngIf="loading" class="loading">
    <fa-icon
        [icon]="faCircleNotch"
        [spin]="true"
        class="loading-spinner"
    ></fa-icon>
</div>
