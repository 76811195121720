<div *ngIf="!loading" class="details-container">
    <mat-toolbar class="second-sticky mat-elevation-z3 banner">
        <div class="horizontal" >
            <span class="business-name">
                <fa-icon [icon]="faBars" class="menu-space" (click)="menu.toggle()"></fa-icon>
            </span>
            <div class="name-container">
                <span class="business-title">{{file.backupDetails.summary.name}}</span>
                <div class="breadcrumbs">
                    <button mat-button (click)="goHome()">
                        <fa-icon [icon]="faHome"></fa-icon>
                        <span class="button-text">Home</span>
                    </button>
                    <span class="separator">/</span>
                    <span>{{fileType}}</span>
                    <span class="separator">/</span>
                    <span>Overview</span>
                </div>
            </div>
        </div>
        <span class="fill-remaining-space"></span>
        <div class="mat-elevation-z2 blue-status-box" *ngIf="fileStatus == FileStatus.BackedUp">
            <fa-icon [icon]="faCloud" class="black-icon file-status-icon"></fa-icon>
            <span class="status-text">Backed Up</span>
        </div>
        <div class="mat-elevation-z2 orange-status-box" *ngIf="fileStatus == FileStatus.Downloading">
            <fa-icon [icon]="faDownload" class="animate-flicker black-icon file-status-icon"></fa-icon>
            <span class="status-text">Downloading</span>
        </div>
        <div class="mat-elevation-z2 orange-status-box" *ngIf="fileStatus == FileStatus.Uploading">
            <fa-icon [icon]="faUpload" class="animate-flicker black-icon file-status-icon"></fa-icon>
            <span class="status-text">Uploading</span>
        </div>
        <div class="mat-elevation-z2 red-status-box" *ngIf="fileStatus == FileStatus.SyncError">
            <fa-icon [icon]="faExclamationTriangle" class="white-icon file-status-icon"></fa-icon>
            <span class="status-text">Sync Error</span>
        </div>
        <div class="mat-elevation-z2 red-status-box" *ngIf="fileStatus == FileStatus.FileInUse">
            <fa-icon [icon]="faUserLock" class="white-icon file-status-icon"></fa-icon>
            <span class="status-text">File In Use</span>
        </div>
        <div class="mat-elevation-z2 grey-status-box" *ngIf="fileStatus == FileStatus.Processing">
            <fa-icon [icon]="faCog" [spin]="true" class="black-icon file-status-icon"></fa-icon>
            <span class="black-status-text">Processing</span>
        </div>
        <div class="mat-elevation-z2 grey-status-box" *ngIf="fileStatus == FileStatus.Verifying">
            <fa-icon [icon]="faBinoculars" class="animate-flicker black-icon file-status-icon"></fa-icon>
            <span class="black-status-text">Verifying</span>
        </div>
        <div class="mat-elevation-z2 red-status-box" *ngIf="fileStatus == FileStatus.ProcessingError">
            <fa-icon [icon]="faExclamationTriangle" class="white-icon file-status-icon"></fa-icon>
            <span class="status-text">Processing Error</span>
        </div>
        <div class="mat-elevation-z2 red-status-box expansion" *ngIf="fileStatus == FileStatus.FileInUse">
            <span class="status-text">By {{inUseByWho}} ({{inUseByWhoFullName}}){{inUseBy}}</span>
        </div>
        <div class="expansion red-status-box" *ngIf="this.isLicenseInjectAttemptMoreThanMax()">
            <span class="status-text">Please contact support in order to create revision.</span>
        </div>
    </mat-toolbar>
    <mat-sidenav-container class="business-container">
        <mat-sidenav mode="side" #menu opened class="navigation-container">
            <ul class="menu">
              <li><a class="menu-item" routerLink="./overview" routerLinkActive="menu-item-selected">Overview</a></li>
            </ul>
            <ul class="menu" *ngIf='isPayrollEnabled'>
                <li><a class="menu-item" routerLink="./payroll" routerLinkActive="menu-item-selected">Payroll</a></li>
            </ul>
            <ul class="menu" *ngIf='isBankAccountManagementEnabled'>
                <li><a class="menu-item" routerLink="./accounts" routerLinkActive="menu-item-selected">Bank Accounts</a></li>
            </ul>
            <ul class="menu" *ngIf='false'>
                <li><a class="menu-item" routerLink="./codes" routerLinkActive="menu-item-selected">Codes</a></li>
            </ul>
            <ul class="menu" *ngIf='false'>
                <li><a class="menu-item" routerLink="./transactons" routerLinkActive="menu-item-selected">Transactions</a></li>
            </ul>
        </mat-sidenav>
        <mat-sidenav-content>
            <div class="content-container">
                <router-outlet></router-outlet>
            </div>
        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
<div *ngIf="loading" class="loading">
    <fa-icon [icon]="faCircleNotch" [spin]="true" class="loading-spinner"></fa-icon>
</div>