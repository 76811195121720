import { Component, OnInit } from "@angular/core";
import { HomeService } from "src/shared/services/home.service";
import {
    faAward,
    faEllipsisV,
    faHome,
    faShareAlt,
    faHistory,
    faInfoCircle,
    faCircleNotch,
    faBars
} from "@fortawesome/pro-regular-svg-icons";
import {
    faTimes,
    faDownload,
    faExclamationTriangle,
    faCloud,
    faUpload,
    faCog,
    faBinoculars,
    faUserLock,
} from "@fortawesome/pro-solid-svg-icons";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { FileService } from "src/shared/services/file.service";
import { StateAwareFile } from "src/shared/models/state-aware-file";
import { Subscription, Observable, from } from "rxjs";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import * as configcat from "configcat-js";
import { AuthService } from 'src/shared/services/auth.service';
import { AppConfigService } from 'src/shared/services/app-config.service';
import { FileStatus } from 'src/shared/models/file-status';

@Component({
    selector: "app-business-details",
    templateUrl: "./business-details.component.html",
    styleUrls: ["./business-details.component.scss"],
})
export class BusinessDetailsComponent implements OnInit {
    public faAward = faAward;
    public faEllipsisV = faEllipsisV;
    public faHome = faHome;
    public faShareAlt = faShareAlt;
    public faHistory = faHistory;
    public faInfoCircle = faInfoCircle;
    public faCircleNotch = faCircleNotch;
    public faBars = faBars;

    public faCloud = faCloud;
    public faTimes = faTimes;
    public faDownload = faDownload;
    public faExclamationTriangle = faExclamationTriangle;
    public faUpload = faUpload;
    public faCog = faCog;
    public faBinoculars = faBinoculars;
    public faUserLock = faUserLock;

    public showNavigation = false;
    public businessId: string;
    public fileType: string;
    public file: StateAwareFile;
    public FileStatus = FileStatus;
    public loading: boolean;
    public fileStatus: FileStatus;
    public inUseByWho: string;
    public inUseBy: string;
    public inUseByWhoFullName: string;
    public isBankAccountManagementEnabled: boolean;
    public isPayrollEnabled: boolean;

    private _statusSubscription: Subscription;
    private _inUseWhoSubscription: Subscription;
    private _inUseWhoFullNameSubscription: Subscription;
    private _inUseHereSubscription: Subscription;
    private configSnackBar: MatSnackBarConfig = {
        panelClass: ["style-bar"],
        duration: 3000,
    };

    constructor(
        public homeService: HomeService,
        private _router: Router,
        private _route: ActivatedRoute,
        private _authService: AuthService,
        private _fileService: FileService,
        private _snackBar: MatSnackBar,
    ) {
        this.loading = true;
        this._route.paramMap.subscribe((params) => {
            this.businessId = params.get("id");
            this.fileType = params.get("type");
            this._fileService
                .getFile(this.businessId, this.fileType)
                .subscribe((result) => {
                    this.file = result;
                    if (this.file) {
                        this._statusSubscription = this.file.syncStatus$.subscribe(
                            (result) => {
                                if (result === FileStatus.Conflict) {
                                    this._snackBar.open(
                                        `File conflict [${this.file.backupDetails.summary.name}] has to be resolved before continuing work on that file`,
                                        null,
                                        this.configSnackBar
                                    );
                                    this.goHome();
                                }

                                this.fileStatus = result;
                            }
                        );

                        this._inUseWhoSubscription = this.file.inUseByWho$.subscribe(
                            (result) => {
                                this.inUseByWho = result;
                            }
                        );

                        this._inUseHereSubscription = this.file.inUseHere$.subscribe(
                            (result) => {
                                if (result) {
                                    this.inUseBy = " on this computer";
                                } else {
                                    this.inUseBy = " on another computer";
                                }
                            }
                        );

                        this._inUseWhoFullNameSubscription = this.file.inUseByWhoFullName$.subscribe(
                            (result) => {
                                this.inUseByWhoFullName = result;
                            }
                        );

                        this.loading = false;
                        this.homeService.homeParams.next({
                            homeTitle: '',
                        });

                        if (this._authService.toggleClient) {
                            from(
                                this._authService.toggleClient.getValueAsync(
                                    "bankaccountmanagementenabled",
                                    false,
                                    this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
                                )
                            ).subscribe((result) => {
                                this.isBankAccountManagementEnabled = result;
                            });
                
                            from(
                                this._authService.toggleClient.getValueAsync(
                                    "payrollEnabled",
                                    false,
                                    this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
                                )
                            ).subscribe((result) => {
                                this.isPayrollEnabled = result;
                            });
                            
                        } else {
                            this._authService.toggleClient = configcat.createClientWithAutoPoll(
                                AppConfigService.settings.configCat,
                                {
                                    pollIntervalSeconds: AppConfigService.settings.configInterval
                                });
            
                            from(
                                this._authService.toggleClient.getValueAsync(
                                    "bankaccountmanagementenabled",
                                    false,
                                    this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
                                )
                            ).subscribe((result) => {
                                this.isBankAccountManagementEnabled = result;
                            });
                          
                            from(
                                this._authService.toggleClient.getValueAsync(
                                    "payrollEnabled",
                                    false,
                                    this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
                                )
                            ).subscribe((result) => {
                                this.isPayrollEnabled = result;
                            });
                        }
                    }
                });
        });
    }

    ngOnInit() {}

    public goHome() {
        this._router.navigateByUrl('/home');
    }

    public isLicenseInjectAttemptMoreThanMax(): boolean{
        let result: boolean = false;
        if(!this.file.currentSummary.isLicenseInjectionSuccessful 
            && this.file.currentSummary.licenseInjectionAttemptCount > 3)
            {
                result = true;
            }
        return result;
    }
}
