import { Component, OnInit, Input } from '@angular/core';
import { Messages } from 'src/shared/models/messages';

@Component({
  selector: 'app-purchase-complete',
  templateUrl: './purchase-complete.component.html',
  styleUrls: ['./purchase-complete.component.scss']
})
export class PurchaseCompleteComponent implements OnInit {

  @Input() public invoiceUrl: string;
  @Input("productCode") public productCode:string;
  @Input("paymentIsPending") public paymentIsPending: boolean;
  @Input("purchaserIsStaffMember") public purchaserIsStaffMember: boolean;
  @Input("onBehalfOfClient") public onBehalfOfClient: boolean;

  public shopReturnUrl: string;
  public purchaseComplete: string = Messages.confirmationAndNextSteps;
  public adviseCustomerText: string = Messages.adviseCustomer;
  public adviseCustomerSteps: string = Messages.adviseCustomerSteps;

  ngOnInit(): void {
    this.shopReturnUrl = Messages.shopReturnUrlSuccess.replace("<PRODUCTCODE>", this.productCode);
    
    if(this.onBehalfOfClient){
      if(this.paymentIsPending){
        this.purchaseComplete = Messages.confirmationPurchasedByStaffPendingPayment;
        this.adviseCustomerText = Messages.adviceCustomerPendingOnly;
      }
      else{
        this.purchaseComplete = Messages.confirmationPurchasedByStaffPaidByCard;
        this.adviseCustomerText = Messages.adviseCustomer;
      }
    }
    else
    {
      if(this.paymentIsPending)
      {
        this.purchaseComplete = Messages.confirmationPurchasedByStaffPendingPayment;
      }
    }

    
  }
}
