<mat-card>
    <mat-card-title><fa-icon [icon]="faHistory" class="file-status"></fa-icon><span class="header-text">Restore File</span></mat-card-title>
    <mat-card-content>
        <div>
            <div class="container">
                <p>You are going to restore this file from {{file.modifiedDate.fromNow()}}</p>
                <p>Any changes to your current file <span class="text-bold">will be overwritten</span></p>
                <mat-checkbox [color]="'warn'" [(ngModel)]="accepted">I understand and wish to restore this file</mat-checkbox>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!accepted || waitingRequest" aria-label="Submit" (click)="onOpen()">
            Restore
        </button>
    </mat-card-actions>
</mat-card>