<mat-card class="card">
    <mat-card-title><fa-icon [icon]="faKey" class="file-status"></fa-icon><span class="header-text">Change Password</span></mat-card-title>
    <mat-card-content>
        <div>
            <h2 class="top">Your new password must:</h2>
            <ul>
                <li [ngClass]="{'success' : meetsLengthRequirement}">be at least {{maxLength}} characters long</li>
                <li [ngClass]="{'success' : meetsCharacterRequirement}">contain at least 1 capital letter</li>
                <li [ngClass]="{'success' : meetsNumericalRequirement}">contain at least 1 number</li>
            </ul>
            <form class="form-box" [formGroup]="form">
                <mat-form-field appearance="outline">
                    <mat-label>Existing Password</mat-label>
                    <input id="existingPassword" [type]="existingHide ? 'password' : 'text'" matInput formControlName="existingPassword" value="" name="old" autocomplete="current-password">
                    <button id="toggleShowExistingPassword" mat-button matSuffix mat-icon-button aria-label="Show Existing"
                        (click)="existingHide = !existingHide">
                        <fa-icon [icon]="existingHide ? faEye : faEyeSlash"></fa-icon>
                    </button>
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>New Password</mat-label>
                    <input id="newPassword" [type]="newHide ? 'password' : 'text'" matInput formControlName="newPassword" value="" name="new" autocomplete="new-password">
                    <button id="toggleShowNewPassword" mat-button matSuffix mat-icon-button aria-label="Show New"
                        (click)="newHide = !newHide">
                        <fa-icon [icon]="newHide ? faEye : faEyeSlash"></fa-icon>
                    </button>
                </mat-form-field>
                <password-strength-meter [password]="form.value.newPassword" [enableFeedback]="true" class="bottom-margin"></password-strength-meter>
                <mat-form-field  appearance="outline">
                    <mat-label>Confirm Password</mat-label>
                    <input id="confirmPassword" [type]="confirmHide ? 'password' : 'text'" matInput formControlName="confirmPassword" value="" name="confirm" autocomplete="new-password">
                    <button id="toggleShowConfirmPassword" mat-button matSuffix mat-icon-button aria-label="Show Confirm"
                        (click)="confirmHide = !confirmHide">
                        <fa-icon [icon]="confirmHide ? faEye : faEyeSlash"></fa-icon>
                    </button>
                    <mat-error>Passwords must match</mat-error>
                </mat-form-field>
            </form>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button id="cancelChangePassword" mat-button (click)="goHome()">Cancel</button>
        <button id="submitChangePassword" mat-raised-button color="primary" [disabled]="!form.valid || !form.value.confirmPassword || !form.value.newPassword || !form.value.existingPassword" (click)="confirmAndGoHome()">Submit</button>
    </mat-card-actions>
</mat-card>