  export namespace SignalRConsts {
    export const login = 'login';
    export const onLoggedIn = 'loggedIn';

    export const connect = 'connect';
    export const onConnected = 'connected';

    export const onDeleteFile = 'deleteFile';

    export const registerFileForNotifications = 'registerFileForNotifications';
    export const onFileRegisteredForNotifications = 'fileRegisteredForNotifications';

    export const openFile = 'openFile2';
    export const onFileOpened = 'fileOpened';

    export const fileUploadComplete = 'fileUploadComplete2';
    export const onFileUploadComplete = 'fileUploaded';

    export const fileUploadSkipped = 'fileUploadSkipped2';
    export const onFileUploadSkipped = 'fileSkipped';

    export const closeFile = 'closeFile2';
    export const onFileClosed = 'fileClosed';

    export const closeReadOnlyFile = 'closeReadOnlyFile2';
    export const onReadOnlyFileClosed = 'readOnlyFileClosed';

    export const addUserToShare = 'addUserShare2';
    export const onUserAddedToShare = 'shareAdded';

    export const revokeUserShare = 'revokeUserShare2';
    export const onUserShareRevoked = 'shareRevoked';

    export const modifyUserShare = 'modifyUserShare2';
    export const onUserShareModified = 'shareModified';

    export const userRejectedShare = 'userRejectedShare';
    export const onUserRejectedShare = 'shareRejected';

    export const addRevisionNote = 'addRevisionNote';
    export const onRevisionNoteAdded = 'revisionNoteAdded';        

    export const onDownloading = 'downloading';
    export const onUploading = 'uploading';
    export const onBackedUp = 'backedup';
    export const onProcessing = 'processing';
    export const onSyncError = 'syncerror';
    export const onOffline = 'offline';
    export const onLocked = 'locked';
    export const onLinked = 'linked';
    export const onConflict = 'conflict';
    export const onProcessingError = 'processingError';
    export const onNormalisationFailed = 'normalisationFailed';
    export const onNormalisationComplete = 'normalisationComplete';
}

export type SignalRMethod = typeof SignalRConsts.login | typeof SignalRConsts.connect | typeof SignalRConsts.openFile
    | typeof SignalRConsts.closeFile | typeof SignalRConsts.registerFileForNotifications
    | typeof SignalRConsts.fileUploadComplete | typeof SignalRConsts.fileUploadSkipped | typeof SignalRConsts.addUserToShare
    | typeof SignalRConsts.revokeUserShare | typeof SignalRConsts.modifyUserShare
    | typeof SignalRConsts.closeReadOnlyFile | typeof SignalRConsts.addRevisionNote
    | typeof SignalRConsts.userRejectedShare;

export type SignalREvents = typeof SignalRConsts.onLoggedIn | typeof SignalRConsts.onFileOpened
    | typeof SignalRConsts.onFileClosed | typeof SignalRConsts.onConnected
    | typeof SignalRConsts.onFileUploadComplete | typeof SignalRConsts.onFileUploadSkipped
    | typeof SignalRConsts.onFileRegisteredForNotifications | typeof SignalRConsts.onUserAddedToShare 
    | typeof SignalRConsts.onUserShareRevoked | typeof SignalRConsts.onUserShareModified
    | typeof SignalRConsts.onReadOnlyFileClosed | typeof SignalRConsts.onRevisionNoteAdded
    | typeof SignalRConsts.onUserRejectedShare | typeof SignalRConsts.onDownloading 
    | typeof SignalRConsts.onUploading | typeof SignalRConsts.onSyncError
    | typeof SignalRConsts.onProcessing | typeof SignalRConsts.onBackedUp | typeof SignalRConsts.onDeleteFile
    | typeof SignalRConsts.onLocked | typeof SignalRConsts.onOffline | typeof SignalRConsts.onLinked | typeof SignalRConsts.onConflict | typeof SignalRConsts.onProcessingError | typeof SignalRConsts.onNormalisationFailed
    | typeof SignalRConsts.onNormalisationComplete;

export type SignalRListener = (data: any) => void;
