<mat-card>
    <mat-card-title>
        <fa-icon [icon]="faCheck" class="file-status"></fa-icon>
        <span class="header-text">Select Code</span>
    </mat-card-title>
    <mat-card-content>
        <div class="container">
            <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="code-tree">
                <!-- This is the tree node template for leaf nodes -->
                <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
                    <li class="mat-tree-node clickable" (click)="onSelect(node.code)">
                        <!-- use a disabled button to provide padding for tree leaf -->
                        <button mat-icon-button disabled>
                            <fa-icon [icon]="faCheck" *ngIf="node.id === selectedCode.id"></fa-icon>
                        </button>
                        {{node.name}}
                    </li>
                </mat-tree-node>
                <!-- This is the tree node template for expandable nodes -->
                <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
                    <li>
                        <div class="mat-tree-node">
                            <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name">
                                <fa-icon [icon]="faChevronRight" *ngIf="treeControl.isExpanded(node)"></fa-icon>
                                <fa-icon [icon]="faChevronDown" *ngIf="!treeControl.isExpanded(node)"></fa-icon>
                            </button>
                            {{node.name}}
                        </div>
                        <ul [class.code-tree-invisible]="!treeControl.isExpanded(node)">
                            <ng-container matTreeNodeOutlet></ng-container>
                        </ul>
                    </li>
                </mat-nested-tree-node>
            </mat-tree>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" aria-label="Submit" (click)="onSave()">
            Ok
        </button>
    </mat-card-actions>
</mat-card>