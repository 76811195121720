import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, of, throwError } from 'rxjs';
import { User } from '../models/user';
import { catchError, map } from 'rxjs/operators';
import { User as ConfigUser } from "configcat-common/lib/RolloutEvaluator";
import { IConfigCatClient } from "configcat-common/lib/ConfigCatClient";
import * as configcat from "configcat-js";
import { AppConfigService } from './app-config.service';
import { ProfileServiceProxy, UserProfile } from '../service-proxies/api-service-proxies';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private _currentUserSubject$: BehaviorSubject<User>;
  private _inProgress$ = new BehaviorSubject<boolean>(false);
  private _hasError$ = new BehaviorSubject<boolean>(false);
  public error: string;
  public toggleClient: IConfigCatClient;
  public token: string;

  constructor(
    //private _profileService: ProfileServiceProxy,
    private _router: Router,
    private _injector: Injector) {
    if (AppConfigService.settings) {
      this.toggleClient = configcat.createClientWithAutoPoll(
        AppConfigService.settings.configCat,
        {
            pollIntervalSeconds: AppConfigService.settings.configInterval
        });
    }

    this._currentUserSubject$ = new BehaviorSubject<User>(
      JSON.parse(localStorage.getItem("currentUser"))
    );
    this._inProgress$.next(false);
    this._hasError$.next(false);
  }

  public get inProgress$(): Observable<boolean> {
    // Pipe the behaviour subject through a map function to force it to read only.
    return this._inProgress$.pipe(map(f => f));
  }

  public get hasError$(): Observable<boolean> {
    // Pipe the behaviour subject through a map function to force it to read only.
    return this._hasError$.pipe(map(f => f));
  }

  public get currentUser$(): Observable<User> {
    // Pipe the behaviour subject through a map function to force it to read only.
    return this._currentUserSubject$.pipe(map((f) => f));
  }

  public get currentUserValue(): User {
    return this._currentUserSubject$.value;
}

  public get loggedIn(){
    return this.currentUserValue != null;
  }

  public logOut(){
    localStorage.removeItem('currentUser');
    this._currentUserSubject$.next(null);
  }

  public logIn(username: string, password: string, returnUrl: string){
    this._inProgress$.next(true);
    this._hasError$.next(false);
    this.error = '';

    const profileService = this._injector.get(ProfileServiceProxy);
    profileService.fileManagerToken(username, password).pipe(
      catchError(err => {
        this._inProgress$.next(false);
        this.error = 'The user number or password entered is incorrect.';
        this._hasError$.next(true);
        return throwError(err);
      }))
      .subscribe((response: any) => {
        this.logInWithToken(response, username, password, returnUrl);
        });
  }

  public logInWithToken(token: string, userName?: string, password?: string, returnUrl?: string){
    const profileService = this._injector.get(ProfileServiceProxy);
    this.token = token;
    profileService.profile(null)
            .pipe(
                catchError((err) => {
                    this._inProgress$.next(false);
                    this.error = "Could get profile for user";
                    this._hasError$.next(true);
                    //this.currentUserValue = null;
                    return throwError(err);
                })
            )
            .subscribe((response: UserProfile) => {
                this.getProfile(
                    response,
                    userName ?? "",
                    password ?? "",
                    this.token,
                    returnUrl ?? ""
                );
            });
  }

private getProfile(
  profile: UserProfile,
  username: string,
  password: string,
  token: string,
  returnUrl: string
) {
  let user: User = {
      id: profile.userId,
      userName: username,
      password: password,
      userProfile: profile,
      token: token,
      isOffline: false,
      configUser: this.getConfigToggleUser(profile, ""),
  };
  localStorage.setItem("currentUser", JSON.stringify(user));
  this._currentUserSubject$.next(user);
  this._router.navigateByUrl(returnUrl);
  this._inProgress$.next(false);
}

public getConfigToggleUser(profile: UserProfile, subscription: string): ConfigUser {
  var userObject = {
      identifier: profile.userName, // required
      custom: {
          userType: profile.userType,
          state: profile.state,
          city: profile.city,
          subscription: subscription
      },
  };

  return userObject;
}

public getSubscriptionSpecificConfigToggleUser(subscription: string): ConfigUser {
  return this.getConfigToggleUser(this.currentUserValue.userProfile, subscription);
}

}

