import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { StateAwareFile } from "src/shared/models/state-aware-file";
import { BackupType } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';

@Component({
    selector: "app-open-read-only",
    templateUrl: "./open-read-only.component.html",
    styleUrls: ["./open-read-only.component.scss"],
})
export class OpenReadOnlyComponent implements OnInit {
    public faExclamationTriangle = faExclamationTriangle;
    public file: StateAwareFile;
    public accepted = false;

    constructor(
        public dialogRef: MatDialogRef<OpenReadOnlyComponent>,
        private _authService: AuthService,
        @Inject(MAT_DIALOG_DATA) public data: StateAwareFile
    ) {
        this.file = data;
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onOpen(): void {
        let subscriptionId = 0;

        if(this.file.backupDetails.summary.subscriptionId)
        {
            subscriptionId = this.file.backupDetails.summary.subscriptionId;
        }

        if (this.file.backupDetails.summary.fileType == BackupType.Agrimaster) {
            window.open(
                "agrimaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    this.file.backupDetails.summary.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(this.file.backupDetails.summary.name) +
                    "/ReadOnly",
                "_self"
            );
        } else {
            window.open(
                "wagemaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    this.file.backupDetails.summary.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(this.file.backupDetails.summary.name) +
                    "/ReadOnly",
                "_self"
            );
        }
        this.dialogRef.close();
    }

    ngOnInit() {}
}
