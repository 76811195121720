import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { NgModel } from '@angular/forms';
import { CountdownComponent, CountdownConfig, CountdownEvent } from 'ngx-countdown';
import { EmailRequest, ProfileServiceProxy, RegistrationCodeRequest, UserAccountRequest, UserProfile } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';
import { AppConfigService } from 'src/shared/services/app-config.service';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { Subscription, lastValueFrom } from 'rxjs';
import { ClientService } from 'src/shared/services/client.service';

@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})
export class CreateUserComponent implements OnChanges, OnDestroy {
  @Input() parentUserName: string | undefined = undefined;

  @Output() userCreateSuccess: EventEmitter<UserProfile> = new EventEmitter();
  @ViewChild('email') emailInput: NgModel;
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;
  public faCircleNotch = faCircleNotch;
  public isStaff: boolean;
  public emailDbValidated: boolean;
  public emailVerified: boolean;
  public validateIsDisabled: boolean;
  public verifiedIsDisabled: boolean;
  public captchaPass: boolean;
  public registerDisabled: boolean;
  public emailDisabled: boolean;
  public userEmail: string;
  public userEmailTested: string;
  public firstName: string;
  public lastName: string;
  public mobile: string;
  public emailCode: string;
  public validationError: string;
  public codeVerificationGuid: string;
  public captchaCode: string;
  public creationError: string;
  public config: CountdownConfig = { leftTime: 30 };
  private readonly subscriptions: Subscription[] = [];

  constructor(private _authService: AuthService, private _profileServiceProxy: ProfileServiceProxy, private _clientService: ClientService) {
    this.isStaff = false;
    this.registerDisabled = false;
    this.captchaPass = false;
    this.validateIsDisabled = false;
    this.verifiedIsDisabled = false;
    this.emailDbValidated = false;
    this.emailVerified = false;
    this.emailDisabled = false;
    this.captchaCode = AppConfigService.settings.captchaSiteKey;
    if (this._authService.currentUserValue) {
      this.isStaff = true;
    }

    this.subscribeClientSearchResult();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputParentUserName = changes['parentUserName'];

    if (inputParentUserName) {
      this.parentUserName = inputParentUserName.currentValue as string;
    }
  }

  subscribeClientSearchResult() {
    this.subscriptions.push(
      this._clientService.clientSearchError$.subscribe({
        next: () => {
          this.resetControl();
        }
      })
    );

    this.subscriptions.push(
      this._clientService.clientSearchResult$.subscribe({
        next: () => {
          this.resetControl();
        }
      })
    );
  }

  public async onSubmit() {
    try {
      this.registerDisabled = true;
      this.creationError = undefined;
      let mobile = '04' + this.mobile;
      if (!this.isStaff) {
        let userAccountRequest = new UserAccountRequest();
        userAccountRequest.emailAddress = this.userEmailTested;
        userAccountRequest.firstName = this.firstName;
        userAccountRequest.lastName = this.lastName;
        userAccountRequest.mobile = mobile;
        userAccountRequest.registrationCodeGuid = this.codeVerificationGuid;
        if (this.parentUserName) {
          userAccountRequest.parentUserName = this.parentUserName;
        }
        let newUser = await lastValueFrom(this._profileServiceProxy.createNewUserAccount(userAccountRequest));
        this._authService.logInWithToken(newUser.userToken, undefined, undefined, '/home');
      } else {
        let userAccountRequest = new UserAccountRequest();
        userAccountRequest.emailAddress = this.userEmail;
        userAccountRequest.firstName = this.firstName;
        userAccountRequest.lastName = this.lastName;
        userAccountRequest.mobile = mobile;
        if (this.parentUserName) {
          userAccountRequest.parentUserName = this.parentUserName;
        }
        let newUser = await lastValueFrom(this._profileServiceProxy.createNewUserAccountFromStaff(userAccountRequest));
        this.userCreateSuccess.emit(newUser);
        this.emailDisabled = true;
        this.validationError = undefined;
        this.emailDbValidated = false;
        this.emailVerified = false;
        this.captchaPass = false;
        this.validateIsDisabled = true;
        this.codeVerificationGuid = undefined;
        this.creationError = undefined;
        this.registerDisabled = false;
      }
    } catch (ex: any) {
      this.registerDisabled = false;
      this.creationError = 'Could not create new user profile';
      console.log(ex);
    }
  }

  public isRegisterDisabled() {
    if (this.registerDisabled) {
      return true;
    }

    if (
      this.emailDbValidated &&
      (this.emailVerified || this.isStaff) &&
      this.firstName &&
      this.lastName &&
      this.mobile?.length === 8 &&
      this.userEmailTested === this.userEmail &&
      (this.isStaff || this.captchaPass)
    ) {
      return false;
    }
    return true;
  }

  public async validateEmail() {
    this.emailVerified = false;
    this.emailDbValidated = false;
    this.validateIsDisabled = true;
    this.validationError = undefined;
    var emailRequest = new EmailRequest();
    emailRequest.emailAddress = this.userEmail;
    this.userEmailTested = this.userEmail;

    //Validate if email exists in database
    try {
      await lastValueFrom(this._profileServiceProxy.validateNewUserEmailAddress(emailRequest));
    } catch (exception: any) {
      this.userEmailTested = this.userEmail;
      this.emailDbValidated = false;
      this.validateIsDisabled = false;
      this.validationError = exception.response;
      return;
    }

    if (!this.isStaff) {
      await this.resendCode();
    }
    {
      this.emailDbValidated = true;
      this.validateIsDisabled = false;
    }
  }

  public async verifyCode() {
    try {
      this.verifiedIsDisabled = true;
      var body = new RegistrationCodeRequest();
      body.emailAddress = this.userEmail;
      body.registrationCode = this.emailCode;
      body.guid = this.codeVerificationGuid;
      await lastValueFrom(this._profileServiceProxy.validateNewUserRegistrationCode(body));
      this.emailVerified = true;
      this.emailDbValidated = true;
      this.emailCode = undefined;
    } catch (exception: any) {
      this.verifiedIsDisabled = false;
      this.codeVerificationGuid = undefined;
      this.emailDbValidated = false;
      this.emailVerified = false;
      this.validateIsDisabled = false;
      this.validationError = exception.response;
      this.emailCode = undefined;
      return;
    }
  }

  public async resendCode() {
    var emailRequest = new EmailRequest();
    emailRequest.emailAddress = this.userEmail;
    this.userEmailTested = this.userEmail;
    //Create code validation
    try {
      this.verifiedIsDisabled = false;
      const response = await lastValueFrom(this._profileServiceProxy.generateNewUserRegistrationCode(emailRequest));
      this.codeVerificationGuid = response.guid;
      this.config.leftTime = response.registrationCodeExpiryInSeconds;
      this.validateIsDisabled = false;
      this.emailDbValidated = true;
      this.countdown.config = this.config;
      this.countdown.stop();
      this.countdown.restart();
    } catch (exception: any) {
      this.emailDbValidated = false;
      this.validateIsDisabled = false;
      this.validationError = exception.response;
      return;
    }
  }

  public handleEvent(e: CountdownEvent) {
    if (e.action === 'done') {
      this.verifiedIsDisabled = true;
    }
  }

  public onVerify(token: string) {
    this.captchaPass = true;
  }

  public onExpired(response: any) {
    this.captchaPass = false;
  }

  public onError(error: any) {
    this.captchaPass = false;
  }

  public resetControl() {
    this.validationError = undefined;
    this.emailDbValidated = false;
    this.emailVerified = false;
    this.captchaPass = false;
    this.validateIsDisabled = false;
    this.userEmail = undefined;
    this.firstName = undefined;
    this.lastName = undefined;
    this.mobile = undefined;
    this.codeVerificationGuid = undefined;
    this.registerDisabled = false;
    this.creationError = undefined;
    this.emailDisabled = false;
    if (this.emailInput) {
      this.emailInput.reset();
    }
  }

  ngOnDestroy(): void {
    this.resetControl();
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
