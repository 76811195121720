<mat-card class="card">
    <mat-card-title>Account Recovery</mat-card-title>
    <mat-card-subtitle *ngIf="!loading">Fill in the form below and we will email you a new password</mat-card-subtitle>
    <mat-card-content>
        <div *ngIf="!loading" class="text-box">
            <form class="form-box" [formGroup]="form">
                <mat-form-field appearance="outline">
                    <mat-label>User Number</mat-label>
                    <input id="userName" matInput formControlName="userName" value="" name="userName">
                </mat-form-field>
                <mat-form-field  appearance="outline">
                    <mat-label>Email</mat-label>
                    <input id="emailAddress" matInput formControlName="emailAddress" value="" name="emailAddress">
                </mat-form-field>
            </form>
        </div>
        <div *ngIf="loading" class="loading">
            <fa-icon [icon]="faCircleNotch" [spin]="true" class="loading-spinner"></fa-icon>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'" *ngIf="!loading">
        <button mat-button (click)="this.returnToLogin()">Cancel</button>
        <button id="forgotPassword" mat-raised-button color="primary" (click)="this.goToConfirmation()" class="button-action">Send</button>
    </mat-card-actions>
</mat-card>