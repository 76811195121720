import { Component, OnInit } from "@angular/core";
import { faGift } from "@fortawesome/pro-regular-svg-icons";

@Component({
    selector: "app-update-installed",
    templateUrl: "./update-installed.component.html",
    styleUrls: ["./update-installed.component.scss"],
})
export class UpdateInstalledComponent implements OnInit {
    public faGift = faGift;

    constructor() {}

    ngOnInit(): void {}

    public reload(): void {
        window.location.reload();
    }
}
