<div *ngFor="let product of products" class="product">
  <mat-card routerLink="{{product.code}}">
    <mat-card-header>
        <mat-card-title>{{product.name}}</mat-card-title>
    </mat-card-header>
    <mat-card-content>
        <p>
            {{ product.price  | currency:'AUD':'symbol-narrow' }}
        </p>
    </mat-card-content>
</mat-card>
</div>
