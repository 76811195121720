import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Business, BusinessLookupServiceProxy, BusinessNumberResponse, BusinessServiceProxy } from '../service-proxies/api-service-proxies';

@Injectable({
  providedIn: 'root'
})
export class BusinessService implements OnDestroy {

  private _abnSearchResult$: BehaviorSubject<BusinessNumberResponse> = new BehaviorSubject<BusinessNumberResponse>(null);
  private _abnError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private _unlicensedBusiness$: BehaviorSubject<Business[]> = new BehaviorSubject<Business[]>([]);


  public get abnSearchResult$(): Observable<BusinessNumberResponse>{
    return this._abnSearchResult$.pipe(map((f) => f));
  }

  public get abnError$(): Observable<boolean>{
    return this._abnError$.pipe(map((f)=> f));
  }

  public get unlicensedBusiness$(): Observable<Business[]>{
    return this._unlicensedBusiness$.pipe(map((f)=> f));
  }

  constructor(
    private _lookupService: BusinessLookupServiceProxy,
    private _businessServiceProxy: BusinessServiceProxy) 
    {
    }  

  ngOnDestroy(): void {
    this._abnSearchResult$.next(null);
    this._abnError$.next(false);
    this._unlicensedBusiness$.next([]);
  }

  searchAbn(abn:string){
    this._abnError$.next(false);

    this._lookupService.abn(abn).subscribe({
      next:(businessNumberResponse)=>{
        this._abnSearchResult$.next(businessNumberResponse);
        this._abnError$.next(false);
      },
      error:()=>{
        this._abnError$.next(true);
      }
    })
  }

  unlicensed(userName:string){
    this._businessServiceProxy.unlicensed(userName).subscribe({
      next:(unlicensedBusiness: Business[])=>{
        this._unlicensedBusiness$.next(unlicensedBusiness);
      }
    })
  }
}
