import { FileStatus } from '../models/file-status';
import { ITrackedFile, TrackedFile } from '../models/tracked-file';
import { BehaviorSubject, Observable } from "rxjs";
import { BackupFile, BackupFileDetails, IBackupFileDetails, SharingPermission } from '../service-proxies/api-service-proxies';
import { ShareStatus } from './share-status';

export class StateAwareFile extends TrackedFile {
    private _syncStatus$ = new BehaviorSubject<FileStatus>(
        FileStatus.Verifying
    );
    private _inUseByWho$ = new BehaviorSubject<string>("");
    private _inUseByWhoFullName$ = new BehaviorSubject<string>("");
    private _inUseHere$ = new BehaviorSubject<boolean>(false);
    private _sharingList$ = new BehaviorSubject<SharingPermission[]>([]);
    private _fileHistory$ = new BehaviorSubject<BackupFile[]>([]);
    private _summary$ = new BehaviorSubject<BackupFile>(new BackupFile());
    private _conflict$ = new BehaviorSubject<BackupFile>(new BackupFile());
    private trackedFile: ITrackedFile;

    constructor(private backupFile: BackupFileDetails) {
        
        super(convertFile(backupFile));
        this.trackedFile = convertFile(backupFile);
        this._syncStatus$.next(this.trackedFile.fileStatus);
        this._inUseByWho$.next("");
        this._inUseByWhoFullName$.next("");
        this._inUseHere$.next(false);
        this._sharingList$.next(this.trackedFile.backupDetails.sharedWith);
        this._fileHistory$.next(this.trackedFile.backupDetails.revisions);
        this._summary$.next(this.trackedFile.backupDetails.summary);
        // if (trackedFile.conflictBackupDetails) {
        //     this._conflict$.next(trackedFile.conflictBackupDetails.summary);
        // }
    }
    
    public get currentStatus() {
        return this._syncStatus$.value;
    }

    public get currentInUseByWho() {
        return this._inUseByWho$.value;
    }

    public get currentInUseByWhoFullName() {
        return this._inUseByWhoFullName$.value;
    }

    public get currentInUseHere() {
        return this._inUseHere$.value;
    }

    public get currentSharingList() {
        return this._sharingList$.value;
    }

    public get currentFileHistory() {
        return this._fileHistory$.value;
    }

    public get currentSummary() {
        return this._summary$.value;
    }

    public get syncStatus$(): Observable<FileStatus> {
        return this._syncStatus$;
    }

    public get inUseByWho$(): Observable<string> {
        return this._inUseByWho$;
    }

    public get inUseByWhoFullName$(): Observable<string> {
        return this._inUseByWhoFullName$;
    }

    public get inUseHere$(): Observable<boolean> {
        return this._inUseHere$;
    }

    public get sharingList$(): Observable<SharingPermission[]> {
        return this._sharingList$;
    }

    public get fileHistory$(): Observable<BackupFile[]> {
        return this._fileHistory$;
    }

    public get summary$(): Observable<BackupFile> {
        return this._summary$;
    }

    public get conflictSummary$(): Observable<BackupFile> {
        return this._conflict$;
    }

    public setFileStatus(status: FileStatus) {
        const currentStatus = this._syncStatus$.getValue();
        if (status === currentStatus) {
            return;
        }
        this.fileStatus = status;
        this._syncStatus$.next(status);
    }

    public setInUseByWho(user: string) {
        const currentInUseByWho = this._inUseByWho$.getValue();
        if (user === currentInUseByWho) {
            return;
        }
        this._inUseByWho$.next(user);
    }

    public setInUseByWhoFullName(location: string) {
        const currentInUseByWhere = this._inUseByWhoFullName$.getValue();
        if (location === currentInUseByWhere) {
            return;
        }
        this._inUseByWhoFullName$.next(location);
    }

    public setInUseHere(value: boolean) {
        const currentInUseHere = this._inUseHere$.getValue();
        if (value === currentInUseHere) {
            return;
        }
        this._inUseHere$.next(value);
    }

    public setSharingList(value: SharingPermission[]) {
        this._sharingList$.next(value);
    }

    public setFileHistory(value: BackupFile[]) {
        this._fileHistory$.next(value);
    }

    public setSummary(value: BackupFile) {
        this._summary$.next(value);
    }

    public setConflict(value: BackupFile) {
        this._conflict$.next(value);
    }
}

export function convertFile(file: BackupFileDetails): ITrackedFile {
    let t = new TrackedFile(); 
    t.backupDetails = file;
    t.sharedStatus = ShareStatus.NotShared;
    let fs = file.summary.subscriptionId ? FileStatus.BackedUp : FileStatus.Locked;
    if (file.summary.subscription && file.summary.subscription.expiryDate.isBefore(new Date())) {
        fs = FileStatus.Locked
    }
    t.fileStatus = fs;

    return t;
    
}
