import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { AccountRecoveryComponent } from '../components/account-recovery/account-recovery.component';
import { RecoveryConfirmationComponent } from '../components/recovery-confirmation/recovery-confirmation.component';
import { AuthComponent } from '../components/auth/auth.component';
import { HomeComponent } from '../components/home/home.component';
import { AuthGuard } from '../shared/helpers/auth.guard';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { ShopComponent } from '../components/shop/shop.component';
import { ProductsComponent } from '../components/products/products.component';
import { AgrimasterProductComponent } from 'src/components/agrimaster-product/agrimaster-product.component';
import { RegisterUserComponent } from 'src/components/register-user/register-user.component';

const routes: Routes = [
  { path: '', redirectTo: '/home/shop/products/essentials', pathMatch: 'full' },
  {
    path: 'auth',
    component: AuthComponent,
    children: [
      { path: '', redirectTo: 'login', pathMatch: 'full' },
      { path: 'login', component: LoginComponent },
      { path: 'recovery', component: AccountRecoveryComponent },
      { path: 'confirmation', component: RecoveryConfirmationComponent },
      { path: 'register', component: RegisterUserComponent }
    ]
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', redirectTo: 'shop/products/essentials', pathMatch: 'full' },
      {
        path: 'shop',
        component: ShopComponent,
        children: [
          { path: 'products', component: ProductsComponent },
          { path: 'products/essentials', component: AgrimasterProductComponent },
          { path: 'products/lite', component: AgrimasterProductComponent },
          { path: 'products/:id', component: AgrimasterProductComponent }
        ]
      },
      /** Disable this path as shop should never access file list and file details (should use filemanager instead)
       *  Comment this one for now, as we may need it back in the future
        { path: "files", component: FileListComponent },
        { path: "business/:id/:type",
            component: BusinessDetailsComponent,
            children: [
                { path: "", redirectTo: "overview", pathMatch: "full" },
                { path: "overview", component: BusinessOverviewComponent },
                { path: "accounts", component: BankAccountListComponent },
            ],
        },
         */
      {
        path: 'password',
        component: null,
        children: [
          { path: '', redirectTo: 'change', pathMatch: 'full' },
          { path: 'change', component: ChangePasswordComponent }
        ]
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
