import { User as ConfigUser } from "configcat-common/lib/RolloutEvaluator";
import { UserProfile } from '../service-proxies/api-service-proxies';

export class User {
    id: number;
    userName: string;
    password: string;
    token: string;
    isOffline: boolean;
    userProfile: UserProfile;
    configUser?: ConfigUser;
}