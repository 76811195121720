import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faHistory } from "@fortawesome/pro-regular-svg-icons";
import { BackupFile, BackupType, FileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';

@Component({
    selector: "app-open-revision-read-only",
    templateUrl: "./open-revision-read-only.component.html",
    styleUrls: ["./open-revision-read-only.component.scss"],
})
export class OpenRevisionReadOnlyComponent implements OnInit {
    public faHistory = faHistory;
    public file: BackupFile;
    public accepted = false;
    public revisionGuid: string;

    constructor(
        public dialogRef: MatDialogRef<OpenRevisionReadOnlyComponent>,
        private _authService: AuthService,
        private _revisionServiceProxy: FileServiceProxy,
        @Inject(MAT_DIALOG_DATA) public data: BackupFile
    ) {
        this.file = data;
        this.revisionGuid = "";
        // this._revisionServiceProxy
        //     .getRevisionReadOnlyId(
        //         this._authService.currentUserValue.userName,
        //         this.file
        //     )
        //     .subscribe((result) => {
        //         this.revisionGuid = result.guid;
        //     });
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onOpen(): void {
        let subscriptionId = 0;

        if (this.file.subscriptionId) {
            subscriptionId = this.file.subscriptionId;
        }

        if (this.file.fileType == BackupType.Agrimaster) {
            window.open(
                "agrimaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    this.file.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(this.file.name) +
                    "/ReadOnly" +
                    "/" +
                    this.revisionGuid,
                "_self"
            );
        } else {
            window.open(
                "wagemaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    this.file.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(this.file.name) +
                    "/ReadOnly" +
                    "/" +
                    this.revisionGuid,
                "_self"
            );
        }
        this.dialogRef.close();
    }

    ngOnInit() {}
}
