import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Messages } from 'src/shared/models/messages';
import { faCircleNotch, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import {
  Ags7SubscriptionRequest,
  Business,
  BusinessNumberResponse,
  ProRataDetails,
  Profile,
  ShopProduct,
  SubscriptionInfo,
  SubscriptionRequest,
  UserProfile
} from 'src/shared/service-proxies/api-service-proxies';
import { User } from 'src/shared/models/user';
import * as moment from 'moment';
import { catchError } from 'rxjs/operators';
import { of, throwError } from 'rxjs';
import { MatStepper } from '@angular/material/stepper';
import { CardElement } from '../stripe';
import { AuthService } from 'src/shared/services/auth.service';
import { BusinessService } from 'src/shared/services/business.service';
import { PurchasingService } from 'src/shared/services/purchasing.service';
import { ClientService } from 'src/shared/services/client.service';
import { DocumentService } from 'src/shared/services/document.service';
import { STEPPER_GLOBAL_OPTIONS } from '@angular/cdk/stepper';
import { PaymentIntentResult } from '../stripe/stripe-definitions/payment-intent';
import { DOCUMENT } from '@angular/common';
import { CreateUserComponent } from '../create-user/create-user.component';
import { HttpStatusCode } from '@angular/common/http';

@Component({
  selector: 'app-agrimaster-product',
  templateUrl: './agrimaster-product.component.html',
  styleUrls: ['./agrimaster-product.component.scss'],
  providers: [
    {
      provide: STEPPER_GLOBAL_OPTIONS,
      useValue: { displayDefaultIndicatorType: true, showError: true }
    }
  ]
})
export class AgrimasterProductComponent implements OnInit, OnDestroy {
  @ViewChild('cardNumber') cardNumber: ElementRef;
  @ViewChild('cardExpiry') cardExpiry: ElementRef;
  @ViewChild('cardCvc') cardCvc: ElementRef;
  @ViewChild(CreateUserComponent) createUser: CreateUserComponent;

  private _productCode: string;
  private _productCodesThatCanBuyFromShop: string[] = ['AGS0', 'AGS1', 'AGS3', 'AGS4', 'AGS5', 'AGS6', 'AGS7'];
  private _productCodesThatCanBuyFromShopAsExistingUser: string[] = ['AGS0', 'AGS1', 'AGS3', 'AGS4', 'AGS5', 'AGS6'];
  private _gettingEntityName: boolean = false;
  private _invalidAbnLookup: boolean = false;
  private _isMatchingAbn: boolean = false;
  private _isPartnershipRegistration: boolean = false;
  private _isCompanyRegistration: boolean = false;
  private _isAbnExpired: boolean = false;
  private _onBehalfOfClient: boolean = false;
  private _profile: User;
  private _subscriber: User;
  private _proRataDetails: ProRataDetails;
  private _successfulPurchase: boolean = false;
  private _orderTotalsLoading: boolean = false;
  private _cardError: string = '';
  private _purchaseProcessing: boolean = false;
  private _invoiceUrl: string;
  private _purchaserIsProfPartner: boolean = false;
  private _purchaserIsStaffMember: boolean = false;
  private _paymentIsPending: boolean = false;
  private _isStaffBuyingForNew: boolean = false;
  private _isUploadingAgrimasterFileInvalid: boolean = false;
  private _orderSummaryAllowToBeCalculated: boolean = false;

  public productShortName: string;
  public entityDetailsDirectorship: string;
  public finalConfirmation: string;
  public faCircleNotch = faCircleNotch;
  public faTrashAlt = faTrashAlt;
  public requiresClientDetail: boolean = false;
  public clientSearching: boolean = false;
  public clientLocated: boolean = false;
  public clientSearchError: string;
  public states: string[] = ['ACT', 'NSW', 'NT', 'QLD', 'SA', 'TAS', 'VIC', 'WA'];
  public businessNames: string[] = [];
  public unlicensedFiles: Business[] = [];
  public ineligibilityReason: string = '';
  public clientDetailsGroup: UntypedFormGroup;
  public fileSetupDetails: UntypedFormGroup;
  public companyDetailsGroup: UntypedFormGroup;
  public newEntityDetailsGroup: UntypedFormGroup;
  public paymentDetailsGroup: UntypedFormGroup;
  public files: File[] = [];
  public selectingAgrimasterFiles: boolean = false;
  public card: CardElement;
  public paymentPendingAllowed: boolean = false;
  public newUserProfile = new UserProfile();
  public isNewUserAllowedToBuy: boolean = false;

  public candidatePrimarySubscriptions: SubscriptionInfo[] = [];
  public primarySubscriptionId: number = 0;
  public ags7User: UserProfile = new UserProfile();

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    private _authService: AuthService,
    private _formBuilder: UntypedFormBuilder,
    private _businessService: BusinessService,
    private _purchasingService: PurchasingService,
    private _clientService: ClientService,
    private _documentService: DocumentService
  ) {
    this._profile = this._authService.currentUserValue;

    this.subscribeClientSearchResult();

    this._businessService.abnError$.subscribe({
      next: (err) => {
        if (err) {
          this.businessNames = [];
          this._invalidAbnLookup = true;
          this._gettingEntityName = false;
        }
      }
    });

    this._businessService.abnSearchResult$.subscribe({
      next: (response: BusinessNumberResponse) => {
        if (response !== null) {
          this.businessNames = [];
          const name = this.newEntityDetailsGroup.get('entityName');
          if (response?.entityName) {
            this.setBusinessName(response, name);
          } else {
            this._invalidAbnLookup = true;
          }
          this._gettingEntityName = false;
        }
      }
    });

    this._businessService.unlicensedBusiness$.subscribe({
      next: (unlicensedBusiness: Business[]) => {
        if (unlicensedBusiness !== null && unlicensedBusiness.length > 0) {
          this.unlicensedFiles = unlicensedBusiness;
        } else {
          this.unlicensedFiles = [];
        }
      }
    });
  }

  ngOnInit(): void {
    this.setupShop();

    this._purchaserIsProfPartner = this._profile.userProfile.userType.startsWith('Professional Partner');
    this._purchaserIsStaffMember = this._profile.userProfile.userType === 'Staff';

    if (this._purchaserIsProfPartner || this._purchaserIsStaffMember) {
      this.requiresClientDetail = true;

      if (this._purchaserIsStaffMember) {
        this.paymentPendingAllowed = true;
      }
    } else if (this._profile.userProfile.edition === 'Agrimaster Basic Subscription') {
      this.ineligibilityReason = Messages.ineligibilityDueToCashbook.replace('<PRODUCTNAME>', this.productShortName);
    }

    if (!this._purchaserIsProfPartner && !this._purchaserIsStaffMember) {
      this._subscriber = this._profile;
      var currentDate = moment();
      const maxSubscriptionLength = 365;

      if (currentDate.isAfter(this._subscriber.userProfile.userExpiry)) {
        this.ineligibilityReason = Messages.expiredUser;
      } else if (Math.abs(moment().diff(this._subscriber.userProfile.userExpiry, 'days', false)) > maxSubscriptionLength) {
        this.ineligibilityReason = Messages.ineligibilityExtraordinaryExpiry;
      }

      this._businessService.unlicensed(this._subscriber.userName);
    } else {
      this._subscriber = new User();
      this._subscriber.userProfile = new UserProfile();
    }

    this.setupFormGroup();
  }

  ngOnDestroy(): void {
    this._clientService.ngOnDestroy();
    this._businessService.ngOnDestroy();
  }

  ClientDetailChange(clientDetailChange: any) {
    if (this.clientLocated) {
      this.clientLocated = false;
      this.clientDetailsGroup.get('australianBusinessNumber').setValue(' ');
      this.clientSearchError = '';
    }
  }

  public onUserCreateSuccess(userDetails: UserProfile) {
    console.log('New User Created - (Id: ' + userDetails.userId + ' UserName:' + userDetails.userName + '), by Staff: ' + this._profile.userProfile.userName);
    this.newUserProfile = userDetails;
    this._clientService.updateClientSearch(this.newUserProfile);
    this.clientDetailsGroup.get('userName').setValue(this.newUserProfile.userName);
    this.clientDetailsGroup.get('emailAddress').setValue(this.newUserProfile.emailAddress);
  }

  public onUploadSupportingDocumentations(files: File[]) {
    this.files = files;
  }

  public onOrderSummarySuccess(prorataDetails: ProRataDetails) {
    this._proRataDetails = prorataDetails;
  }

  public onSelectedPrimarySubscription(input: number) {
    this.primarySubscriptionId = input;
  }

  /**
   * output event for setting ags7 user
   * @param userProfile
   */
  public onSetAgs7User(userDetails: UserProfile) {
    this.ags7User = userDetails;
  }

  forNewUserChanged(purchasedFor: string) {
    this.clientDetailsGroup.reset();

    if (purchasedFor === 'New') {
      if (!this.isNewUserAllowedToBuy) {
        return;
      }

      if (this._purchaserIsStaffMember) {
        this._isStaffBuyingForNew = true;
      }
    } else {
      this._isStaffBuyingForNew = false;
      this.newUserProfile = new UserProfile();
    }
  }

  fileRequiredChanged(reset: string) {
    if (reset === 'Y') {
      this.fileSetupDetails.get('partnerSuppliedFile').reset();
      this._isUploadingAgrimasterFileInvalid = false;
    } else if (reset === 'N') {
      this.fileSetupDetails.get('setupRequired').reset();
    }
    this.fileSetupDetails.updateValueAndValidity();
  }

  AgrimasterFileChange(fileInputEvent: any) {
    this._isUploadingAgrimasterFileInvalid = false;
    this.selectingAgrimasterFiles = true;
    const upload = <File>fileInputEvent.target.files[0];
    if (upload.type !== 'application/agm') {
      this._isUploadingAgrimasterFileInvalid = true;
      this.selectingAgrimasterFiles = false;
      this.fileSetupDetails.get('file').reset();
      this.fileSetupDetails.get('partnerSuppliedFile').reset();
      return;
    }
    this.fileSetupDetails.get('file').setValue(upload);
    this.fileSetupDetails.get('partnerSuppliedFile').setValue(upload.name);

    this.selectingAgrimasterFiles = false;
  }

  public get paymentIsPending() {
    return this._paymentIsPending;
  }
  public get gettingEntityName() {
    return this._gettingEntityName;
  }

  public get isPartnershipRegistration() {
    return this._isPartnershipRegistration;
  }

  public get isCompanyRegistration() {
    return this._isCompanyRegistration;
  }

  public get newUserHasDetails() {
    return this.newUserProfile.userId > 0;
  }

  public get purchaserIsStaffMember() {
    return this._purchaserIsStaffMember;
  }

  public get purchaserIsProfPartner() {
    return this._purchaserIsProfPartner;
  }

  public get isStaffBuyingForNew() {
    return this._isStaffBuyingForNew;
  }

  public get orderTotalsLoading() {
    return this._orderTotalsLoading;
  }
  public get isInvalidEntityName() {
    let entityName = this.newEntityDetailsGroup.get('entityName').value;
    return entityName.startsWith('ERROR') || entityName === '';
  }

  public get isMatchingAbn() {
    return this._isMatchingAbn;
  }
  public get isInvalidAbnLookup() {
    return this._invalidAbnLookup;
  }

  public get successfulPurchase() {
    return this._successfulPurchase;
  }

  public get isExpired() {
    return this._isAbnExpired;
  }

  public get proRataDetails() {
    return this._proRataDetails;
  }

  public get cardError() {
    return this._cardError;
  }

  public get purchaseProcessing() {
    return this._purchaseProcessing;
  }

  public get invoiceUrl() {
    return this._invoiceUrl;
  }

  public get productCode() {
    return this._productCode;
  }

  public get isUploadingAgrimasterFileInvalid() {
    return this._isUploadingAgrimasterFileInvalid;
  }

  public get orderSummaryAllowToBeCalculated(): boolean {
    return this._orderSummaryAllowToBeCalculated;
  }

  public get subscriber(): User {
    return this._subscriber;
  }

  public get onBehalfOfClient() {
    return this._onBehalfOfClient;
  }

  public get clientDetailsComplete() {
    var disclaimer = this.clientDetailsGroup.get('disclaimer').value;
    let newUserHasDetailsValid = this.newUserHasDetails && disclaimer;
    let existingCustomerValid = this.clientLocated && this.clientSearchError === '' && disclaimer;
    return existingCustomerValid || newUserHasDetailsValid;
  }

  public get displayFreeTrialOption() {
    return this._productCode === 'AGS1';
  }

  /**
   * Display new file required option if (user is staff or PP) and (product is not ags0)
   */
  public get displayNewFileRequiredFileOption() {
    let result: boolean = false;

    if (this.requiresClientDetail && this._productCode !== 'AGS0') {
      result = true;
    }
    return result;
  }

  /**
   * Display setup option if user is member not matter what product is
   * or (file required option = 'yes')
   */
  public get displayAgrimasterSetupYourFileOption() {
    let result: boolean = false;

    if (!this.requiresClientDetail || this.fileSetupDetails.get('fileRequired').value === 'Yes') {
      result = true;
    }

    return result;
  }

  /**
   * Display link existing unlicensed file if purchaser is a staff and have unlicensed business
   */
  public get displayLinkExistingUnlicensedFileOption() {
    let result: boolean = false;

    if (this._purchaserIsStaffMember && this.unlicensedFiles.length > 0) {
      if (this._productCode === 'AGS0' || this.fileSetupDetails.get('fileRequired').value === 'No') {
        result = true;
      }
    }
    return result;
  }

  public get displayUnlicensedBusinessList() {
    let result: boolean = false;

    if (this._purchaserIsStaffMember && this.unlicensedFiles.length > 0) {
      if (this.fileSetupDetails.get('fileRequired').value === 'No' && this.fileSetupDetails.get('linkExistingUnlicensedFile').value === 'Yes') {
        result = true;
      }
    }

    return result;
  }

  /**
   * Display File upload if one of this condition is true:
   *  - purchaser is PP
   *  - purchaser is staff and subscriber doesnt have unlicensed file
   *  - purchaser is staff and choose to not link existing unlicensed file
   */
  public get displayFileUpload() {
    let result: boolean = false;

    if (this.fileSetupDetails.get('fileRequired').value === 'No') {
      if (
        (this._purchaserIsStaffMember && this.fileSetupDetails.get('linkExistingUnlicensedFile').value === 'No') ||
        (this._purchaserIsStaffMember && this.unlicensedFiles.length === 0) ||
        this._purchaserIsProfPartner
      ) {
        result = true;
      }
    }
    return result;
  }

  setBusinessNumberChangedLookup() {
    this.newEntityDetailsGroup.get('australianCompanyNumber').valueChanges.subscribe((se) => {
      if (se === null) {
        return;
      }
      this.newEntityDetailsGroup.get('entityName').setValue('');
      this.newEntityDetailsGroup.get('australianCompanyNumber').setValidators(abnMatchValidator(this._subscriber.userProfile.abn));
      this.lookupEntityName(se);
    });
  }

  lookupEntityName(abn: string) {
    this._invalidAbnLookup = false;
    let parentAbn;
    if (this._subscriber.userProfile.abn) {
      parentAbn = this._subscriber.userProfile.abn.replace(/\D/g, '').trim();
    } else {
      parentAbn = '';
    }

    let cleanedAbn = abn.replace(/\D/g, '').trim();
    this.businessNames = [];

    if (cleanedAbn === parentAbn) {
      this._isMatchingAbn = true;
    }

    const minAbnLength = 11;
    if (cleanedAbn.length > 0 && cleanedAbn.length < minAbnLength && !this.isValidABN(cleanedAbn) && !this.isValidACN(cleanedAbn)) {
      this._invalidAbnLookup = true;
      return;
    }

    if (cleanedAbn && cleanedAbn !== parentAbn && (this.isValidABN(cleanedAbn) || this.isValidACN(cleanedAbn))) {
      this._gettingEntityName = true;
      this._businessService.searchAbn(cleanedAbn);
    }
  }

  setBusinessName(response: BusinessNumberResponse, name: AbstractControl) {
    this._isAbnExpired = response.isExpired;

    if (!response.isExpired) {
      name.setValue(response.entityName);

      if (response.entityType.includes('Partnership')) {
        this._isPartnershipRegistration = true;
        this._isCompanyRegistration = false;
      } else {
        this._isPartnershipRegistration = false;
        this._isCompanyRegistration = true;
      }

      if (response.businessName && response.businessName.length > 0) {
        this.businessNames = response.businessName;
      } else {
        this.businessNames.push(response.entityName);
      }

      if (this.businessNames.length === 1) {
        var businessName = this.newEntityDetailsGroup.get('businessName');
        businessName.setValue(this.businessNames[0]);
      }
    } else {
      this._invalidAbnLookup = true;
      name.setValue('ABN is not Active!');
    }
  }

  /**
   * Set business Name from entity name if purchaser is staff
   * and abn is empty and entity name has value
   */
  onEntityNameOverideAbnChanges() {
    this.newEntityDetailsGroup.get('entityName').valueChanges.subscribe((changes) => {
      if (this._purchaserIsStaffMember && this.newEntityDetailsGroup.get('australianCompanyNumber').value === '') {
        this._invalidAbnLookup = false;
        this.businessNames = [];
        this.businessNames.push(changes);
        var businessName = this.newEntityDetailsGroup.get('businessName');
        businessName.setValue(changes);
      }
    });
  }

  setEntityNameToReadOnly() {
    if (!this.purchaserIsStaffMember) {
      return true;
    }
    if (this.newEntityDetailsGroup.get('australianCompanyNumber').value) {
      return true;
    }
    return false;
  }

  startCalculateOrderSummary() {
    this._orderSummaryAllowToBeCalculated = true;
  }

  clientSearch() {
    this.clientSearching = true;
    this.clientSearchError = '';

    if (this.clientDetailsGroup.get('userName').value === this._profile.userName && this._purchaserIsProfPartner) {
      this.clientSearchError = 'User Number can not match Logged in User!';
    }

    if (this._productCode === 'AGS7') {
      this.resetAgs7SObjectCreation();
    }

    this._clientService.search(this.clientDetailsGroup.get('userName').value, this.clientDetailsGroup.get('emailAddress').value);
  }

  subscribeClientSearchResult() {
    this._clientService.clientSearchError$.subscribe({
      next: (err) => {
        if (err) {
          this.clientSearching = false;
          this.clientSearchError =
            this._clientService.clientSearchErrorMessage$.value !== null && this._clientService.clientSearchErrorMessage$.value.length > 0
              ? this._clientService.clientSearchErrorMessage$.value
              : Messages.cannotFindExistingUserErrorMessage;

          this.clientDetailsGroup.get('isClientValid').setValue(false);
        }
      }
    });

    this._clientService.clientSearchResult$.subscribe({
      next: async (response) => {
        if (response !== null) {
          if (moment(response.userExpiry) <= moment()) {
            this.clientDetailsGroup.get('isClientValid').setValue(false);
            this.clientSearchError = Messages.expiredUser;
            this.clientSearching = false;
            return;
          }

          let [disqualifiedResult, errorMessage] = this.isClientPurchaseProductDisqualified(response, this.productCode);

          if (disqualifiedResult) {
            this.clientSearching = false;
            this.clientDetailsGroup.get('isClientValid').setValue(false);
            this.clientSearchError = errorMessage;
            return;
          }

          this._subscriber.userProfile = response;
          this._subscriber.userName = response.userName;

          if (response.userName !== this._profile.userName) {
            this._onBehalfOfClient = true;
          }

          const maxSubscriptionLength = 365;
          if (Math.abs(moment().diff(moment(response.userExpiry), 'days', false)) > maxSubscriptionLength) {
            //User with stupid long expiry cant buy on shop
            this.clientSearchError = Messages.ineligibilityExtraordinaryExpiry;
            this.clientDetailsGroup.get('isClientValid').setValue(false);
          } else {
            this.clientDetailsGroup.get('australianBusinessNumber').setValue(this._subscriber.userProfile.abn);
            this.companyDetailsGroup.get('userName').setValue(this._subscriber.userProfile.userName);
            this.companyDetailsGroup.get('australianCompanyNumber').setValue(this._subscriber.userProfile.abn);
            this.companyDetailsGroup.get('companyName').setValue(this._subscriber.userProfile.companyName);
            this.companyDetailsGroup.get('addressLine1').setValue(this._subscriber.userProfile.addressLine1);
            this.companyDetailsGroup.get('addressLine2').setValue(this._subscriber.userProfile.addressLine2);
            this.companyDetailsGroup.get('city').setValue(this._subscriber.userProfile.city);
            this.companyDetailsGroup.get('state').setValue(this._subscriber.userProfile.state);
            this.companyDetailsGroup.get('postCode').setValue(this._subscriber.userProfile.postcode);
            this.companyDetailsGroup.get('contactName').setValue(`${this._subscriber.userProfile.firstName} ${this._subscriber.userProfile.lastName}`);
            this.companyDetailsGroup.get('contactPhone').setValue(this._subscriber.userProfile.mobile);
            this.companyDetailsGroup.get('contactEmail').setValue(this._subscriber.userProfile.emailAddress);

            if (!this.isCompanyDetailsGroupValid && !response.isNewUser) {
              this.clientDetailsGroup.get('isClientValid').setValue(false);
              this.clientSearchError = Messages.incompleteProfile;
              this.clientDetailsGroup.get('australianBusinessNumber').setValue('');
            } else {
              // if product code is ags7, call api to get candidate primary subscriptions before moving to next step
              await this.getCandidatePrimarySubscriptionsAsync();

              this.clientLocated = true;
              this.clientDetailsGroup.get('isClientValid').setValue(true);
              this._businessService.unlicensed(this._subscriber.userName);
            }
          }
          this.clientSearching = false;
        }
      }
    });
  }

  /**
   * company details group valid if username, contact name, contact phone, email have some value
   * @returns
   */
  isCompanyDetailsGroupValid(): boolean {
    let result: boolean = false;

    const userName = this.companyDetailsGroup.get('userName').valid;
    const contactName = this.companyDetailsGroup.get('contactName').valid;
    const contactPhone = this.companyDetailsGroup.get('contactPhone').valid;
    const email = this.companyDetailsGroup.get('contactEmail').valid;

    result = userName && contactName && contactPhone && email;

    return result;
  }

  isClientPurchaseProductDisqualified(subscriber: UserProfile, productCode): [boolean, string] {
    let result = false;
    let message = '';

    if (productCode === 'AGS3' && !subscriber.isNewUser) {
      result = true;
      message = Messages.ineligibilityDueToExistingCustomer.replace('<PRODUCTCODE>', this.productCode);
    }

    if (subscriber.isAgs7User) {
      result = true;
      message = Messages.ineligibilityAGS7ToPurchaseSubscription.replace('<PRODUCTCODE>', this.productCode);
    }

    return [result, message];
  }

  validateEntityDetails() {
    if (this.isLiteOrEssentialSubscription) {
      const needsPayroll = this.newEntityDetailsGroup.get('payrollRequired');
      const needsBudgeting = this.newEntityDetailsGroup.get('fullBudgetsRequired');

      if (needsPayroll.value === 'Yes' || needsBudgeting.value === 'Yes') {
        this.ineligibilityReason = Messages.ineligibilityDueToBudgetsOrPayroll;
      }

      const matchingDirectorship = this.newEntityDetailsGroup.get('matchingDirectors');
      if (matchingDirectorship.value === 'No' && !this.isStaffBuyingForNew) {
        if (this._productCode === 'AGS4') {
          this.ineligibilityReason = Messages.essentialsIneligibilityDueToMismatchedDirectors;
        } else if (this._productCode === 'AGS6') {
          this.ineligibilityReason = Messages.liteIneligibilityDueToMismatchedDirectors;
        }
      }
    }
  }

  public get IsFileSetupDetailsValid(): boolean {
    var result: boolean = this.fileSetupDetails.valid;
    if (this._purchaserIsStaffMember) {
      if (this.displayFileUpload) {
        result = this.fileSetupDetails.get('partnerSuppliedFile').value !== null && this.fileSetupDetails.get('partnerSuppliedFile').value !== '';

        if (result && !this.fileSetupDetails.valid) {
          this.fileSetupDetails.setErrors(null);
        }
      } else if (this.displayUnlicensedBusinessList) {
        result = this.fileSetupDetails.get('businessId').value !== 0;
      }
    }
    return result;
  }

  public get IsOrderSummaryCompleted() {
    //will add more condition in ags7 when we know the requirement more
    if (this._productCode === 'AGS7') {
      return this.primarySubscriptionId > 0 && this.ags7User && this.ags7User.userId; //and new childid has been assigned
    }
    return this._proRataDetails !== null && this.fileSetupDetails.valid;
  }

  getEntityAbnErrorMessage() {
    if (this.newEntityDetailsGroup.get('australianCompanyNumber').hasError('required')) {
      return 'ABN of the new Entity is required';
    }

    if (this.newEntityDetailsGroup.get('australianCompanyNumber').hasError('abnMatch')) {
      return 'Entity ABN can not match the existing Subscription Abn';
    }

    return '';
  }

  returnToShop() {
    let shopReturnUrl: string = Messages.shopProductReturnUrl;
    this._document.location.href = shopReturnUrl;
  }

  returnToShopBecauseOfDisqualified(productCode: string) {
    let shopReturnUrl: string = Messages.shopDisqualifiedProductReturnUrl.replace('<PRODUCTCODE>', productCode);
    this._document.location.href = shopReturnUrl;
  }

  returnToShopBecauseInvalidProduct() {
    this._document.location.href = Messages.shopInvalidProductUrl;
  }

  buyWithPendingPayment(stepper: MatStepper) {
    this._purchaseProcessing = true;
    this._paymentIsPending = true;
    if (this._productCode === 'AGS7') {
      let ags7Request = this.buildAgs7RequestObject();
      this.purchaseAgs7Subscription(ags7Request);
    } else {
      let request = this.buildRequestObj();

      if (this.isLiteOrEssentialSubscription) {
        this._documentService.uploadVerificationDocuments(this.files, request.subscriberUserName, request.abn).subscribe(() => {
          this.purchaseSubscription(request);
        });
      } else {
        this.purchaseSubscription(request);
      }
    }
  }

  async buyWithCardPayment(stepper: MatStepper) {
    this._purchaseProcessing = true;
    this._paymentIsPending = false;

    await this._purchasingService
      .createPaymentMethod({
        type: 'card',
        card: this.paymentDetailsGroup.get('card').value,
        billing_details: {
          email: this.companyDetailsGroup.get('contactEmail').value,
          name: this.paymentDetailsGroup.get('name').value,
          phone: this.companyDetailsGroup.get('contactPhone').value,
          address: {
            country: 'AU',
            line1: this.companyDetailsGroup.get('addressLine1').value,
            line2: this.companyDetailsGroup.get('addressLine2').value,
            state: this.companyDetailsGroup.get('state').value,
            city: this.companyDetailsGroup.get('city').value,
            postal_code: this.companyDetailsGroup.get('postCode').value
          }
        }
      })
      .then((paymentResult) => {
        if (paymentResult.error) {
          this._cardError = `Payment Failed: ${paymentResult.error.message}`;
          this._successfulPurchase = false;
          this._purchaseProcessing = false;
        } else {
          if (this.productCode === 'AGS7') {
            let request = this.buildAgs7RequestObject();
            request.paymentMethodId = paymentResult.paymentMethod.id;
            this.purchaseAgs7Subscription(request);
          } else {
            let request = this.buildRequestObj();
            request.paymentMethodId = paymentResult.paymentMethod.id;

            if (this.isLiteOrEssentialSubscription) {
              this._documentService.uploadVerificationDocuments(this.files, request.subscriberUserName, request.abn).subscribe(() => {
                this.purchaseSubscription(request);
              });
            } else {
              this.purchaseSubscription(request);
            }
          }
        }
      });
  }

  buildRequestObj(): SubscriptionRequest {
    const request = new SubscriptionRequest();

    request.abn = this.newEntityDetailsGroup.get('australianCompanyNumber').value;
    request.abn = request.abn.replace(/\D/g, '').trim();
    request.subscriberUserName = this.companyDetailsGroup.get('userName').value;
    request.planCode = this._productCode;
    request.entityName = this.newEntityDetailsGroup.get('entityName').value;
    request.businessName = this.newEntityDetailsGroup.get('businessName').value.trim();
    request.hasFreeTrial = this.newEntityDetailsGroup.get('freeTrial').value === 'Yes';
    request.addressLine1 = this.companyDetailsGroup.get('addressLine1').value;
    request.addressLine2 = this.companyDetailsGroup.get('addressLine2').value;
    request.city = this.companyDetailsGroup.get('city').value;
    request.state = this.companyDetailsGroup.get('state').value;
    request.postCode = this.companyDetailsGroup.get('postCode').value;
    request.contactName = this.companyDetailsGroup.get('contactName').value;
    request.contactPhone = this.companyDetailsGroup.get('contactPhone').value;
    request.contactEmail = this.companyDetailsGroup.get('contactEmail').value;
    request.onBehalfOfClient = this.requiresClientDetail;
    request.requiresFile = this.fileSetupDetails.get('fileRequired').value === 'Yes';
    request.requiresSetup = this.fileSetupDetails.get('setupRequired').value === 'Yes';
    request.businessId = Number(this.fileSetupDetails.get('businessId').value) || 0;
    request.prorataStartDate = this.proRataDetails.prorataStartDate;

    if (this.proRataDetails.couponApplied?.isValid) {
      request.coupon = this.proRataDetails.couponApplied.stripeId;
    }

    if (this.fileSetupDetails.get('linkExistingUnlicensedFile').value === 'Yes') {
      this.fileSetupDetails.get('partnerSuppliedFile').setValue('');
    } else {
      request.businessId = 0;
    }

    request.files = [];
    for (let file of this.files) {
      request.files.push(file.name);
    }

    return request;
  }

  buildAgs7RequestObject(): Ags7SubscriptionRequest {
    const ags7Request = new Ags7SubscriptionRequest();
    const selectedPrimarySubscription = this.candidatePrimarySubscriptions.find((w) => w.subscriptionId === this.primarySubscriptionId);

    ags7Request.abn = selectedPrimarySubscription.abn;
    ags7Request.planCode = this._productCode;

    ags7Request.childUserId = this.ags7User.userId;
    ags7Request.childProfile = new Profile();
    ags7Request.childProfile.userName = this.ags7User.userName;
    ags7Request.childProfile.firstName = this.ags7User.firstName;
    ags7Request.childProfile.lastName = this.ags7User.lastName;

    ags7Request.parentSubscriptionId = selectedPrimarySubscription.subscriptionId;
    ags7Request.entityName = selectedPrimarySubscription.entityName;
    ags7Request.businessName = selectedPrimarySubscription.entityName;
    ags7Request.addressLine1 = this.companyDetailsGroup.get('addressLine1').value;
    ags7Request.addressLine2 = this.companyDetailsGroup.get('addressLine2').value;
    ags7Request.subscriberUserName = this.companyDetailsGroup.get('userName').value;
    ags7Request.city = this.companyDetailsGroup.get('city').value;
    ags7Request.state = this.companyDetailsGroup.get('state').value;
    ags7Request.postCode = this.companyDetailsGroup.get('postCode').value;
    ags7Request.contactName = this.companyDetailsGroup.get('contactName').value;
    ags7Request.contactPhone = this.companyDetailsGroup.get('contactPhone').value;
    ags7Request.contactEmail = this.companyDetailsGroup.get('contactEmail').value;
    ags7Request.onBehalfOfClient = this.requiresClientDetail;

    return ags7Request;
  }

  purchaseSubscription(request: SubscriptionRequest) {
    this._purchasingService
      .purchase(request)
      .pipe(
        catchError((err) => {
          this._cardError = Messages.subscriptionCreationCardError;
          this._successfulPurchase = false;
          this._purchaseProcessing = false;
          return throwError(() => err);
        })
      )
      .subscribe(async (purchaseResponse) => {
        if (purchaseResponse.status === 'incomplete' || purchaseResponse.status === 'requires_action') {
          request.subscriptionId = purchaseResponse.subscriptionId;
          request.billingId = purchaseResponse.billingId;

          await this._purchasingService.confirmCardPayment(purchaseResponse.clientSecret).then((cardPaymentResult) => {
            if (cardPaymentResult.error) {
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              console.error(`Payment Failed: ${cardPaymentResult.error.message}`);
              this._cardError = Messages.visaOrMasterCardError;
              this._successfulPurchase = false;
              this._purchaseProcessing = false;
            } else {
              this.purchasingSubscription(request, cardPaymentResult);
            }
          });
        } else {
          //Upload PP File if one supplied...
          if (!request.requiresFile && this.requiresClientDetail && this.fileSetupDetails.get('partnerSuppliedFile').value != '') {
            this._documentService
              .deliverClientFile(request.subscriberUserName, request.businessName, purchaseResponse.subscriptionId, this.fileSetupDetails.get('file').value)
              .pipe(
                catchError((err) => {
                  console.log(err);
                  return of(false);
                })
              )
              .subscribe((complete) => {
                this.purchaseComplete(purchaseResponse.invoiceUrl);
              });
          } else {
            this.purchaseComplete(purchaseResponse.invoiceUrl);
          }
        }
      });
  }

  purchaseAgs7Subscription(ags7Request: Ags7SubscriptionRequest) {
    this._purchasingService
      .purchaseAgs7Subscription(ags7Request)
      .pipe(
        catchError((err) => {
          this._cardError = Messages.subscriptionCreationCardError;
          this._successfulPurchase = false;
          this._purchaseProcessing = false;
          return throwError(() => err);
        })
      )
      .subscribe(async (ags7PurchaseResponse) => {
        if (ags7PurchaseResponse.status === 'incomplete' || ags7PurchaseResponse.status === 'requires_action') {
          ags7Request.subscriptionId = ags7PurchaseResponse.subscriptionId;
          ags7Request.billingId = ags7PurchaseResponse.billingId;

          await this._purchasingService.confirmCardPayment(ags7PurchaseResponse.clientSecret).then((cardPaymentResult) => {
            if (cardPaymentResult.error) {
              // Display error message in your UI.
              // The card was declined (i.e. insufficient funds, card has expired, etc)
              console.error(`Payment Failed: ${cardPaymentResult.error.message}`);
              this._cardError = Messages.visaOrMasterCardError;
              this._successfulPurchase = false;
              this._purchaseProcessing = false;
            } else {
              this.purchasingAgs7Subscription(ags7Request, cardPaymentResult);
            }
          });
        } else {
          this.purchaseComplete(ags7PurchaseResponse.invoiceUrl);
        }
      });
  }

  purchasingSubscription(request: SubscriptionRequest, cardPaymentResult?: PaymentIntentResult) {
    this._purchasingService.purchase(request).subscribe((subscriptionResponse) => {
      if (subscriptionResponse.status === 'active') {
        if (!request.requiresFile && this.requiresClientDetail && this.fileSetupDetails.get('partnerSuppliedFile').value != '') {
          this._documentService
            .deliverClientFile(request.subscriberUserName, request.businessName, subscriptionResponse.subscriptionId, this.fileSetupDetails.get('file').value)
            .pipe(
              catchError((err) => {
                console.log(err);
                return of(false);
              })
            )
            .subscribe((complete) => {
              this.purchaseComplete(subscriptionResponse.invoiceUrl);
            });
        } else {
          this.purchaseComplete(subscriptionResponse.invoiceUrl);
        }
      } else {
        this._cardError = `Payment Failed: ${cardPaymentResult.error.message}`;
        this._successfulPurchase = false;
        this._purchaseProcessing = false;
      }
    });
  }

  purchasingAgs7Subscription(ags7Request: Ags7SubscriptionRequest, cardPaymentResult?: PaymentIntentResult) {
    this._purchasingService.purchaseAgs7Subscription(ags7Request).subscribe((ags7SubscriptionResponse) => {
      if (ags7SubscriptionResponse.status === 'active') {
        this.purchaseComplete(ags7SubscriptionResponse.invoiceUrl);
      } else {
        this._cardError = `Payment Failed: ${cardPaymentResult.error.message}`;
        this._successfulPurchase = false;
        this._purchaseProcessing = false;
      }
    });
  }

  setupShop() {
    this._purchasingService.getProduct().subscribe({
      next: (shopProduct: ShopProduct) => {
        if (shopProduct === null) {
          this.returnToShopBecauseInvalidProduct();
          return;
        }
        this._productCode = shopProduct.code;

        if (this._productCode === 'AGS0' && !this._purchaserIsStaffMember) {
          this.returnToShopBecauseOfDisqualified(this._productCode);
        } else if (this._productCode === 'AGS4') {
          this.entityDetailsDirectorship = Messages.essentialsEntityDetailsDirectorshipQuestion;
          this.finalConfirmation = Messages.essentialsFinalConfirmation;
        } else if (this._productCode === 'AGS6') {
          this.entityDetailsDirectorship = Messages.liteEntityDetailsDirectorshipQuestion;
          this.finalConfirmation = Messages.liteFinalConfirmation;
        } else if (this._productCode === 'AGS3' && !this._profile.userProfile.isNewUser && !this._purchaserIsProfPartner && !this._purchaserIsStaffMember) {
          this.returnToShopBecauseOfDisqualified(this._productCode);
        } else if (!this._productCodesThatCanBuyFromShop.includes(this._productCode)) {
          this.returnToShopBecauseInvalidProduct();
        }

        this.productShortName = shopProduct.name;
        this.setupFormGroup();

        this.isNewUsersAllowedToBuySubscription(shopProduct.code);
      },
      error: () => {
        this.returnToShopBecauseInvalidProduct();
      }
    });
  }

  setupFormGroup() {
    this.clientDetailsGroup = this._formBuilder.group({
      userName: ['', Validators.required],
      emailAddress: ['', Validators.required && Validators.email],
      australianBusinessNumber: [''],
      isClientValid: [false, Validators.requiredTrue],
      disclaimer: [false, Validators.requiredTrue]
    });

    this.companyDetailsGroup = this._formBuilder.group({
      userName: [this._subscriber.userName, Validators.required],
      australianCompanyNumber: [this._subscriber.userProfile.abn],
      companyName: [this._subscriber.userProfile.companyName],
      addressLine1: [this._subscriber.userProfile.addressLine1],
      addressLine2: [this._subscriber.userProfile.addressLine2],
      city: [this._subscriber.userProfile.city],
      state: [this._subscriber.userProfile.state],
      postCode: [this._subscriber.userProfile.postcode],
      contactName: [`${this._subscriber.userProfile.firstName} ${this._subscriber.userProfile.lastName}`, Validators.required],
      contactPhone: [this._subscriber.userProfile.mobile, Validators.required],
      contactEmail: [this._subscriber.userProfile.emailAddress, Validators.required && Validators.email]
    });

    if (!this.isCompanyDetailsGroupValid && !(this._purchaserIsProfPartner || this._purchaserIsStaffMember)) {
      this.ineligibilityReason = Messages.incompleteProfile;
    }

    this.setupNewEntityDetailFormGroup();

    this.setupFileSetupFormGroup();

    this.setBusinessNumberChangedLookup();

    this.onEntityNameOverideAbnChanges();

    this.paymentDetailsGroup = this._formBuilder.group({
      name: this.requiresClientDetail ? [``] : [`${this._profile.userProfile.firstName} ${this._profile.userProfile.lastName}`, [Validators.required]],
      directorshipDisclaimer: this.isLiteOrEssentialSubscription ? false : true, // we don't care about directorship disclaimer if we trying to purchase AGS1
      termsAndConditionsDisclaimer: false,
      card: this.card
    });
  }

  setupNewEntityDetailFormGroup() {
    if (this.isLiteOrEssentialSubscription) {
      this.newEntityDetailsGroup = this._formBuilder.group({
        australianCompanyNumber: [''],
        entityName: ['', Validators.required],
        businessName: ['', Validators.required],
        fullBudgetsRequired: ['', Validators.required],
        payrollRequired: ['', Validators.required],
        matchingDirectors: ['', Validators.required],
        freeTrial: 'No'
      });
    } else {
      this.newEntityDetailsGroup = this._formBuilder.group({
        australianCompanyNumber: [''],
        entityName: ['', Validators.required],
        businessName: ['', Validators.required],
        freeTrial: 'No'
      });
    }
  }

  setupFileSetupFormGroup() {
    //if member login, always get shell delivered no matter what product it is
    if (!this.requiresClientDetail) {
      this.fileSetupDetails = this._formBuilder.group({
        fileRequired: 'Yes',
        setupRequired: '',
        linkExistingUnlicensedFile: 'No',
        businessId: 0,
        file: File,
        partnerSuppliedFile: ''
      });
    } else if (this._productCode === 'AGS0') {
      this.fileSetupDetails = this._formBuilder.group({
        fileRequired: 'No',
        setupRequired: 'No',
        linkExistingUnlicensedFile: '',
        businessId: 0,
        file: File,
        partnerSuppliedFile: ''
      });
    } else {
      this.fileSetupDetails = this._formBuilder.group({
        fileRequired: ['', Validators.required],
        setupRequired: '',
        linkExistingUnlicensedFile: '',
        businessId: 0,
        file: File,
        partnerSuppliedFile: ''
      });
    }
  }

  purchaseComplete(invoice: string) {
    this._successfulPurchase = true;
    this._purchaseProcessing = false;
    this._invoiceUrl = invoice;
  }

  public get isLiteOrEssentialSubscription() {
    if (this._productCode === 'AGS4' || this._productCode === 'AGS6') {
      return true;
    }
    return false;
  }

  isValidABN(inp: string) {
    const weights = new Array(10, 1, 3, 5, 7, 9, 11, 13, 15, 17, 19);
    // Convert to string and remove all white space
    inp = inp.toString().replace(/\s/g, '');
    // Split it to number array
    let num = inp.split('').map((n) => parseInt(n));
    // Subtract 1 from the first (left-most) digit of the ABN to give a new 11 digit number
    num[0] = num[0] - 1;
    // Multiply each of the digits in this new number by a "weighting factor" based on its position as shown in the table below
    num = num.map((n, i) => n * weights[i]);
    // Sum the resulting 11 products
    let total = num.reduce((acc, n) => {
      return acc + n;
    }, 0);
    // Divide the sum total by 89, noting the remainder
    if (total % 89 === 0) {
      return true;
    } else {
      return false;
    }
  }

  isValidACN(inp: string) {
    const weights = new Array(8, 7, 6, 5, 4, 3, 2, 1);
    // Convert to string and remove all white space
    inp = inp.toString().replace(/\s/g, '');
    // Split it to number array
    let num = inp.split('').map((n) => parseInt(n));
    // Set the check digit and remove it
    let checkDigit = num.pop();
    // Apply weighting to digits 1 to 8.
    num = num.map((n, i) => n * weights[i]);
    // Sum the products
    let total = num.reduce((acc, n) => {
      return acc + n;
    }, 0);
    // Divide by 10 to obtain remainder
    let calculatedCheckDigit = (10 - (total % 10)) % 10;
    // calculatedCheckDigit should match check digit
    if (calculatedCheckDigit === checkDigit) {
      return true;
    } else {
      return false;
    }
  }

  isNewUsersAllowedToBuySubscription(productCode: string) {
    if (this._productCodesThatCanBuyFromShopAsExistingUser.find((w) => w === productCode) !== undefined) {
      this.isNewUserAllowedToBuy = true;
    }
  }

  async getCandidatePrimarySubscriptionsAsync() {
    try {
      if (this._productCode === 'AGS7') {
        this.candidatePrimarySubscriptions = await this._purchasingService.getCandidatePrimarySubscriptionsAsync(this._subscriber.userName);
      }
    } catch (err: any) {
      this.resetAgs7SObjectCreation();
      if (err.status === undefined || err.status !== HttpStatusCode.NotFound) {
        console.error(err);
      }
    }
  }

  resetAgs7SObjectCreation() {
    this.primarySubscriptionId = 0;
    this.candidatePrimarySubscriptions = [];
    this.ags7User = new UserProfile();
  }
}

export function abnMatchValidator(newEntityAbn: string): ValidatorFn {
  return (control: AbstractControl): { [key: string]: boolean } | null => {
    if (newEntityAbn) {
      if (control.value.replace(/\D/g, '').trim() === newEntityAbn.replace(/\D/g, '').trim()) {
        return { 'abnMatch': true };
      }
    }
    return null;
  };
}
