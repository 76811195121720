<form id="clientDetailsForm" [formGroup]="existingClientDetailsGroup" fxLayout="column">
  <div class="input-row" fxLayout="row" fxLayoutGap="20px">
    <mat-form-field fxFlex>
      <input matInput placeholder="Agrimaster User Number" formControlName="userName" digitOnly required id="userNumber" (change)="existingClientDetailChange()" />
    </mat-form-field>
    <mat-form-field fxFlex>
      <input matInput placeholder="Contact Email Address" formControlName="emailAddress" required id="emailAddress" (change)="existingClientDetailChange()" />
    </mat-form-field>
  </div>
  <div class="input-row" fxLayout="row" fxLayoutGap="20px">
    <button type="button" mat-raised-button color="accent" (click)="clientSearchAsync()" [disabled]="clientLocated || existingClientSearching" id="clientSearch">
      Search
    </button>
    <fa-icon [icon]="faCircleNotch" [spin]="true" *ngIf="this.existingClientSearching"></fa-icon>
    <span *ngIf="existingClientSearchError">{{ existingClientSearchError }}</span>
  </div>
</form>
