import { EventEmitter, Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { SignalRService } from "./signalr.service";
import { FileInfoLookup, IFileInfoLookup } from "../models/file-info-lookup";
import { Observable } from "rxjs/internal/observable";
import { of } from "rxjs";
import { map, take } from "rxjs/operators";
import { SignalRConsts } from "../models/constants";
import { FileStatusUpdate } from "../models/file-status-update";
import { StateAwareFile } from "../models/state-aware-file";
import { SignalRFile } from "../models/signalr-file";
import { BackupType, FileServiceProxy } from '../service-proxies/api-service-proxies';
import { FileStatus } from '../models/file-status';
import { TrackedFile } from '../models/tracked-file';

@Injectable({
    providedIn: "root",
})
export class FileService {
    private _fileInfoLookup = new FileInfoLookup();

    constructor(
        private _authService: AuthService,
        private _revisionServiceProxy: FileServiceProxy,
        private _signalRService: SignalRService
    ) {
        this._signalRService.connectedGlobalSubject.subscribe((connected) => {
            if (connected) {
                this.registerGlobalSignalRListeners();
            }
        });

        this._authService.currentUser$.subscribe((user) => {
            if (!user) {
                this._fileInfoLookup.removeAll();
            }
        });

        this._signalRService.reconnectHappened.subscribe((happened) => {
            if (this._authService.currentUserValue) {
                let files = this._fileInfoLookup.getFiles();
                for (var file of files) {
                    if (file.backupDetails.summary.subscriptionId) {
                        this._signalRService.registerFileForNotifications(
                            this._authService.currentUserValue.userName,
                            file.backupDetails
                        );
                    }
                }
            }
        });
    }

    public get fileInfoLookup(): IFileInfoLookup {
        return this._fileInfoLookup;
    }

    public removeAllFiles() {
        this._fileInfoLookup.removeAll();
    }

    public get files$(): Observable<StateAwareFile[]> {
        return this._fileInfoLookup.files$;
    }

    public get haveFetchedFiles$(): Observable<boolean> {
        return this._fileInfoLookup.haveFetchedFiles();
    }

    public getFiles(reload: boolean) {
        if (reload) {
            var user = this._authService.currentUserValue;

            if (!user) {
                this._fileInfoLookup.removeAll();
            }
        }

        this._fileInfoLookup.setFetchStatus(false);
        this._revisionServiceProxy
            .list()
            .pipe(take(1))
            .subscribe((onlineFiles) => {
                this._fileInfoLookup.setFetchStatus(true);
                this._fileInfoLookup.removeAll();
                for (const onlineFile of onlineFiles) {
                    this.updateInfoInLookup(new StateAwareFile(onlineFile));
                    if (onlineFile.summary.subscriptionId) {
                        this._signalRService.registerFileForNotifications(
                            this._authService.currentUserValue.userName,
                            onlineFile
                        );
                    }
                }
                this._signalRService.startFileCheck(
                    this._authService.currentUserValue.userName
                );
            });
    }

    public getFilesIfNone() {
        if (!this._fileInfoLookup.hasValues()) {
            this.getFiles(false);
        }
    }

    public getFile(
        business: string | StateAwareFile,
        fileType?: string
    ): Observable<StateAwareFile> {
        var user = this._authService.currentUserValue;

        if (!user) {
            this._fileInfoLookup.removeAll();
        }

        if (this._fileInfoLookup.hasValues()) {
            return of(this._fileInfoLookup.getFile(business, fileType));
        } else {
            return this._revisionServiceProxy
                .list()
                .pipe(
                    map((onlineFiles) => {
                        this._fileInfoLookup.setFetchStatus(true);
                        for (const onlineFile of onlineFiles) {
                            this.updateInfoInLookup(
                                new StateAwareFile(onlineFile)
                            );
                            if (
                                onlineFile.summary.subscriptionId
                            ) {
                                this._signalRService.registerFileForNotifications(
                                    this._authService.currentUserValue.userName,
                                    onlineFile
                                );
                            }
                        }
                        this._signalRService.startFileCheck(
                            this._authService.currentUserValue.userName
                        );
                        return this._fileInfoLookup.getFile(business, fileType);
                    })
                );
        }
    }

    public updateFileFromServer(
        oldFile: StateAwareFile,
        skipStatus: Boolean = false
    ) {
        this._revisionServiceProxy
            .file(
                oldFile.backupDetails.summary.name,
                oldFile.backupDetails.summary.userOwnedBy.userName,
                oldFile.backupDetails.summary.fileType
            )
            .subscribe((file) => {
                this.updateInfoInLookup(new StateAwareFile(file), skipStatus);
                this._signalRService.registerFileForNotifications(
                    this._authService.currentUserValue.userName,
                    file
                );
            });
    }

    public updateSharedFileFromServer(
        name: string,
        owner: string,
        backupType: BackupType,
        skipStatus: Boolean = false
    ) {
        this._revisionServiceProxy
            .file(
                name,
                owner,
                backupType
            )
            .subscribe((file) => {
                this.updateInfoInLookup(new StateAwareFile(file), skipStatus);
            });
    }

    public updateConflictFileFromServer(file: StateAwareFile) {
        // this._revisionServiceProxy
        //     .file(
        //         this._authService.currentUserValue.token,
        //         this._authService.currentUserValue.userName,
        //         file.backupDetails.summary
        //     )
        //     .subscribe((conflictFile) => {
        //         file.setConflict(conflictFile);
        //         file.setFileStatus(FileStatus.Conflict);
        //     });
    }

    public addFileFromServer(name: string, backupType: BackupType) {
        this._revisionServiceProxy
            .file(
                name,
                this._authService.currentUserValue.userName,
                backupType
            )
            .subscribe((file) => {
                this.updateInfoInLookup(new StateAwareFile(file));
                this._signalRService.registerFileForNotifications(
                    this._authService.currentUserValue.userName,
                    file
                );
            });
    }

    public addSharedFileFromServer(
        name: string,
        owner: string,
        backupType: BackupType
    ) {
        this._revisionServiceProxy
            .file(
                name,
                owner,
                backupType
            )
            .subscribe((file) => {
                this.updateInfoInLookup(new StateAwareFile(file));
                this._signalRService.registerFileForNotifications(
                    this._authService.currentUserValue.userName,
                    file
                );
            });
    }

    public removeFile(file: StateAwareFile) {
        this._fileInfoLookup.removeFile(file);
    }

    private updateInfoInLookup(
        file: StateAwareFile,
        skipStatus: Boolean = false
    ) {
        this._fileInfoLookup.setFile(file, skipStatus);
        if (file.backupDetails.summary.isPendingInjection) {
            setTimeout(() => {
                this.updateFileFromServer(file, skipStatus);
            }, 30000);
        }
        return file;
    }

    private registerGlobalSignalRListeners() {
        this._signalRService.registerGlobalListener(
            SignalRConsts.onFileOpened,
            (data: SignalRFile) => this.onSignalRFileOpenedElsewhere(data)
        );
        this._signalRService.registerGlobalListener(
            SignalRConsts.onFileClosed,
            (data: SignalRFile) => this.onSignalRFileClosedElsewhere(data)
        );
        //this._signalRService.registerGlobalListener(SignalRConsts.onRevisionNoteAdded, (data: SignalRFile) => this.onRevisionNoteAdded(data));
        this._signalRService.registerGlobalListener(
            SignalRConsts.onFileUploadComplete,
            (data: SignalRFile) => this.onSignalRFileUploadedElsewhere(data)
        );
        this._signalRService.registerGlobalListener(
            SignalRConsts.onUserAddedToShare,
            (data: SignalRFile) => this.onSignalRFileShareAdded(data)
        );
        this._signalRService.registerGlobalListener(
            SignalRConsts.onUserShareRevoked,
            (data: SignalRFile) => this.onSignalRFileShareRevoked(data)
        );
        this._signalRService.registerGlobalListener(
            SignalRConsts.onUserShareModified,
            (data: SignalRFile) => this.onSignalRFileShareUpdated(data)
        );
        this._signalRService.registerGlobalListener(
            SignalRConsts.onDeleteFile,
            (data: SignalRFile) => this.onSignalRFileDeleted(data)
        );
        this._signalRService.registerGlobalListener(
            SignalRConsts.onUserRejectedShare,
            (data: SignalRFile) => this.onSignalRShareRejected(data)
        );

        this._signalRService.registerGlobalListener(
            SignalRConsts.onProcessingError,
            (data) => this.onSignalrFileProcessingError(data)
        );

        this._signalRService.registerGlobalListener(
            SignalRConsts.onNormalisationComplete,
            (data) => this.onSignalrFileNormalisationComplete(data)
        );

        this._signalRService.registerGlobalListener(
            SignalRConsts.onNormalisationFailed,
            (data) => this.onSignalrFileNormalisationFailed(data)
        );

        this._signalRService.registerGlobalListener(
            SignalRConsts.onProcessing,
            (data) => this.onSignalrFileProcessing(data)
        );
    }

    private onSignalRFileDownloading(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null) {
            file.setFileStatus(FileStatus.Downloading);
        }
    }

    private onSignalRFileUploading(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null) {
            file.setFileStatus(FileStatus.Uploading);
        }
    }

    private onSignalRFileSyncError(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null) {
            file.setFileStatus(FileStatus.SyncError);
        }
    }

    private onLocalSignalRFileProcessing(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null) {
            file.setFileStatus(FileStatus.Processing);
        }
    }

    private onSignalRFileBackedUp(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (this._fileInfoLookup.hasValues()) {
            if (file != null) {
                if (file.currentStatus == FileStatus.Uploading) {
                    this.updateFileFromServer(file, true);
                }
                file.setFileStatus(FileStatus.BackedUp);
            } else if (
                data.fileKey.includes(
                    this._authService.currentUserValue.userName
                )
            ) {
                this.addFileFromServer(data.fileName, data.backupType);
            }
        }
    }

    private onSignalRFileLinked(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null) {
            file.setFileStatus(FileStatus.Verifying);
            this.updateFileFromServer(file, true);
        } else if (
            data.fileKey.includes(this._authService.currentUserValue.userName)
        ) {
            this.addFileFromServer(data.fileName, data.backupType);
        }
    }

    private onSignalRFileOffline(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null) {
            file.setFileStatus(FileStatus.Offline);
        }
    }

    private onSignalRFileLocked(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null) {
            file.setFileStatus(FileStatus.Locked);
        }
    }

    private onSignalRFileConflict(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null && file.backupDetails.summary.subscriptionId) {
            this.updateConflictFileFromServer(file);
        }
    }

    private onLocalSignalrFileProcessingError(data: FileStatusUpdate) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            data.fileKey
        );
        if (file != null && file.backupDetails.summary.subscriptionId) {
            file.setFileStatus(FileStatus.ProcessingError);
        }
    }

    private onSignalrFileNormalisationComplete(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );

        if (file != null && file.backupDetails.summary.subscriptionId) {
            this.updateFileFromServer(file, true);
        }
    }

    private onSignalrFileNormalisationFailed(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );

        if (file != null && file.backupDetails.summary.subscriptionId) {
            this.updateFileFromServer(file, true);
        }
    }
    private onSignalrFileProcessingError(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );

        if (file != null && file.backupDetails.summary.subscriptionId) {
            file.setFileStatus(FileStatus.ProcessingError);
        }
    }

    private onSignalrFileProcessing(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );

        if (file != null && file.backupDetails.summary.subscriptionId) {
            file.setFileStatus(FileStatus.Processing);
        }
    }

    private onSignalRFileOpenedElsewhere(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (
            file != null &&
            file.backupDetails.summary.subscriptionId &&
            !file.currentInUseHere
        ) {
            file.setFileStatus(FileStatus.FileInUse);
            file.setInUseByWhoFullName(data.fromFullName);
            file.setInUseByWho(data.fromUserNumber);
            file.setInUseHere(false);
        }
    }

    private onSignalRFileOpenedHere(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (file != null && file.backupDetails.summary.subscriptionId) {
            file.setFileStatus(FileStatus.FileInUse);
            file.setInUseByWhoFullName(data.fromFullName);
            file.setInUseByWho(data.fromUserNumber);
            file.setInUseHere(true);
        }
    }

    private onSignalRFileClosedElsewhere(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (
            file != null &&
            file.backupDetails.summary.subscriptionId &&
            !file.currentInUseHere &&
            file.currentStatus === FileStatus.FileInUse
        ) {
            file.setFileStatus(FileStatus.Verifying);
            file.setInUseByWhoFullName("");
            file.setInUseByWho("");
            file.setInUseHere(false);
        }
    }

    private onSignalRFileUploadedElsewhere(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (
            file != null &&
            file.backupDetails.summary.subscriptionId &&
            (file.currentStatus === FileStatus.Verifying ||
                file.currentStatus === FileStatus.Processing ||
                file.currentStatus === FileStatus.ProcessingError)
        ) {
            this.updateFileFromServer(file);
        }
    }

    private onSignalRShareRejected(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (file != null && file.backupDetails.summary.subscriptionId) {
            this.updateFileFromServer(file, true);
        }
    }

    private onSignalRFileShareUpdated(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );

        if (file != null && file.backupDetails.summary.subscriptionId) {
            this.updateSharedFileFromServer(
                data.fileName,
                data.fileOwnerNumber,
                data.fileType,
                true
            );
        }
    }

    private onSignalRFileShareRevoked(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (file != null) {
            this._fileInfoLookup.removeFile(file);
        }
    }

    private onSignalRFileDeleted(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (file != null) {
            this._fileInfoLookup.removeFile(file);
        }
    }

    private onSignalRFileShareAdded(data: SignalRFile) {
        this.addSharedFileFromServer(
            data.fileName,
            data.fileOwnerNumber,
            data.fileType
        );
    }

    private onSignalRFileClosedHere(data: SignalRFile) {
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            this.getAlternateKey(data)
        );
        if (
            file != null &&
            file.backupDetails.summary.subscriptionId &&
            file.currentStatus === FileStatus.FileInUse
        ) {
            file.setInUseByWhoFullName("");
            file.setInUseByWho("");
            file.setInUseHere(false);
        }
    }

    private getAlternateKey(data: SignalRFile) {
        return `${data.fileName}::${data.fileOwnerNumber}::${data.fileType}`.toLowerCase();
    }

    public removeFileFromList(
        fileName: string,
        ownerUserName: string,
        fileType: BackupType
    ) {
        let key = `${fileName}::${ownerUserName}::${fileType}`.toLowerCase();

        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            key
        );

        if (file != null) {
            this._fileInfoLookup.removeFile(file);
        }
    }

    public confirmWagemasterExists(fileName: string) {
        let alternateKey = `${fileName}::${this._authService.currentUserValue.userName}::Wagemaster`.toLowerCase();
        const file: StateAwareFile = this._fileInfoLookup.getFileWithAlternateKey(
            alternateKey
        );
        if (file != null) {
            return true;
        }
        return false;
    }
}
