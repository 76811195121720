import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProfileServiceProxy, UserProfile } from '../service-proxies/api-service-proxies';

@Injectable({
  providedIn: 'root'
})
export class ClientService implements OnDestroy {
  private _clientSearchResult: BehaviorSubject<UserProfile> = new BehaviorSubject<UserProfile>(null);
  private _clientSearchError$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  public clientSearchErrorMessage$: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  public get clientSearchResult$(): Observable<UserProfile> {
    return this._clientSearchResult.pipe(map((f) => f));
  }

  public get clientSearchError$(): Observable<boolean> {
    return this._clientSearchError$.pipe(map((f) => f));
  }

  constructor(private _profileService: ProfileServiceProxy) {}

  /**
   * Clear all the subjects
   */
  ngOnDestroy() {
    this._clientSearchError$.next(null);
    this._clientSearchResult.next(null);
    this.clientSearchErrorMessage$.next(null);
  }

  search(userName: string, emailAddress: string) {
    this._profileService.verification(userName, emailAddress).subscribe({
      next: (profile) => {
        this._clientSearchResult.next(profile);
        this._clientSearchError$.next(false);
      },
      error: (err) => {
        this.clientSearchErrorMessage$.next(err.response);
        this._clientSearchError$.next(true);
      }
    });
  }

  async searchExistingUserAsync(userName: string, emailAddress: string): Promise<UserProfile> {
    let userProfile = await lastValueFrom(this._profileService.verification(userName, emailAddress));
    return userProfile;
  }

  public updateClientSearch(userProfile: UserProfile): void {
    if (userProfile) {
      this._clientSearchResult.next(userProfile);
    }
  }
}
