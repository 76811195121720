import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserProfile } from 'src/shared/service-proxies/api-service-proxies';
import { ClientService } from 'src/shared/services/client.service';

@Component({
  selector: 'app-assign-ags7-user',
  templateUrl: './assign-ags7-user.component.html',
  styleUrls: ['./assign-ags7-user.component.scss']
})
export class AssignAgs7UserComponent implements OnChanges, OnDestroy {
  ags7UserDetail: UserProfile = new UserProfile();

  @Input() parentUserName: string | undefined = undefined;

  @Output() ags7User: EventEmitter<UserProfile> = new EventEmitter();

  private readonly subscriptions: Subscription[] = [];
  public isStaffBuyingForNew: boolean;

  constructor(private _clientService: ClientService) {
    this.subscribeClientSearchResult();
  }

  ngOnChanges(changes: SimpleChanges): void {
    const inputParentUserName = changes['parentUserName'];

    if (inputParentUserName) {
      this.parentUserName = inputParentUserName.currentValue as string;
    }
  }

  subscribeClientSearchResult() {
    this.subscriptions.push(
      this._clientService.clientSearchError$.subscribe({
        next: () => {
          this.resetUserProfile();
        }
      })
    );

    this.subscriptions.push(
      this._clientService.clientSearchResult$.subscribe({
        next: () => {
          this.resetUserProfile();
        }
      })
    );
  }

  resetUserProfile() {
    this.ags7UserDetail = new UserProfile();
  }

  toggleAgs7NewExistingUser(staffBuyingNewUser: boolean) {
    this.isStaffBuyingForNew = staffBuyingNewUser;
  }

  /**
   * output event for setting ags7 user
   * @param userProfile
   */
  public onSetAGS7NewUser(userDetails: UserProfile) {
    this.ags7UserDetail = userDetails;
    this.ags7User.emit(userDetails);
  }

  public onSetAGS7ExistingUser(userDetails: UserProfile) {
    this.ags7UserDetail = userDetails;
    this.ags7User.emit(userDetails);
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((subscription) => subscription.unsubscribe());
  }
}
