import { Component, OnInit } from '@angular/core';
import { HomeService } from '../../shared/services/home.service';
import { Router } from '@angular/router';
import { ValidatorFn, AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { faKey, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { AuthService } from '../../shared/services/auth.service';
import { PasswordChangeRequest, ProfileServiceProxy } from '../../shared/service-proxies/api-service-proxies';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  public faKey = faKey;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public newPassword = new UntypedFormControl('', []);
  public confirmPassword = new UntypedFormControl('', [passwordMatchValidator(this.newPassword.value)]);
  public form: UntypedFormGroup;
  private configSnackBar: MatSnackBarConfig = {
    panelClass: ['style-bar'],
    duration: 3000
  };
  public existingHide: Boolean;
  public newHide: Boolean;
  public confirmHide: Boolean;
  public maxLength: number = 8;
  public meetsLengthRequirement: boolean = false;
  public meetsCharacterRequirement: boolean = false;
  public meetsNumericalRequirement: boolean = false;

  constructor(public homeService: HomeService, private _router: Router, private _formBuilder: UntypedFormBuilder, private _snackBar: MatSnackBar, private _profileService: ProfileServiceProxy, private _authService: AuthService) { 
    this.homeService.homeParams.next({homeTitle: 'Account Management'});
    this.form = _formBuilder.group({
      existingPassword: ['', Validators.required],
      newPassword: ['', Validators.required],
      confirmPassword: ''
    });

    this.form.valueChanges.subscribe(changes => {
      this.form.get('confirmPassword').setValidators(passwordMatchValidator(this.form.value.newPassword));

      this.meetsLengthRequirement = changes.newPassword.length >= this.maxLength;
      this.meetsCharacterRequirement = /[A-Z]/.test(changes.newPassword);
      this.meetsNumericalRequirement = /\d/.test(changes.newPassword);
      
    });
  }

  public goHome(){
    this._router.navigateByUrl('/home');
  }
  
  ngOnInit() {
  }

  public confirmAndGoHome() {
    var passwordRequest = new PasswordChangeRequest();
    passwordRequest.oldPassword = this.form.value.existingPassword,
    passwordRequest.newPassword = this.form.value.newPassword

    this._profileService.changePassword2(passwordRequest).pipe(
      catchError(err => {
        this._snackBar.open("Password change failed", null, this.configSnackBar);
        return throwError(err);
      })
    ).subscribe(() => {
      this._snackBar.open("Password changed successfully", null, this.configSnackBar);
      this._router.navigateByUrl('/home');
    });
  }
}

export function passwordMatchValidator(newpass: string): ValidatorFn {
  return (control: AbstractControl): {[key: string]: any} | null => {
    if(control.value !== newpass)
    {
        return {'passwordMatch' : control.value};
    }
    return null;
  };
}
