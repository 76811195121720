<mat-card>
    <mat-card-title><fa-icon [icon]="faSync" class="file-status"></fa-icon><span class="header-text">Refresh Licence - {{file.backupDetails.summary.name}}</span></mat-card-title>
    <mat-card-content>
        <div>
            <div class="container">
                <p class="paragraph">
                    {{file.backupDetails.summary.name}} has been sent to our servers to refresh the licence.
                    It is recommended that you allow <b>up to an hour</b> for your file to be updated with the licence details, before you start using it again. <br />
                    Please contact support if you still have issues accessing the file after the prescribed time has
                    elapsed.
                </p>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-raised-button color="primary" aria-label="Submit" (click)="onNoClick()">
            Close
        </button>
    </mat-card-actions>
</mat-card>