<mat-card>
    <mat-card-title><fa-icon [icon]="faGift" class="file-status"></fa-icon><span class="header-text">Update Available</span></mat-card-title>
    <mat-card-content>
        <div>
            <div class="container">
                <p>A new update for Agrimaster has been installed on your computer.</p>
                <p>You will see the update the next time you open Agrimaster File Manager.</p>
                <p>Otherwise you can click Reload to get the changes now.</p>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-raised-button color="primary" (click)="reload()">Reload</button>
    </mat-card-actions>
</mat-card>