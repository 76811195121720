<div class="main-container">

    <p id="purchaseCompleteParagraph">{{ purchaseComplete }}</p>
    
    <div *ngIf="onBehalfOfClient">
        <p *ngIf="purchaserIsStaffMember" id="adviseCustomerParagraph">{{ adviseCustomerText }}</p>
        <ul *ngIf="purchaserIsStaffMember && !paymentIsPending" id="adviseList" class="bulletList" [innerHTML]="adviseCustomerSteps"></ul>
    </div>
    
    <p *ngIf="!onBehalfOfClient && !paymentIsPending">Click <a id="invoiceUrlHyperLink" [href]="invoiceUrl" target="_blank">here</a> to to download a copy of your invoice.</p>

    <p *ngIf="!onBehalfOfClient">Please click <a [href]="shopReturnUrl">here</a> to return to the shop.</p>

</div>