import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Messages } from 'src/shared/models/messages';
import { faCircleNotch, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-supporting-documentation',
  templateUrl: './supporting-documentation.component.html',
  styleUrls: ['./supporting-documentation.component.scss']
})
export class SupportingDocumentationComponent implements OnInit {
  @Output() outputDocumentationfilesSuccess: EventEmitter<File[]> = new EventEmitter();
  private _documentationStepOne: string;
  private _documentationStepTwo: string;
  private _selectingFiles: boolean = false;

  @Input('productCode') productCode:string;
  @Input('isPartnershipRegistration') isPartnershipRegistration: boolean;
  @Input('isCompanyRegistration') isCompanyRegistration: boolean;

  public faCircleNotch = faCircleNotch;
  public faTrashAlt = faTrashAlt;
  private _files: File[] = [];

  public get files()
  {
    return this._files;
  }

  public get selectingFiles() {
    return this._selectingFiles;
  }

  public get documentationStepOne(){
    return this._documentationStepOne;
  }

  public get documentationStepTwo(){
    return this._documentationStepTwo;
  }
  
  ngOnInit(): void {
    if(this.productCode === "AGS4")
    {
      this._documentationStepOne = Messages.essentialsDocumentationStepOne;
      this._documentationStepTwo = Messages.essentialsDocumentationStepTwo;
    }
    else if(this.productCode === "AGS6")
    {
      this._documentationStepOne = Messages.liteDocumentationStepOne;
      this._documentationStepTwo = Messages.liteDocumentationStepTwo;
    }
  }

  FileChange(fileInputEvent: any) {
    this._selectingFiles = true;
    const uploads = <File[]>fileInputEvent.target.files

    var prevouslySelectedFileNameList: string[] = [];
    this._files.forEach(element => {
      prevouslySelectedFileNameList.push(element.name);
    });

    for(let upload of uploads)
    {
      var isNameMatched = false;

      for(let previousFileName of prevouslySelectedFileNameList)  {
        isNameMatched = (previousFileName.localeCompare(upload.name, undefined, {sensitivity: 'base'}) == 0);
        if(isNameMatched) break;
      }

      if (!isNameMatched)
      {
        this._files.push(upload);
      }
    }            
    this.outputDocumentationfilesSuccess.emit(this._files);        
    this._selectingFiles = false;
  }

  removeSelected(fileName: string){
    this._files = Object.values(this._files).filter(file => file.name !== fileName); 
    this.outputDocumentationfilesSuccess.emit(this._files);       
  }

}
