import { Component, Inject } from '@angular/core';
import { faHourglassEnd } from '@fortawesome/pro-regular-svg-icons';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { BackupFile } from 'src/shared/service-proxies/api-service-proxies';

@Component({
  selector: 'app-subscription-expired',
  templateUrl: './subscription-expired.component.html',
  styleUrls: ['./subscription-expired.component.scss']
})
export class SubscriptionExpiredComponent {
  public file: BackupFile;
  public faHourglassEnd = faHourglassEnd;

  constructor(public dialogRef: MatDialogRef<SubscriptionExpiredComponent>, @Inject(MAT_DIALOG_DATA) public data: BackupFile) {
    this.file = data;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }
}
