import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { faHistory } from '@fortawesome/pro-regular-svg-icons';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { BackupFile, FileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';

@Component({
  selector: 'app-restore-file',
  templateUrl: './restore-file.component.html',
  styleUrls: ['./restore-file.component.scss']
})
export class RestoreFileComponent implements OnInit {

  public faHistory = faHistory;
  public file: BackupFile;
  public accepted = false;
  public waitingRequest = false;

  private configSnackBar: MatSnackBarConfig = {
    panelClass: ['style-bar'],
    duration: 3000
  };

  constructor(public dialogRef: MatDialogRef<RestoreFileComponent>,
    private _authService: AuthService,
    private _revisionServiceProxy: FileServiceProxy,
    private _snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) public data: BackupFile) {
    this.file = data;
  }

  public onNoClick(): void {
    this.dialogRef.close();
  }

  public onOpen(): void {
    this.waitingRequest = true;
    this._revisionServiceProxy.restoreRevision(this.file.id, this.file.userOwnedBy.userName).pipe(
      catchError(err => {
        this.waitingRequest = false;
        this._snackBar.open("File restore failed", null, this.configSnackBar);
        return throwError(err);
      })
    ).subscribe(() => {
      this.waitingRequest = false;
      this.dialogRef.close(true);
    });
  }

  ngOnInit() {
  }

}
