<div *ngIf="!loading">
    <div class="top-row">
        <mat-form-field class="search-field">
            <input matInput type="text" placeholder="Search by User Number or Business Name" appearance="outline"
                [(ngModel)]="filter.nameFilter" (keyup)="applyFilter()">
            <button mat-button *ngIf="filter.nameFilter" matSuffix mat-icon-button aria-label="Clear"
                (click)="filter.nameFilter=''; applyFilter();">
                <fa-icon [icon]="faTimes"></fa-icon>
            </button>
        </mat-form-field>
        <span class="fill-remaining-space"></span>
        <div>
            <mat-button-toggle-group name="fileType" aria-label="File Type" class="file-type"
                [(ngModel)]="filter.typeFilter">
                <mat-button-toggle value="All" (click)="applyFilter()">All</mat-button-toggle>
                <mat-button-toggle value="Agrimaster" (click)="applyFilter()"><img
                        src="../../assets/images/logo-agrimaster_70x70.png" alt="logo Agrimaster"
                        class="program-icon" /><span class="button-text">Agrimaster</span></mat-button-toggle>
                <mat-button-toggle value="Wagemaster" (click)="applyFilter()"><img
                        src="../../assets/images/logo-wagemaster_64x64.png" alt="logo Agrimaster"
                        class="program-icon" /><span class="button-text">Wagemaster</span></mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>
    <div class="table-container">
        <table mat-table [dataSource]="fileList" class="file-list mat-elevation-z8">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Files</th>
                <td mat-cell *matCellDef="let element" (click)="openBusiness(element)">
                    <span class="business-name">{{element.backupDetails.summary.name}}</span>
                    <br />
                    <span class="abn" *ngIf="element.backupDetails.summary.subscription && (element.syncStatus$ | async) !== FileStatus.Offline">ABN:
                        {{element.backupDetails.summary.subscription.abn}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" (click)="openBusiness(element)">
                    <img src="../../assets/images/logo-agrimaster_70x70.png" alt="logo Agrimaster"
                        *ngIf="element.backupDetails.summary.fileType == BackupType.Agrimaster" class="program-icon" />
                    <img src="../../assets/images/logo-wagemaster_64x64.png" alt="logo Wagemaster"
                        *ngIf="element.backupDetails.summary.fileType == BackupType.Wagemaster" class="program-icon" />
                </td>
            </ng-container>
            <ng-container matColumnDef="createdBy">
                <th mat-header-cell *matHeaderCellDef>Created By</th>
                <td mat-cell *matCellDef="let element" (click)="openBusiness(element)">
                    <div *ngIf="element.backupDetails.summary.subscriptionId && (element.syncStatus$ | async) !== FileStatus.Locked && (element.syncStatus$ | async) !== FileStatus.Offline">
                        <span
                            class="small-highlight">{{element.backupDetails.summary.userOwnedBy.firstName + ' ' + element.backupDetails.summary.userOwnedBy.lastName}}</span>
                        <br />
                        <span class="abn">{{element.backupDetails.summary.userOwnedBy.userName}}</span>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="fileStatusIcon">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" (click)="openBusiness(element)">
                    <fa-icon [icon]="faDownload" *ngIf="(element.syncStatus$ | async) == FileStatus.Downloading"
                        class="animate-flicker downloading file-status"></fa-icon>
                    <fa-icon [icon]="faExclamationTriangle"
                        *ngIf="(element.syncStatus$ | async) == FileStatus.SyncError" class="sync-error file-status">
                    </fa-icon>
                    <fa-icon [icon]="faUserLock" *ngIf="(element.syncStatus$ | async) == FileStatus.FileInUse"
                        class="sync-error file-status"></fa-icon>
                    <fa-icon [icon]="faCloud" *ngIf="(element.syncStatus$ | async) == FileStatus.BackedUp"
                        class="backed-up file-status">
                    </fa-icon>
                    <fa-icon [icon]="faUpload" *ngIf="(element.syncStatus$ | async) == FileStatus.Uploading"
                        class="animate-flicker downloading file-status"></fa-icon>
                    <fa-icon [icon]="faCog" [spin]="true" *ngIf="(element.syncStatus$ | async) == FileStatus.Processing"
                        class="file-status">
                    </fa-icon>
                    <fa-icon [icon]="faBinoculars" *ngIf="(element.syncStatus$ | async) == FileStatus.Verifying"
                        class="animate-flicker file-status">
                    </fa-icon>
                    <fa-icon [icon]="faHdd" *ngIf="(element.syncStatus$ | async) == FileStatus.Offline"
                        class="local-file file-status">
                    </fa-icon>
                    <fa-icon [icon]="faLock" *ngIf="(element.syncStatus$ | async) == FileStatus.Locked && !element.backupDetails.summary.subscriptionId"
                        class="file-status"></fa-icon>
                    <fa-icon [icon]="faHourglassEnd" *ngIf="(element.syncStatus$ | async) == FileStatus.Locked && element.backupDetails.summary.subscriptionId"
                        class="file-status"></fa-icon>
                    <fa-icon [icon]="faDoNotEnter" *ngIf="(element.syncStatus$ | async) == FileStatus.Conflict"
                        class="conflict file-status"></fa-icon>
                    <fa-icon [icon]="faExclamationTriangle" 
                        *ngIf="(element.syncStatus$ | async) == FileStatus.ProcessingError"
                        class="sync-error file-status"></fa-icon>
                </td>
            </ng-container>
            <ng-container matColumnDef="fileStatus">
                <th mat-header-cell *matHeaderCellDef>File Status</th>
                <td mat-cell *matCellDef="let element" (click)="openBusiness(element)">
                    <span class="status"
                        *ngIf="(element.syncStatus$ | async) == FileStatus.Downloading">Downloading</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.SyncError">Sync Error</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.BackedUp">Backed Up</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.Uploading">Uploading</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.Processing">Processing</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.Verifying">Verifying</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.Offline">Local</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.Locked && !element.backupDetails.summary.subscriptionId">Locked</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.Locked && element.backupDetails.summary.subscriptionId">Expired</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.FileInUse">File In Use</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.Conflict">Conflict</span>
                    <span class="status" *ngIf="(element.syncStatus$ | async) == FileStatus.ProcessingError">Processing Error</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="sharedStatus">
                <th mat-header-cell *matHeaderCellDef>Shared Status</th>
                <td mat-cell *matCellDef="let element" (click)="openBusiness(element)">
                    <span
                        *ngIf="element.backupDetails.summary.subscriptionId && (element.syncStatus$ | async) !== FileStatus.Locked">{{getSharingPermissionString(element.sharedStatus)}}</span>
                </td>
            </ng-container>
            <ng-container matColumnDef="changedDetails">
                <th mat-header-cell *matHeaderCellDef>Last changed details</th>
                <td mat-cell *matCellDef="let element">
                    <div *ngIf="element.backupDetails.summary.subscriptionId && (element.syncStatus$ | async) !== FileStatus.Locked && (element.syncStatus$ | async) !== FileStatus.Conflict && (element.syncStatus$ | async) !== FileStatus.Offline" (click)="openBusiness(element)">
                        <span>Last changed {{element.backupDetails.summary.modifiedDate.fromNow()}}</span>
                        <br />
                        by <span
                            class="small-highlight">{{element.backupDetails.summary.userSavedBy.firstName + ' ' + element.backupDetails.summary.userSavedBy.lastName}}</span>
                    </div>
                    <button
                        *ngIf="(element.syncStatus$ | async) == FileStatus.Locked && !element.backupDetails.summary.subscriptionId && element.backupDetails.summary.fileType == BackupType.Agrimaster && element.backupDetails.summary.userOwnedBy.userName === currentUserName"
                        class="learn-more" mat-stroked-button aria-label="learn" (click)="openAssignLicenses(element)">
                        Learn More
                    </button>
                    <button
                        *ngIf="(element.syncStatus$ | async) == FileStatus.Locked && element.backupDetails.summary.subscriptionId && element.backupDetails.summary.fileType == BackupType.Agrimaster"
                        class="learn-more" mat-stroked-button aria-label="learn" (click)="openSubscriptionExpired(element)">
                        Learn More
                    </button>
                    <button
                        *ngIf="(element.syncStatus$ | async) == FileStatus.Conflict && element.backupDetails.summary.subscriptionId"
                        class="learn" mat-stroked-button aria-label="learn" (click)="openConflictDialog(element)">
                        Resolve
                    </button>
                    <button
                        *ngIf="!element.backupDetails.summary.subscriptionId && element.backupDetails.summary.fileType == BackupType.Agrimaster && element.backupDetails.summary.userOwnedBy.userName !== currentUserName"
                        class="learn-more" mat-stroked-button aria-label="learn" (click)="openUnlicencedShare(element)">
                        Learn More
                    </button>
                    <button
                        *ngIf="(element.syncStatus$ | async) == FileStatus.Offline && element.backupDetails.summary.fileType == BackupType.Wagemaster && element.backupDetails.summary.userOwnedBy.userName === currentUserName"
                        mat-stroked-button aria-label="learn" (click)="openWagemasterLearnMore(element)">
                        Learn More
                    </button>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" class="file-row"
                [ngClass]="{'unlicensed': (!row.backupDetails.summary.subscriptionId || (row.syncStatus$ | async) === FileStatus.Locked ) && row.backupDetails.summary.fileType == BackupType.Agrimaster && (row.syncStatus$ | async) !== FileStatus.Offline }"
                ></tr>
        </table>
    </div>
</div>
<div *ngIf="loading" class="loading">
    <fa-icon [icon]="faCircleNotch" [spin]="true" class="loading-spinner"></fa-icon>
</div>