<mat-card>
    <mat-card-title><fa-icon [icon]="faFileCertificate" class="file-status"></fa-icon><span class="header-text">Review Licence - {{file.summary.name}}</span></mat-card-title>
    <mat-card-content>
        <div>
            <div class="container">
                <p class="paragraph">To add a licence to a WageMaster file please link a subscription to the related Agrimaster file</p>
                <p>Alternatively you can delete the file.</p>
                <p>Deleting a file is a permanent action and <span class="text-bold">cannot be undone</span>.
                    <br/>Once deleted, the file will no longer be accessible or appear in File Manager.</p>
                <p>This action will also:</p>
                <ul class="clean-list">
                    <li>
                        <fa-icon [icon]="faFolder" class="delete-options"></fa-icon><span
                            class="delete-options-text">Remove this file from your work folder</span>
                    </li>
                    <li>
                        <fa-icon [icon]="faHistory" class="delete-options"></fa-icon><span
                            class="delete-options-text">Remove this file from AutoBackups</span>
                    </li>
                </ul>
                <mat-checkbox [color]="'warn'" [(ngModel)]="accepted">I understand and wish to delete this file</mat-checkbox>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="warn" [disabled]="!accepted" aria-label="Submit" (click)="onDelete()">
            Delete
        </button>
    </mat-card-actions>
</mat-card>