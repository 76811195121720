<mat-card>
    <mat-card-title><fa-icon [icon]="faExclamationTriangle" class="file-status"></fa-icon><span class="header-text">Open Read Only</span></mat-card-title>
    <mat-card-content>
        <div>
            <div class="container">
                <p>You are opening this file as read only.</p>
                <p>Any changes you make to this file <span class="text-bold">will not save</span> to File Manager.</p>
                <mat-checkbox [color]="'warn'" [(ngModel)]="accepted">I understand and wish to enable Read Only</mat-checkbox>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!accepted" aria-label="Submit" (click)="onOpen()">
            Open - Read Only
        </button>
    </mat-card-actions>
</mat-card>