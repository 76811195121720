import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { faStickyNote } from "@fortawesome/pro-regular-svg-icons";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { BackupFile, NoteServiceProxy } from 'src/shared/service-proxies/api-service-proxies';

@Component({
    selector: "app-add-note",
    templateUrl: "./add-note.component.html",
    styleUrls: ["./add-note.component.scss"],
})
export class AddNoteComponent implements OnInit {
    faStickyNote = faStickyNote;

    public currentFile: BackupFile;
    public note: string;
    public hasNoteAlready: boolean;
    public noteDisabled: boolean;

    private configSnackBar: MatSnackBarConfig = {
        panelClass: ["style-bar"],
        duration: 3000,
    };

    constructor(
        public dialogRef: MatDialogRef<AddNoteComponent>,
        private _noteProxy: NoteServiceProxy,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public file: BackupFile
    ) {
        this.currentFile = file;
        this.note = this.currentFile.note;
        if (this.note) {
            this.hasNoteAlready = true;
        } else {
            this.hasNoteAlready = false;
        }
        this.noteDisabled = false;
    }

    public onNoClick(): void {
        this.note = null;
        this.dialogRef.close(this.note);
    }

    public onSave(): void {
        this.noteDisabled = true;

        if (!this.hasNoteAlready) {
            this._noteProxy
                .addNoteByRevisionId(this.file.id, this.note)
                .pipe(
                    catchError((err) => {
                        this.noteDisabled = false;
                        this._snackBar.open(
                            "Could not save note",
                            null,
                            this.configSnackBar
                        );
                        return throwError(err);
                    })
                )
                .subscribe(() => {
                    this.noteDisabled = false;
                    this.dialogRef.close(this.note);
                });
        } else {
            this._noteProxy
                .updateNoteByRevisionId(this.file.id, this.note)
                .pipe(
                    catchError((err) => {
                        this.noteDisabled = false;
                        this._snackBar.open(
                            "Could not save note",
                            null,
                            this.configSnackBar
                        );
                        return throwError(err);
                    })
                )
                .subscribe(() => {
                    this.noteDisabled = false;
                    this.dialogRef.close(this.note);
                });
        }
    }

    ngOnInit(): void {
    }
}
