<mat-card>
    <mat-card-title>
        <fa-icon [icon]="faShareAlt" class="file-status"></fa-icon>
        <span class="header-text" *ngIf="isNewShare">Add Share</span>
        <span class="header-text" *ngIf="!isNewShare">Edit Share</span>
    </mat-card-title>
    <mat-card-content>
        <div class="vertical-box">
            <mat-form-field appearance="outline">
                <mat-label>User Number</mat-label>
                <input [disabled]="!isNewShare" matInput [(ngModel)]="shareUserNumber">
            </mat-form-field>
            <mat-form-field appearance="outline">
                <mat-label>Permissions</mat-label>
                <mat-select [(value)]="selectedPermission">
                    <mat-option *ngFor="let permission of permissions" [value]="permission">
                        <span *ngIf="permission">{{permission}}</span>
                        <span *ngIf="!permission">None</span>
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" *ngIf="isNewShare" [disabled]="isAddDisabled()" (click)="onAddClick()">Add</button>
        <button mat-raised-button color="primary" *ngIf="!isNewShare" [disabled]="isUpdateDisabled()" (click)="onUpdateClick()">Save</button>
    </mat-card-actions>
</mat-card>