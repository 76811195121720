<form [formGroup]="form" (ngSubmit)="onSubmit(form.value)">
    <mat-card>
        <mat-card-title>Login</mat-card-title>
        <mat-card-content>
            <div *ngIf="!loading" class="text-box">
                <mat-form-field appearance="outline">
                    <mat-label>User Number</mat-label>
                    <input id="userName" matInput formControlName="userName" value="" name="userName" autocomplete="username">
                </mat-form-field>
                <mat-form-field appearance="outline">
                    <mat-label>Password</mat-label>
                    <input id="password" [type]="passwordHide ? 'password' : 'text'" matInput formControlName="password" value=""
                        name="password" autocomplete="current-password">
                    <button id="passwordShowHide" mat-button matSuffix mat-icon-button aria-label="Show Password" type="button"
                        (click)="passwordHide = !passwordHide">
                        <fa-icon [icon]="passwordHide ? faEye : faEyeSlash"></fa-icon>
                    </button>
                </mat-form-field>
                <div>
                    <a class="link" id="forgotPassword" [routerLink]="'/auth/recovery'">Forgot Password?</a>
                </div>
                <div>
                    <a class="link" id="register" [routerLink]="'/auth/register'">Register</a>
                </div>
            </div>
            <div *ngIf="loading" class="loading">
                <fa-icon id="loadingSpinner" [icon]="faCircleNotch" [spin]="true" class="loading-spinner"></fa-icon>
            </div>
        </mat-card-content>
        <mat-card-actions [align]="'end'" *ngIf="!loading">
            <button mat-raised-button id="login" color="primary" type="submit" class="button-action">Login</button>
        </mat-card-actions>
    </mat-card>
</form>
