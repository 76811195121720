<mat-card>
    <mat-card-title>Refresh Connection</mat-card-title>
    <mat-card-content>
        <p>
            Connection has been lost - please refresh to continue.
        </p>
    </mat-card-content>

    <mat-card-actions [align]="'end'">
        <button mat-stroked-button (click)="onClick()">Refresh</button>
    </mat-card-actions>
</mat-card>
