import { Component, OnInit, Inject } from "@angular/core";
import { faSync } from "@fortawesome/pro-regular-svg-icons";

import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { StateAwareFile } from "src/shared/models/state-aware-file";
import { AuthService } from 'src/shared/services/auth.service';
import { FileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { FileStatus } from 'src/shared/models/file-status';

@Component({
    selector: "app-refresh-license",
    templateUrl: "./refresh-license.component.html",
    styleUrls: ["./refresh-license.component.scss"],
})
export class RefreshLicenseComponent implements OnInit {
    public file: StateAwareFile;
    public faSync = faSync;
    public result: boolean = false;
    public _apiUrl: string;

    constructor(
        public _fileServiceProxy: FileServiceProxy,
        public _authService: AuthService,
        public dialogRef: MatDialogRef<RefreshLicenseComponent>,
        @Inject(MAT_DIALOG_DATA) public data: StateAwareFile
    ) {
        this.file = data;
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit(): void {
        this.injectRevision();
    }

    public injectRevision(): void {
        this._fileServiceProxy
            .injectRevision(this.file.backupDetails.summary.id)
            .pipe(
                catchError((err) => {
                    return throwError(err);
                })
            )
            .subscribe(() => {
                this.file.setFileStatus(FileStatus.Processing);
            });
    }
}
