import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import {
    faTrash,
    faFolder,
    faHistory,
    faShareAlt,
} from "@fortawesome/pro-regular-svg-icons";

import { catchError } from "rxjs/operators";
import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { throwError } from "rxjs";
import { Router } from "@angular/router";
import { BackupFileDetails, FileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { FileService } from 'src/shared/services/file.service';

@Component({
    selector: "app-delete-file",
    templateUrl: "./delete-file.component.html",
    styleUrls: ["./delete-file.component.scss"],
})
export class DeleteFileComponent implements OnInit {
    public faTrash = faTrash;
    public faFolder = faFolder;
    public faHistory = faHistory;
    public faShareAlt = faShareAlt;
    public file: BackupFileDetails;
    public accepted = false;
    private configSnackBar: MatSnackBarConfig = {
        panelClass: ["style-bar"],
        duration: 3000,
    };

    constructor(
        public dialogRef: MatDialogRef<DeleteFileComponent>,
        private _fileService: FileService,
        private _revisionServiceProxy: FileServiceProxy,
        private _snackBar: MatSnackBar,
        private _router: Router,
        @Inject(MAT_DIALOG_DATA) public data: BackupFileDetails
    ) {
        this.file = data;
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onDelete(): void {
            this._revisionServiceProxy
                .file2(
                    this.data.summary.id,
                    this.data.summary.fileType
                )
                .pipe(
                    catchError((err) => {
                        this._snackBar.open(
                            "Could not delete file",
                            null,
                            this.configSnackBar
                        );
                        return throwError(err);
                    })
                )
                .subscribe(() => {
                    this._snackBar.open(
                        "File deleted successfully",
                        null,
                        this.configSnackBar
                    );
                    this._fileService.getFiles(true);
                    this._router.navigateByUrl("/home/files");
                    this.dialogRef.close(true);
                });
    }

    ngOnInit() {}
}
