import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { faCheckCircle } from '@fortawesome/pro-regular-svg-icons';

@Component({
  selector: 'app-recovery-confirmation',
  templateUrl: './recovery-confirmation.component.html',
  styleUrls: ['./recovery-confirmation.component.scss']
})
export class RecoveryConfirmationComponent implements OnInit {

  public faCheckCircle = faCheckCircle;

  constructor(private _router: Router) { }

  ngOnInit() {
  }

  public returnToLogin()
  {
    this._router.navigateByUrl('/auth/login');
  }

}
