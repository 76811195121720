<mat-form-field appearance="fill">
    <mat-label>Discount Code</mat-label>
    <input matInput placeholder="Enter discount code" [(ngModel)]="currentCode"/>
</mat-form-field>
<button id="apply" mat-raised-button color="primary" (click)="verifyCode()">
    Apply
</button>
<div *ngIf="appliedCoupon">
    <span>Applied Code</span>
    <br/>
    <span class="right-spacing">{{appliedCoupon.name}}</span>
    <span *ngIf="appliedCoupon.amountOff && appliedCoupon.isValid">Amount Off: {{appliedCoupon.amountOff}}</span>
    <span *ngIf="appliedCoupon.percentOff && appliedCoupon.isValid">Percent Off: {{appliedCoupon.percentOff}}%</span>
    <span *ngIf="!appliedCoupon.isValid">Invalid Code</span>
    <button id="remove" mat-stroked-button color="warn" class="left-spacing" (click)="removeCode()">
        Remove
    </button>
</div>
