import { Component, OnInit, Inject } from "@angular/core";
import {
    faFileCertificate,
    faCircleNotch,
    faFolder,
    faHistory
} from "@fortawesome/pro-regular-svg-icons";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

import { MatSnackBarConfig, MatSnackBar } from "@angular/material/snack-bar";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { BackupFileDetails, FileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';
import { FileService } from 'src/shared/services/file.service';

@Component({
    selector: "app-delete-local-wagemaster",
    templateUrl: "./delete-local-wagemaster.component.html",
    styleUrls: ["./delete-local-wagemaster.component.scss"],
})
export class DeleteLocalWagemasterComponent implements OnInit {
    public faFileCertificate = faFileCertificate;
    public faCircleNotch = faCircleNotch;
    public faFolder = faFolder;
    public faHistory = faHistory;
    public file: BackupFileDetails;
    public accepted = false;
    private configSnackBar: MatSnackBarConfig = {
        panelClass: ["style-bar"],
        duration: 3000,
    };

    constructor(
        public dialogRef: MatDialogRef<DeleteLocalWagemasterComponent>,
        private _fileService: FileService,
        private _revisionServiceProxy: FileServiceProxy,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public data: BackupFileDetails
    ) {
        this.file = data;
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onDelete(): void {
        this._revisionServiceProxy
            .file2(
                this.data.summary.id,
                this.data.summary.fileType
            )
            .pipe(
                catchError((err) => {
                    this._snackBar.open(
                        "Could not delete file",
                        null,
                        this.configSnackBar
                    );
                    return throwError(err);
                })
            )
            .subscribe(() => {
                this._snackBar.open(
                    "File deleted successfully",
                    null,
                    this.configSnackBar
                );
                this._fileService.getFiles(true);
                this.dialogRef.close(true);
            });
        
    }

    ngOnInit() {}
}
