<div *ngIf="!loading">
    <div class="wagemaster-message mat-elevation-z2 orange-status-box" *ngIf="showWagemasterBanner() && !summary.isPendingInjection" (click)="openWagemasterLink()">
        <div class="message">
            <fa-icon [icon]="faExclamationTriangle" class="black-icon file-status-icon"></fa-icon>
            <span class="message-text">This Agrimaster-Plus subscription does not have a Wagemaster file linked, Click here to link file</span>
        </div>
    </div>
    <div class="wagemaster-message mat-elevation-z2 orange-status-box" *ngIf="summary.isPendingInjection">
        <div class="message">
            <fa-icon [icon]="faExclamationTriangle" class="black-icon file-status-icon"></fa-icon>
            <span class="message-text">This file is in the process of being licenced. Once licenced you will be able to open your file</span>
        </div>
    </div>
    <div class="horizontal-container">
        <mat-card class="card horizontal-card">
            <mat-card-title>
                <fa-icon [icon]="faInfoCircle" class="file-status"></fa-icon><span class="header-text">File
                    Details</span>
            </mat-card-title>
            <mat-card-content>
                <div class="grid-container">
                    <div class="abn-label">
                        <span>ABN:</span>
                    </div>
                    <div class="file-type-label">
                        <span>File Type:</span>
                    </div>
                    <div class="file-size-label">
                        <span>File Size:</span>
                    </div>
                    <div class="abn-value">
                        <span>{{summary.subscription.abn}}</span>
                    </div>
                    <div class="file-type-value">
                        <span>{{fileType}}</span>
                    </div>
                    <div class="file-size-value">
                        <span>{{transformFileSize(summary.fileSize)}}</span>
                    </div>
                    <div class="username-label">
                        <span>User Number:</span>
                    </div>
                    <div class="created-by-label">
                        <span>Created By:</span>
                    </div>
                    <div class="subscription-label">
                        <span *ngIf="isEditionEnabled">Subscription:</span>
                    </div>
                    <div class="username-value">
                        <span>{{summary.userOwnedBy.userName}}</span>
                    </div>
                    <div class="created-by-value">
                        <span>{{summary.userOwnedBy.firstName + ' ' + summary.userOwnedBy.lastName}}</span>
                    </div>
                    <div class="subscription-value">
                        <span *ngIf="isEditionEnabled">{{summary.subscription.planType}}</span>
                    </div>
                </div>
            </mat-card-content>
            <mat-card-actions [align]="'end'" *ngIf="showBothOpenButtons()">
                <button mat-button *ngIf="this.stashFiles != null && this.stashFiles.length > 0" (click)="openStashFileList()">Conflict Files</button>
                <button mat-button *ngIf="this.summary.fileType == BackupType.Agrimaster && currentFileStatus == FileStatus.BackedUp" (click)="refreshLicense()">Refresh Licence</button>
                <button mat-button (click)="openReadOnly()">Open Read Only</button>
                <button mat-raised-button color="primary" aria-label="Open" (click)="openFile()" [disabled]="openDisabled">
                    Open
                </button>
            </mat-card-actions>
            <mat-card-actions [align]="'end'" *ngIf="showOnlyReadOnly()">
                <button mat-button *ngIf="this.summary.fileType == BackupType.Agrimaster && currentFileStatus == FileStatus.BackedUp" (click)="refreshLicense()">Refresh Licence</button>
                <button mat-raised-button color="primary" (click)="openReadOnly()" *ngIf="!this.retryDisplayed">Open Read Only</button>
            </mat-card-actions>
            <mat-card-actions [align]="'end'" *ngIf="this.retryDisplayed">
                <button mat-raised-button color="primary" (click)="injectRevision()" *ngIf="this.retryDisplayed">Retry</button>
            </mat-card-actions>
        </mat-card>
        <mat-card class="card horizontal-card sharing-card">
            <mat-card-title>
                <fa-icon [icon]="faShareAlt" class="file-status"></fa-icon><span class="header-text">Sharing</span>
            </mat-card-title>
            <mat-card-content *ngIf="summary.userOwnedBy.userName === userName">
                <table mat-table [dataSource]="sharedToList" class="table">
                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef>User Number</th>
                        <td mat-cell *matCellDef="let element">{{element.userSharedWith}}</td>
                        <td mat-footer-cell *matFooterCellDef><span>Add new share</span></td>
                    </ng-container>
                    <ng-container matColumnDef="permissions">
                        <th mat-header-cell *matHeaderCellDef>Permissions</th>
                        <td mat-cell *matCellDef="let element"> {{getSharingPermissionString(element.permissions)}}
                        </td>
                        <td mat-footer-cell *matFooterCellDef></td>
                    </ng-container>
                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef>Actions</th>
                        <td mat-cell *matCellDef="let element">
                            <button mat-stroked-button (click)="openManageShare(element)">Update</button>
                        </td>
                        <td mat-footer-cell *matFooterCellDef>
                            <button mat-stroked-button (click)="openManageShare()">Add</button>
                        </td>
                    </ng-container>
                    <tr mat-header-row *matHeaderRowDef="sharingDisplayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: sharingDisplayedColumns;" class="row"></tr>
                    <tr mat-footer-row *matFooterRowDef="sharingDisplayedColumns" class="add-row"></tr>
                </table>
            </mat-card-content>
            <mat-card-content *ngIf="summary.userOwnedBy.userName !== userName">
                <p class="message">User {{summary.userOwnedBy.userName}} shared this file with you</p>
            </mat-card-content>
            <mat-card-actions [align]="'end'" *ngIf="summary.userOwnedBy.userName !== userName && isRejectEnabled">
                <button mat-button color="warn" (click)="rejectShare()">Reject Share</button>
            </mat-card-actions>
        </mat-card>
    </div>
    <mat-card class="card" >
        <mat-card-title>
            <fa-icon [icon]="faHistory" class="file-status"></fa-icon><span class="header-text">File
                History</span>
        </mat-card-title>
        <mat-card-content>
            <p class="message" *ngIf="summary.subscription.planType === 'Agrimaster Read Only'">No history is available for read only files</p>
            <table mat-table [dataSource]="historyList" class="table" *ngIf="summary.subscription.planType !== 'Agrimaster Read Only'">
                <ng-container matColumnDef="icon">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <mat-chip-list aria-label="Current">
                            <mat-chip color="primary" *ngIf="element.id === summary.id"
                                selected>
                                Current</mat-chip>
                        </mat-chip-list>
                    </td>
                </ng-container>
                <ng-container matColumnDef="modified">
                    <th mat-header-cell *matHeaderCellDef>Modified</th>
                    <td mat-cell *matCellDef="let element">{{element.modifiedDate.fromNow()}}</td>
                </ng-container>
                <ng-container matColumnDef="modifiedBy">
                    <th mat-header-cell *matHeaderCellDef>By</th>
                    <td mat-cell *matCellDef="let element">
                        {{element.userSavedBy.firstName + ' ' + element.userSavedBy.lastName}} </td>
                </ng-container>
                <ng-container matColumnDef="license-status-icon">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="displayLicenseStatus(element)">
                            <div *ngIf="element.isLicenseInjectionSuccessful != null && !element.isLicenseInjectionSuccessful">
                                <fa-icon [icon]="faTimes" class="licence-status-failed license-status-icon"></fa-icon>                            
                            </div>
                            <div *ngIf="element.isLicenseInjectionSuccessful != null && element.isLicenseInjectionSuccessful">
                                <fa-icon [icon]="faCheck" class="licence-status-success license-status-icon" ></fa-icon>
                            </div>
                            <div *ngIf="element.isLicenseInjectionSuccessful === null">
                                <fa-icon [icon]="faCircleNotch" [spin]="true" class="licence-status-normal license-status-icon" ></fa-icon>
                            </div>
                        </div>
                    </td>
                </ng-container>
                <ng-container matColumnDef="license-status">
                    <th mat-header-cell *matHeaderCellDef>Licence Status</th>                    
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="displayLicenseStatus(element)">
                            {{element.licensingStatus}}                   
                        </div> 
                    </td>
                </ng-container>

                <ng-container matColumnDef="normalisation-status-icon" *ngIf="normalisationFeatureEnabled">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="displayLicenseStatus(element)">
                            <div *ngIf="element.isNormalisationSuccessful != null && !element.isNormalisationSuccessful">
                                <fa-icon [icon]="faTimes" class="licence-status-failed normalisation-status-icon"></fa-icon>                            
                            </div>
                            <div *ngIf="element.isNormalisationSuccessful != null && element.isNormalisationSuccessful">
                                <fa-icon [icon]="faCheck" class="licence-status-success normalisation-status-icon" ></fa-icon>
                            </div>
                            <div *ngIf="element.isNormalisationSuccessful === null">
                                <fa-icon [icon]="faCircleNotch" [spin]="true" class="licence-status-normal normalisation-status-icon" ></fa-icon>
                            </div>
                        </div>

                    </td>
                </ng-container>
                <ng-container matColumnDef="normalisation-status" *ngIf="normalisationFeatureEnabled">
                    <th mat-header-cell *matHeaderCellDef>Processing Status</th>
                    <td mat-cell *matCellDef="let element">
                        <div *ngIf="displayLicenseStatus(element)">
                            {{element.normalisationStatus}}
                        </div>
                    </td>

                </ng-container>                
                <ng-container matColumnDef="note">
                    <th mat-header-cell *matHeaderCellDef>Note</th>
                    <td mat-cell *matCellDef="let element"> {{element.note}} </td>
                </ng-container>
                <ng-container matColumnDef="actions">
                    <th mat-header-cell *matHeaderCellDef></th>
                    <td mat-cell *matCellDef="let element">
                        <button mat-button [matMenuTriggerFor]="historyMenu" aria-label="History Menu" *ngIf="!element.modifiedDate.isSame(summary.modifiedDate) || userCreated(element)">
                            <fa-icon [icon]="faEllipsisV"></fa-icon>
                        </button>
                        <mat-menu #historyMenu="matMenu" xPosition="before">
                            <button mat-menu-item (click)="restoreFile(element)"
                                *ngIf="!element.modifiedDate.isSame(summary.modifiedDate) && file.sharedStatus != ShareStatus.Read && currentFileStatus == FileStatus.BackedUp">
                                <span>Restore File</span>
                            </button>
                            <button mat-menu-item *ngIf="!element.modifiedDate.isSame(summary.modifiedDate)"
                                (click)="openRevisionReadOnly(element)">
                                <span>Open File as Read Only</span>
                            </button>
                            <button mat-menu-item (click)="openDialog(element)" *ngIf="userCreated(element)">
                                <span *ngIf="!element.note">Add Note</span>
                                <span *ngIf="element.note">Edit Note</span>
                            </button>
                        </mat-menu>
                    </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="historyDisplayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: historyDisplayedColumns;" class="row"></tr>
            </table>
        </mat-card-content>
    </mat-card>
</div>
<div *ngIf="loading" class="loading">
    <fa-icon [icon]="faCircleNotch" [spin]="true" class="loading-spinner"></fa-icon>
</div>