import { Component, Input, OnInit, ViewChild } from '@angular/core';
import {
  faTimes,
  faDownload,
  faExclamationTriangle,
  faCloud,
  faUpload,
  faCog,
  faHdd,
  faLock,
  faBinoculars,
  faCircleNotch,
  faUserLock,
  faHourglassEnd,
  faTimesCircle,
  faCheckCircle,
  faPlus,
  faPencil
} from '@fortawesome/pro-regular-svg-icons';
import { faDoNotEnter } from '@fortawesome/pro-solid-svg-icons';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { from } from 'rxjs';
import * as configcat from 'configcat-js';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { MatSort } from '@angular/material/sort';
import { ConfigureBankFeedsComponent } from '../configure-bank-feeds/configure-bank-feeds.component';
import { BackupType, BankAccount, BankAccountServiceProxy, FileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { HomeService } from 'src/shared/services/home.service';
import { AuthService } from 'src/shared/services/auth.service';
import { StateAwareFile } from 'src/shared/models/state-aware-file';
import { AppConfigService } from 'src/shared/services/app-config.service';
import { FileStatus } from 'src/shared/models/file-status';
import { FileService } from 'src/shared/services/file.service';

export interface FileListFilter {
  nameFilter: string;
  typeFilter: string;
}

@Component({
  selector: 'app-bank-account-list',
  templateUrl: './bank-account-list.component.html',
  styleUrls: ['./bank-account-list.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)'))
    ])
  ]
})
export class BankAccountListComponent implements OnInit {
  public filter: FileListFilter;
  public faDownload = faDownload;
  public faTimes = faTimes;
  public faExclamationTriangle = faExclamationTriangle;
  public faCloud = faCloud;
  public faUpload = faUpload;
  public faCog = faCog;
  public faHdd = faHdd;
  public faLock = faLock;
  public faCircleNotch = faCircleNotch;
  public faBinoculars = faBinoculars;
  public faUserLock = faUserLock;
  public faDoNotEnter = faDoNotEnter;
  public faHourglassEnd = faHourglassEnd;
  public faCheckCircle = faCheckCircle;
  public faTimesCircle = faTimesCircle;
  public faPlus = faPlus;
  public faPencil = faPencil;

  public isBankAccountManagementEnabled: boolean;
  public isBankFeedsEnabled: boolean;
  public businessId: number;
  public businessType: string;

  @Input() public file: StateAwareFile;
  public FileStatus = FileStatus;
  public BackupType = BackupType;
  public currentUserName: string;
  public displayedColumns: string[] = ['bank', 'type', 'name', 'active', 'feedStatus', 'actionEdit'];
  public expandedElement: BankAccount | null;
  public isEditMode: boolean = false;

  public accountList: MatTableDataSource<BankAccount>;
  public loading: boolean;

  @ViewChild(MatSort) sort: MatSort;

  constructor(
    public homeService: HomeService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _authService: AuthService,
    public dialog: MatDialog,
    private _bankAccountService: BankAccountServiceProxy,
    private _fileService: FileService
  ) {
    this.loading = true;
    this.accountList = new MatTableDataSource<BankAccount>();
    this._route.parent.paramMap.subscribe((params: Params) => {
      this.businessId = Number(params.get('id'));
      this.businessType = params.get('type');

      this._fileService.getFile(this.businessId.toString(), this.businessType).subscribe((result) => {
        this.file = result as StateAwareFile;

        this.configureToggling();
      });
    });
  }

  ngOnInit(): void {
    this._bankAccountService.bankAccount(this.businessId, null).subscribe((data) => {
      let newList = new MatTableDataSource<BankAccount>(data);
      newList.sort = this.sort;
      this.accountList = newList;
      this.loading = false;
    });
  }

  ngAfterViewInit() {
    this.accountList.sort = this.sort;
  }

  configureToggling() {
    if (this._authService.toggleClient) {
      from(
        this._authService.toggleClient.getValueAsync(
          'bankaccountmanagementenabled',
          false,
          this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
        )
      ).subscribe((result) => {
        this.isBankAccountManagementEnabled = result;
        if (!result) {
          this._router.navigateByUrl(`/home`);
        }
      });

      from(
        this._authService.toggleClient.getValueAsync(
          'bankfeedsenabled',
          false,
          this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
        )
      ).subscribe((result) => {
        this.isBankFeedsEnabled = result;
      });
    } else {
      this._authService.toggleClient = configcat.createClientWithAutoPoll(AppConfigService.settings.configCat, {
        pollIntervalSeconds: AppConfigService.settings.configInterval,
        dataGovernance: configcat.DataGovernance.Global
      });

      from(
        this._authService.toggleClient.getValueAsync(
          'bankaccountmanagementenabled',
          false,
          this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
        )
      ).subscribe((result) => {
        this.isBankAccountManagementEnabled = result;
        if (!result) {
          this._router.navigateByUrl(`/home`);
        }
      });

      from(
        this._authService.toggleClient.getValueAsync(
          'bankfeedsenabled',
          false,
          this._authService.getSubscriptionSpecificConfigToggleUser(this.file.currentSummary.subscription.planType)
        )
      ).subscribe((result) => {
        this.isBankFeedsEnabled = result;
      });
    }
  }

  openEditAccountDialog(account: BankAccount) {}

  openNewAccountDialog() {}

  toggleEditMode() {
    if (!this.isEditMode) {
    } else {
    }
    this.isEditMode = !this.isEditMode;
  }

  configureBankFeedsDialog() {
    var dialogRef = this.dialog.open(ConfigureBankFeedsComponent, {
      width: '850px',
      height: '500px',
      data: this.businessId,
      panelClass: 'agrimaster-dialog-container'
    });
  }
}

