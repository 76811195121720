import { BackupFileDetails } from '../service-proxies/api-service-proxies';
import { FileStatus } from './file-status';
import { ShareStatus } from './share-status';


export class TrackedFile implements ITrackedFile {
    fileStatus?: FileStatus;
    sharedStatus?: ShareStatus;
    backupDetails?: BackupFileDetails;
    conflictBackupDetails?: BackupFileDetails;

    constructor(data?: ITrackedFile) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.fileStatus = _data["fileStatus"];
            this.sharedStatus = _data["sharedStatus"];
            this.backupDetails = _data["backupDetails"] ? BackupFileDetails.fromJS(_data["backupDetails"]) : <any>undefined;
            this.conflictBackupDetails = _data["conflictBackupDetails"] ? BackupFileDetails.fromJS(_data["conflictBackupDetails"]) : <any>undefined;
        }
    }

    static fromJS(data: any): TrackedFile {
        data = typeof data === 'object' ? data : {};
        let result = new TrackedFile();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["fileStatus"] = this.fileStatus;
        data["sharedStatus"] = this.sharedStatus;
        data["backupDetails"] = this.backupDetails ? this.backupDetails.toJSON() : <any>undefined;
        data["conflictBackupDetails"] = this.conflictBackupDetails ? this.conflictBackupDetails.toJSON() : <any>undefined;
        return data; 
    }
}

export interface ITrackedFile {
    fileStatus?: FileStatus;
    sharedStatus?: ShareStatus;
    backupDetails?: BackupFileDetails;
    conflictBackupDetails?: BackupFileDetails;
}