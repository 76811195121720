<fa-icon [icon]="faCircleNotch" [spin]="true" class="file-status" *ngIf="orderTotalsLoading"></fa-icon>
<app-discount-code *ngIf="!hasProRata" [ngClass]="{'hide': orderTotalsLoading}" (codeAdded)="onCodeAdded($event)"></app-discount-code>
<table aria-label="Order summary section" id="orderSummaryTotalsTable" aria-hidden="true" *ngIf="!orderTotalsLoading">
    <tr>
        <td id="productNameColumn"> {{ productName }} </td>
        <td id="productValueColumn"> {{ productValue | currency:'AUD':'symbol-narrow'}}</td>
    </tr>
    <tr *ngIf="hasProRata">
        <td id="proRataNameColumn"> {{ proRataName }} </td>
        <td id="proRataValueColumn"> {{ proRataValue | currency:'AUD':'symbol-narrow'}}</td>
    </tr>
    <tr>
        <td> Subtotal </td>
        <td id="subTotalValueColumn"> {{ subtotalValue | currency:'AUD':'symbol-narrow'}}</td>
    </tr>
    <tr>
        <td> GST </td>
        <td id="taxValueColumn"> {{ taxValue | currency:'AUD':'symbol-narrow'}}</td>
    </tr>
    <tr>
        <td><strong>Total (inc GST)</strong></td>
        <td id="totalValueColumn"><strong>{{ totalValue | currency:'AUD':'symbol-narrow'}}</strong></td>
    </tr>
</table>
