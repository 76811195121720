import { Component, OnInit } from "@angular/core";
import {
    faTimes,
    faDownload,
    faExclamationTriangle,
    faCloud,
    faUpload,
    faCog,
    faHdd,
    faLock,
    faBinoculars,
    faCircleNotch,
    faUserLock,
    faHourglassEnd
} from "@fortawesome/pro-regular-svg-icons";
import { faDoNotEnter } from "@fortawesome/pro-light-svg-icons";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { StateAwareFile } from "src/shared/models/state-aware-file";
import { DeleteLocalWagemasterComponent } from "../delete-local-wagemaster/delete-local-wagemaster.component";
import { ShareUnlicensedComponent } from "../share-unlicensed/share-unlicensed.component";
import { OpenReadOnlyComponent } from "../open-read-only/open-read-only.component";
import { SubscriptionExpiredComponent } from '../subscription-expired/subscription-expired.component';
import { BackupType } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';
import { HomeService } from 'src/shared/services/home.service';
import { FileStatus } from 'src/shared/models/file-status';
import { FileService } from 'src/shared/services/file.service';
import { ShareStatus } from 'src/shared/models/share-status';
import { from } from "rxjs";
import { AppConfigService } from "src/shared/services/app-config.service";
import * as configcat from "configcat-js";

export interface FileListFilter {
    nameFilter: string;
    typeFilter: string;
}

@Component({
    selector: "app-file-list",
    templateUrl: "./file-list.component.html",
    styleUrls: ["./file-list.component.scss"],
})
export class FileListComponent implements OnInit {
    public filter: FileListFilter;
    public faDownload = faDownload;
    public faTimes = faTimes;
    public faExclamationTriangle = faExclamationTriangle;
    public faCloud = faCloud;
    public faUpload = faUpload;
    public faCog = faCog;
    public faHdd = faHdd;
    public faLock = faLock;
    public faCircleNotch = faCircleNotch;
    public faBinoculars = faBinoculars;
    public faUserLock = faUserLock;
    public faDoNotEnter = faDoNotEnter;
    public faHourglassEnd = faHourglassEnd;    
    public isFileListEnabled: boolean = false;

    public FileStatus = FileStatus;
    public BackupType = BackupType;
    public currentUserName: string;
    public displayedColumns: string[] = [
        "icon",
        "name",
        "createdBy",
        "fileStatusIcon",
        "fileStatus",
        "sharedStatus",
        "changedDetails",
    ];
    public fileList: MatTableDataSource<StateAwareFile>;
    public loading: boolean;

    constructor(
        public homeService: HomeService,
        private _router: Router,
        private _authService: AuthService,
        public dialog: MatDialog,
        private _fileService: FileService
    ) {
        this.filter = {
            nameFilter: "",
            typeFilter: "All",
        };
        this.homeService.homeParams.next({ homeTitle: "File Manager" });
        this.currentUserName = this._authService.currentUserValue.userName;
        this.loading = true;

        this.configureToggling();


        this._fileService.getFilesIfNone();

        this._fileService.haveFetchedFiles$.subscribe((data) => {
            this.loading = !data;
        });

        this._fileService.files$.subscribe((data) => {
            let newList = new MatTableDataSource<StateAwareFile>(data);
            newList.filterPredicate = this.createFilter();
            newList.filter = JSON.stringify(this.filter);
            this.fileList = newList;
        });
    }

    public applyFilter() {
        this.fileList.filter = JSON.stringify(this.filter);
    }

    ngOnInit() {}

    public openBusiness(row: StateAwareFile) {
        let subscriptionId = 0;

        if(row.backupDetails.summary.subscriptionId)
        {
            subscriptionId = row.backupDetails.summary.subscriptionId;
        }

        if (row.currentStatus == FileStatus.Conflict) {            
            return;
        }

        if (row.backupDetails.summary.subscriptionId && row.currentStatus !== FileStatus.Locked) {

            this._router.navigateByUrl(
                "/home/business/" +
                    row.backupDetails.summary.businessId +
                    "/" +
                    row.backupDetails.summary.fileType
            );
        } else if (
            row.backupDetails.summary.fileType == BackupType.Wagemaster
        ) {
            var checksum = "";
            if(row.backupDetails.summary.fileChecksum != null)
            {
                checksum = row.backupDetails.summary.fileChecksum
            }
            window.open(
                "wagemaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    row.backupDetails.summary.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(row.backupDetails.summary.name)+
                    "/" +
                    ""  + //readonly | stash
                    "/" +
                    ""  +//revision guid
                    "/" +
                    encodeURIComponent(checksum),
                "_self"
            );
        } else if (
            row.backupDetails.summary.fileType == BackupType.Agrimaster &&
            row.currentStatus == FileStatus.Offline
        ) {
            window.open(
                "agrimaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    row.backupDetails.summary.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(row.backupDetails.summary.name),
                "_self"
            );
        }
    }

    // public openAssignLicenses(file: StateAwareFile): void {
    //     var dialogRef = this.dialog.open(ReviewLicensesComponent, {
    //         width: "850px",
    //         data: file,
    //         panelClass: "agrimaster-dialog-container",
    //     });
    // }

    public openSubscriptionExpired(file: StateAwareFile): void {
        var dialogRef = this.dialog.open(SubscriptionExpiredComponent, {
            width: "850px",
            data: file.backupDetails.summary,
            panelClass: "agrimaster-dialog-container",
        });
    }

    public openUnlicencedShare(file: StateAwareFile): void {
        var dialogRef = this.dialog.open(ShareUnlicensedComponent, {
            width: "850px",
            data: file,
            panelClass: "agrimaster-dialog-container",
        });
    }

    public openWagemasterLearnMore(file: StateAwareFile): void {
        var dialogRef = this.dialog.open(DeleteLocalWagemasterComponent, {
            width: "850px",
            data: file.backupDetails,
            panelClass: "agrimaster-dialog-container",
        });
    }
  
    public getSharingPermissionString(share?: ShareStatus): string {
        if (share == ShareStatus.Read) {
            return "Read Only";
        }

        if (share == ShareStatus.ReadWrite) {
            return "Read And Write";
        }

        return "";
    }

    public createFilter() {
        let filterFunction = function (
            data: StateAwareFile,
            filter: string
        ): boolean {
            let searchTerms = JSON.parse(filter) as FileListFilter;
            let typeSearch = true;

            if (searchTerms.typeFilter != "All") {
                typeSearch =
                    data.backupDetails.summary.fileType ==
                    searchTerms.typeFilter;
            }

            let genericSearch = false;

            if (!isNaN(Number(searchTerms.nameFilter))) {
                if (data.backupDetails.summary.userOwnedBy.userName) {
                    if (
                        data.backupDetails.summary.userOwnedBy.userName.includes(
                            searchTerms.nameFilter
                        )
                    ) {
                        genericSearch = true;
                    }
                }
            } else {
                let nameSearch = () => {
                    let found = false;
                    let name = data.backupDetails.summary.name;
                    let word = searchTerms.nameFilter.trim().toLowerCase();
                    if (name.toLowerCase().indexOf(word) != -1) {
                        found = true;
                    }
                    return found;
                };

                genericSearch = nameSearch();
            }

            return typeSearch && genericSearch;
        };
        return filterFunction;
    }

    configureToggling() {
        if (this._authService.toggleClient) {
            from(
                this._authService.toggleClient.getValueAsync(
                    "fileListEnabled",
                    false,
                    this._authService.currentUserValue.configUser
                )
            ).subscribe((result) => {
                this.isFileListEnabled = result;
                if (!result) {
                  this._router.navigateByUrl(`/home`);
                }
            });
        } else {
            this._authService.toggleClient = configcat.createClientWithAutoPoll(
                AppConfigService.settings.configCat,
                { pollIntervalSeconds: AppConfigService.settings.configInterval }
            );

            from(
                this._authService.toggleClient.getValueAsync(
                    "fileListEnabled",
                    false,
                    this._authService.currentUserValue.configUser
                )
            ).subscribe((result) => {
                this.isFileListEnabled = result;
                if (!result) {
                  this._router.navigateByUrl(`/home`);
                }
            });
        }
    }
}
