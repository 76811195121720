import { Component, OnInit, Inject } from "@angular/core";
import { faExclamationTriangle } from "@fortawesome/pro-regular-svg-icons";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { StateAwareFile } from 'src/shared/models/state-aware-file';
import { FileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';

@Component({
    selector: "app-share-unlicensed",
    templateUrl: "./share-unlicensed.component.html",
    styleUrls: ["./share-unlicensed.component.scss"],
})
export class ShareUnlicensedComponent implements OnInit {
    public file: StateAwareFile;
    public faExclamationTriangle = faExclamationTriangle;
    private configSnackBar: MatSnackBarConfig = {
        panelClass: ["style-bar"],
        duration: 3000,
    };
    public accepted = false;

    constructor(
        public _revisionServiceProxy: FileServiceProxy,
        public _authService: AuthService,
        private _snackBar: MatSnackBar,
        public dialogRef: MatDialogRef<ShareUnlicensedComponent>,
        @Inject(MAT_DIALOG_DATA) public data: StateAwareFile
    ) {
        this.file = data;
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public rejectShare() {
        this._revisionServiceProxy.reject(
                this.file.backupDetails.sharedWith[0].id
            )
            .pipe(
                catchError((err) => {
                    this._snackBar.open(
                        "Could not reject share",
                        null,
                        this.configSnackBar
                    );
                    return throwError(err);
                })
            )
            .subscribe((result) => {
                this._snackBar.open(
                    "Share rejected successfully",
                    null,
                    this.configSnackBar
                );
                this.dialogRef.close();
            });
    }

    ngOnInit(): void {}
}
