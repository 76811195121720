<mat-card>
  <mat-card-title
    ><fa-icon [icon]="faHourglassEnd" class="file-status warning"></fa-icon><span class="header-text">Subscription has expired - {{ file.name }}</span></mat-card-title
  >
  <mat-card-content>
    <div>
      <div class="container">
        <p>It appears that your subscription has expired.</p>
        <p>Please click the Renew button below to renew your subscription and continue using Agrimaster.</p>
      </div>
    </div>
  </mat-card-content>
  <mat-card-actions [align]="'end'">
    <button mat-button (click)="onNoClick()">Close</button>
    <a mat-raised-button color="primary" href="https://support.agrimaster.com.au/hc/en-us/articles/5263560797711-Updating-My-Billing-Details" target="_blank" rel="noopener"
      >Renew</a
    >
  </mat-card-actions>
</mat-card>
