import { Component, EventEmitter, Output } from '@angular/core';
import { ShopCoupon } from 'src/shared/service-proxies/api-service-proxies';

@Component({
    selector: 'app-discount-code',
    templateUrl: './discount-code.component.html',
    styleUrls: ['./discount-code.component.scss'],
})
export class DiscountCodeComponent {
    @Output() codeAdded: EventEmitter<string> = new EventEmitter();

    public currentCode: string;
    public appliedCoupon: ShopCoupon;

    constructor() {
        this.currentCode = "";
        this.appliedCoupon = null;
    }

    public verifyCode(){
        this.appliedCoupon = null;
        this.codeAdded.emit(this.currentCode);
        this.currentCode = "";
    }

    public removeCode(){
        this.appliedCoupon = null;
        this.codeAdded.emit(undefined);
        this.currentCode = "";
    }

    public getCoupon(coupon: ShopCoupon){
        this.appliedCoupon = coupon;
    }
}
