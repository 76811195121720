export class Messages {
  static shopProductReturnUrl: string = 'https://www.agrimaster.com.au/shop?utm_source=saleautomation&utm_medium=app';
  static shopDisqualifiedProductReturnUrl: string = 'https://www.agrimaster.com.au/shop?utm_source=saleautomation&utm_medium=app&utm_campaign=<PRODUCTCODE>disqualified';
  static shopInvalidProductUrl: string = 'https://www.agrimaster.com.au/shop?utm_source=saleautomation&utm_medium=app&utm_campaign=invalid_product_code';
  static shopReturnUrlSuccess: string = 'https://www.agrimaster.com.au/shop?utm_source=saleautomation&utm_medium=app&utm_campaign=<PRODUCTCODE>';

  static cannotFindExistingUserErrorMessage: string = 'Failed to locate Agrimaster customer with specified details';
  static ags7FilterSearchExistingUserErrorMessage = `an existing user must already be an AGS7 user, or have no subscriptions`;
  static ineligibilityDueToCashbook: string =
    'You do not currently qualify for Agrimaster <PRODUCTNAME>. To be eligible for this add on subscription, an Agrimaster Standard or Plus subscription is required.';
  static ineligibilityDueToBudgetsOrPayroll: string = 'We are sorry but unfortunately this product does not include Budgeting or Payroll';
  static ineligibilityDueToPartner: string = 'We are sorry but at the current time you are inelligible to purchase Agrimaster Essentials due to your partner status';
  static ineligibilityExtraordinaryExpiry: string = 'Due to your special account status please contact the sales team to purchase additional products.';
  static ineligibilityDueToExistingCustomer: string = 'Existing customer is not allowed to purchase <PRODUCTCODE>';
  static ineligibilityAGS7ToPurchaseSubscription: string = 'AGS7 user is not allowed to purchase <PRODUCTCODE> subscription';
  static expiredUser: string = 'You must hold an active subscription to be able to purchase add-on products';
  static incompleteProfile: string = 'Unfortunately we do not have enough information to process this online, please contact our Sales team.';

  static confirmationAndNextSteps: string =
    'Thank you for your purchase. You will shortly receive an email detailing what happens next. If the email does not arrive, please check your junk or spam folder.';
  static confirmationPurchasedByStaffPendingPayment: string = 'The purchase is incomplete until payment is received and manually updated in Stripe.';
  static confirmationPurchasedByStaffPaidByCard: string = 'The purchase has been successful on behalf of the customer';

  static adviseCustomer: string = 'Advise customer of what happens next.';

  static adviceCustomerPendingOnly: string = 'Advise customer they will be unable to use the software until payment is received.';

  static adviseCustomerSteps: string = `<li>
    <p>An email will be sent</p>
</li>
<li>
    <p>Check spam or junk folder</p>
</li>`;

  static essentialsIneligibilityDueToMismatchedDirectors: string =
    'You have not met the eligibility criteria for Agrimaster Essentials as directors must be 100% in common with your primary subscription.';
  static liteIneligibilityDueToMismatchedDirectors: string =
    'You have not met the eligibility criteria for Agrimaster Lite as directors must be at least 50% in common with your primary subscription.';

  static essentialsEntityDetailsDirectorshipQuestion = 'Is Directorship of both companies is at least 50% the same?*';
  static liteEntityDetailsDirectorshipQuestion = 'Is Directorship of both companies is at least 50% the same?*';

  static essentialsFinalConfirmation =
    'I confirm this add on subscription has at least 50% Directors in common as per my primary subscription, and understand that processing fees apply for ineligible applications.';
  static liteFinalConfirmation =
    'I confirm this add on subscription has at least 50% Directors in common as per my primary subscription, and understand that processing fees apply for ineligible applications.';

  static essentialsDocumentationStepOne = 'To qualify for the Essentials Subscription, attach the following documents to confirm 50% Director ownership';
  static essentialsDocumentationStepTwo = 'Attach the following documents to confirm 50% Director ownership for your existing Standard or Plus Subscription';

  static liteDocumentationStepOne = 'To qualify for the Lite Subscription, attach the following documents to confirm 50% Director ownership';
  static liteDocumentationStepTwo = 'Attach the following documents to confirm 50% Director ownership for your existing Standard or Plus Subscription';

  static subscriptionCreationCardError = 'Creation of Subscription failed, your card has not been charged, please try again.';

  static visaOrMasterCardError = 'We accept Visa or Mastercard only. Please try again using an accepted card.';
}
