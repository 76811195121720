import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { faCircleNotch, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { ProRataDetails, ShopProduct } from 'src/shared/service-proxies/api-service-proxies';
import { PurchasingService } from 'src/shared/services/purchasing.service';
import { DiscountCodeComponent } from '../discount-code/discount-code.component';

@Component({
  selector: 'app-order-summary',
  templateUrl: './order-summary.component.html',
  styleUrls: ['./order-summary.component.scss']
})
export class OrderSummaryComponent implements OnInit, OnChanges {
  @Output() outputProrataDetailSuccess: EventEmitter<ProRataDetails> = new EventEmitter();
  @Input() primarySubscriptionId: number | undefined = undefined;
  @Input('productCode') productCode: string;
  @Input('userName') userName: string;
  @ViewChild(DiscountCodeComponent) discountCode: DiscountCodeComponent;

  public faCircleNotch = faCircleNotch;
  public faTrashAlt = faTrashAlt;
  private _hasProRata = false;
  private _proRataName: string;
  private _proRataValue: number;
  private _subtotalValue: number;
  private _taxValue: number;
  private _productName: string;
  private _productValue: number;
  private _discountCodeApplied: string;
  private _shopResponse: ShopProduct;

  private _totalValue: number;

  private _orderTotalsLoading: boolean;

  public get productName(): string {
    return this._productName;
  }
  public get productValue(): number {
    return this._productValue;
  }
  public get hasProRata(): boolean {
    return this._hasProRata;
  }
  public get proRataName(): string {
    return this._proRataName;
  }
  public get orderTotalsLoading() {
    return this._orderTotalsLoading;
  }
  public get proRataValue(): number {
    return this._proRataValue;
  }
  public get subtotalValue(): number {
    return this._subtotalValue;
  }
  public get taxValue(): number {
    return this._taxValue;
  }
  public get totalValue(): number {
    return this._totalValue;
  }

  constructor(private readonly _purchasingService: PurchasingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const inputPrimarySubscriptionId = changes['primarySubscriptionId'];

    if (inputPrimarySubscriptionId) {
      this.primarySubscriptionId = inputPrimarySubscriptionId.currentValue as number;

      if (this.userName && this.productCode && this._shopResponse) {
        this._orderTotalsLoading = true;
        this.getEstimate();
      }
    }
  }

  public onCodeAdded(code: string) {
    this._discountCodeApplied = code;
    this._orderTotalsLoading = true;
    this.getEstimate();
  }

  ngOnInit(): void {
    this.outputProrataDetailSuccess.emit(null);
    this._orderTotalsLoading = true;

    this._purchasingService.product(this.productCode).subscribe({
      next: (shopProductResponse: ShopProduct) => {
        this._shopResponse = shopProductResponse;
        this.getEstimate();
      },
      error: () => {
        this._productName = 'ERROR: Invalid Product code!';
      }
    });
  }

  private getEstimate() {
    const gstAmount = 11;
    this._purchasingService.proration(this.userName, this.productCode, this._discountCodeApplied, this.primarySubscriptionId).subscribe({
      next: (proRationResponse: ProRataDetails) => {
        if (proRationResponse && proRationResponse.name) {
          if (proRationResponse.couponApplied) {
            this.discountCode.getCoupon(proRationResponse.couponApplied);
          }

          this._productName = this._shopResponse.name;
          this._productValue = this._shopResponse.price;
          this._hasProRata = proRationResponse.isProRata;
          this._proRataName = proRationResponse.name;
          this._proRataValue = -proRationResponse.discountAmount;
          this._subtotalValue = proRationResponse.owingAmount;
          this._taxValue = proRationResponse.owingAmount / gstAmount;

          this._totalValue = proRationResponse.owingAmount;

          if (proRationResponse.couponApplied && !proRationResponse.couponApplied.isValid) {
            this.outputProrataDetailSuccess.emit(null);
            this._orderTotalsLoading = false;
            return;
          }
        } else {
          this._hasProRata = false;
        }
        this.outputProrataDetailSuccess.emit(proRationResponse);
        this._orderTotalsLoading = false;
      },
      error: () => {
        this.outputProrataDetailSuccess.emit(null);
        this._orderTotalsLoading = false;
      }
    });
  }
}
