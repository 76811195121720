<div class="container" *ngIf="this.primarySubscriptions !== null && this.primarySubscriptions.length > 0">
  <mat-radio-group class="radio-group" aria-labelledby="labelSelectPrimarySubscription" ngModel="selectedPrimarySubscriptionId" (ngModelChange)="modelChange($event)">
    <label id="labelSelectPrimarySubscription" class="label">Select the primary subscription the AGS7 will be associated with: </label>
    <br />

    <mat-radio-button class="radio-button" *ngFor="let primarySubscription of primarySubscriptions" [value]="primarySubscription.subscriptionId">
      <div *ngIf="this.primarySubscription.abn">{{ primarySubscription.entityName }} (ABN {{ primarySubscription.abn }}) - {{ primarySubscription.planType }}</div>
      <div *ngIf="this.primarySubscription.abn === null || this.primarySubscription.abn === undefined">
        {{ primarySubscription.entityName }} - {{ primarySubscription.planType }}
      </div>
    </mat-radio-button>
  </mat-radio-group>
</div>

<div class="container error-text" *ngIf="this.primarySubscriptions === null || this.primarySubscriptions.length === 0">No qualifying subscription found</div>
