import { Injectable } from '@angular/core';
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  homeParams:BehaviorSubject<{homeTitle:string}> = new BehaviorSubject({homeTitle:null});
  constructor() { }
}
