import { Component, Inject, OnInit } from "@angular/core";
import { NestedTreeControl } from "@angular/cdk/tree";
import { MatTreeNestedDataSource } from "@angular/material/tree";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faChevronRight, faChevronDown, faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Code, CodeGroup } from 'src/shared/service-proxies/api-service-proxies';

class CodeNode {
    name: string;
    id: number;
    code: Code;
    children?: CodeNode[];
}

@Component({
    selector: "app-code-selector",
    templateUrl: "./code-selector.component.html",
    styleUrls: ["./code-selector.component.scss"],
})
export class CodeSelectorComponent implements OnInit {
    public treeData: CodeNode[];
    public codeGroups: CodeGroup[];
    public treeControl = new NestedTreeControl<CodeNode>(node => node.children);
    public dataSource = new MatTreeNestedDataSource<CodeNode>();
    public selectedCode: Code;
    public faChevronRight = faChevronRight;
    public faChevronDown = faChevronDown;
    public faCheck = faCheck;

    constructor(
        public dialogRef: MatDialogRef<CodeSelectorComponent>,
        @Inject(MAT_DIALOG_DATA) public codes: CodeGroup[]
    ) {
        this.selectedCode = new Code();
        this.selectedCode.id = 0;
        this.treeData = [] as CodeNode[];
        for(let i = 0; i < codes.length; i++){
            let codeNode = new CodeNode();
            codeNode.name = codes[i].name;
            codeNode.id = codes[i].id;
            codeNode.children = [] as CodeNode[];
            for(let c = 0; c < codes[i].codes.length; c++){
                let code = new CodeNode();
                code.id = codes[i].codes[c].id;
                code.name = codes[i].codes[c].name;
                code.code = codes[i].codes[c];
                codeNode.children.push(code);
            }

            for(let g = 0; g < codes[i].groups.length; g++){
                let code = new CodeNode();
                code.id = codes[i].groups[g].id;
                code.name = codes[i].groups[g].name;
                code.children = [] as CodeNode[];

                for(let l = 0; l < codes[i].groups[g].codes.length; l++){
                    let last = new CodeNode();
                    last.id = codes[i].groups[g].codes[l].id;
                    last.name = codes[i].groups[g].codes[l].name;
                    last.code = codes[i].groups[g].codes[l];
                    code.children.push(last);
                }

                codeNode.children.push(code);
            }

            this.treeData.push(codeNode);
        }
        this.dataSource.data = this.treeData;
    }

    ngOnInit(): void {

    }

    hasChild = (_: number, node: CodeNode) => !!node.children && node.children.length > 0;

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onSave(): void {
        this.dialogRef.close(this.selectedCode);
    }

    public onSelect(code: Code): void{
        this.selectedCode = code;
    }
}
