import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfigService } from './app-config.service';
import { FileParameter, FileServiceProxy } from '../service-proxies/api-service-proxies';

@Injectable({
  providedIn: 'root'
})
export class DocumentService {
  private _homeUrl: string
  
  constructor(
    private _http: HttpClient,
    private _fileService: FileServiceProxy
  ) { }

  public uploadVerificationDocuments(files: File[], userName: string, abn: string): Observable<Object> {
    this._homeUrl = AppConfigService.settings.backend;
    const params = new HttpParams()
    .set("owner", userName)
    .set("documentType", "CompanyVerification")
    .set("path", abn);

    const formData = new FormData();
    for (let index = 0; index < files.length; index++) {
      formData.append(`file${index}`, files[index]);
    }

      return this._http.post(this._homeUrl + `/api/Document/upload`, formData, { params });
  }

  public deliverClientFile(userName:string, businessName: string, subscriptionId: number, file: File) :  Observable<void> {
    let fileParameter: FileParameter = { data: file, fileName: file.name };
    return this._fileService.deliverClientFile(userName, businessName,subscriptionId, fileParameter);
  }
}
