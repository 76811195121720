import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, LOCALE_ID } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from '../components/login/login.component';
import { AccountRecoveryComponent } from '../components/account-recovery/account-recovery.component';
import { RecoveryConfirmationComponent } from '../components/recovery-confirmation/recovery-confirmation.component';
import { AuthComponent } from '../components/auth/auth.component';
import { HomeComponent } from '../components/home/home.component';
import { ChangePasswordComponent } from '../components/change-password/change-password.component';
import { CountdownModule } from 'ngx-countdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMenuModule } from '@angular/material/menu';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatChipsModule } from '@angular/material/chips';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatTabsModule } from '@angular/material/tabs';
import { MatStepperModule } from '@angular/material/stepper';
import { MatSelectModule } from '@angular/material/select';
import { MatRadioModule } from '@angular/material/radio';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatListModule } from '@angular/material/list';
import { PasswordStrengthMeterModule } from 'angular-password-strength-meter';
import { ServiceWorkerModule } from '@angular/service-worker';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { AppConfigService } from '../shared/services/app-config.service';
import { DocumentService } from '../shared/services/document.service';
import { AuthInterceptor } from '../shared/helpers/auth.interceptor';
import { DigitOnlyDirective } from '../shared/helpers/digit-only.directive';
import { AuthService } from '../shared/services/auth.service';
import { ShopComponent } from '../components/shop/shop.component';
import { EssentialsIneligibleComponent } from '../components/essentials-ineligible/essentials-ineligible.component';
import { FlexLayoutModule } from '@angular/flex-layout';
import { StripeModule } from 'src/components/stripe';
import { PurchaseCompleteComponent } from '../components/purchase-complete/purchase-complete.component';
import { ProductsComponent } from '../components/products/products.component';
import { FileListComponent } from '../components/file-list/file-list.component';
import { BusinessDetailsComponent } from '../components/business-details/business-details.component';
import { AddNoteComponent } from '../components/add-note/add-note.component';
import { OpenReadOnlyComponent } from '../components/open-read-only/open-read-only.component';
import { ManageShareComponent } from '../components/manage-share/manage-share.component';
import { BusinessOverviewComponent } from '../components/business-overview/business-overview.component';
import { RestoreFileComponent } from '../components/restore-file/restore-file.component';
import { DeleteFileComponent } from '../components/delete-file/delete-file.component';
import { OpenRevisionReadOnlyComponent } from '../components/open-revision-read-only/open-revision-read-only.component';
import { DeleteLocalWagemasterComponent } from '../components/delete-local-wagemaster/delete-local-wagemaster.component';
import { ShareUnlicensedComponent } from '../components/share-unlicensed/share-unlicensed.component';
import { UpdateInstalledComponent } from '../components/update-installed/update-installed.component';
import { SubscriptionExpiredComponent } from '../components/subscription-expired/subscription-expired.component';
import { RefreshLicenseComponent } from '../components/refresh-license/refresh-license.component';
import { RefreshPageComponent } from '../components/refresh-page/refresh-page.component';
import { BankAccountListComponent } from '../components/bank-account-list/bank-account-list.component';
import { ConfigureBankFeedsComponent } from '../components/configure-bank-feeds/configure-bank-feeds.component';
import { HelpCentreLinkComponent } from '../components/help-centre-link/help-centre-link.component';
import { CodeSelectorComponent } from '../components/code-selector/code-selector.component';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { NgHcaptchaModule } from 'ng-hcaptcha';
import {
  BusinessLookupServiceProxy,
  BusinessServiceProxy,
  FileServiceProxy,
  NotificationServiceProxy,
  ProfileServiceProxy,
  PurchasingServiceProxy
} from '../shared/service-proxies/api-service-proxies';
import { environment } from 'src/environments/environment';
import { ClientService } from 'src/shared/services/client.service';
import { BusinessService } from 'src/shared/services/business.service';
import { AgrimasterProductComponent } from '../components/agrimaster-product/agrimaster-product.component';
import { CreateUserComponent } from '../components/create-user/create-user.component';
import { RegisterUserComponent } from '../components/register-user/register-user.component';
import { SupportingDocumentationComponent } from '../components/supporting-documentation/supporting-documentation.component';
import { OrderSummaryComponent } from '../components/order-summary/order-summary.component';
import { ErrorInterceptor } from 'src/shared/helpers/error.interceptor';
import { DiscountCodeComponent } from '../components/discount-code/discount-code.component';
import { SelectPrimarySubscriptionComponent } from '../components/select-primary-subscription/select-primary-subscription.component';
import { AssignAgs7UserComponent } from '../components/assign-ags7-user/assign-ags7-user.component';
import { SearchExistingUserComponent } from '../components/search-existing-user/search-existing-user.component';

export function initialiseApp(appConfigService: AppConfigService) {
  return (): Promise<any> => {
    return appConfigService.load();
  };
}

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AccountRecoveryComponent,
    RecoveryConfirmationComponent,
    AuthComponent,
    HomeComponent,
    ChangePasswordComponent,
    ShopComponent,
    EssentialsIneligibleComponent,
    PurchaseCompleteComponent,
    ProductsComponent,
    FileListComponent,
    BusinessDetailsComponent,
    AuthComponent,
    AccountRecoveryComponent,
    RecoveryConfirmationComponent,
    ChangePasswordComponent,
    AddNoteComponent,
    OpenReadOnlyComponent,
    ManageShareComponent,
    BusinessOverviewComponent,
    RestoreFileComponent,
    DeleteFileComponent,
    OpenRevisionReadOnlyComponent,
    DeleteLocalWagemasterComponent,
    ShareUnlicensedComponent,
    UpdateInstalledComponent,
    SubscriptionExpiredComponent,
    RefreshLicenseComponent,
    RefreshPageComponent,
    BankAccountListComponent,
    ConfigureBankFeedsComponent,
    HelpCentreLinkComponent,
    CodeSelectorComponent,
    AgrimasterProductComponent,
    CreateUserComponent,
    RegisterUserComponent,
    DigitOnlyDirective,
    SupportingDocumentationComponent,
    OrderSummaryComponent,
    DiscountCodeComponent,
    SelectPrimarySubscriptionComponent,
    AssignAgs7UserComponent,
    SearchExistingUserComponent
  ],
  imports: [
    BrowserModule,
    FlexLayoutModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    PasswordStrengthMeterModule,
    HttpClientModule,
    MatMenuModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatInputModule,
    MatButtonToggleModule,
    MatExpansionModule,
    MatTableModule,
    MatCardModule,
    MatGridListModule,
    MatChipsModule,
    MatFormFieldModule,
    MatSnackBarModule,
    MatDialogModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    MatTabsModule,
    MatStepperModule,
    MatSelectModule,
    MatRadioModule,
    MatProgressBarModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatListModule,
    MatTreeModule,
    CountdownModule,
    NgHcaptchaModule.forRoot(),
    StripeModule.init({
      options: { locale: 'en' },
      elementsOptions: {
        fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400,700' }]
      },
      elementOptions: {
        style: {
          base: {
            fontFamily: 'Roboto, sans-serif'
          }
        }
      }
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production
    }),
    MatMomentDateModule
  ],
  providers: [
    DatePipe,

    AppConfigService,
    AuthService,
    ProfileServiceProxy,
    NotificationServiceProxy,
    FileServiceProxy,
    BusinessLookupServiceProxy,
    BusinessServiceProxy,
    PurchasingServiceProxy,
    DocumentService,
    ClientService,
    BusinessService,
    { provide: APP_INITIALIZER, useFactory: initialiseApp, multi: true, deps: [AppConfigService] },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: navigator.language || 'en_AU' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
