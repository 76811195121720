import { Component } from '@angular/core';
import { User } from 'src/shared/models/user';

@Component({
  selector: 'app-register-user',
  templateUrl: './register-user.component.html',
  styleUrls: ['./register-user.component.scss']
})
export class RegisterUserComponent {

  public onUserCreateSuccess(user :User){
    console.log(user.id + ' ' + user.userName);
  }
}
