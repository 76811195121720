import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AuthService } from 'src/shared/services/auth.service';

@Component({
  selector: 'app-refresh-page',
  templateUrl: './refresh-page.component.html',
  styleUrls: ['./refresh-page.component.scss']
})
export class RefreshPageComponent implements OnInit {

  constructor( 
    private _authService: AuthService,
    public dialogRef: MatDialogRef<RefreshPageComponent>) { }

  ngOnInit(): void {

  }

  public onClick() {
    const message:string = `User Number [${this._authService.currentUserValue.userName}] has lost global signalr, and need to refresh the page`;
    
    location.reload();
        this.dialogRef.close();
    // this._homeServiceProxy.logMessage(message,LogLevel.Warning).subscribe(
    //   ()=>{
    //     location.reload();
    //     this.dialogRef.close();
    //   }
    // );

  }
}
