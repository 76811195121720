import * as signalR from "@microsoft/signalr";
import { Injectable, OnDestroy } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import {
    SignalREvents,
    SignalRListener,
    SignalRConsts,
    SignalRMethod,
} from "../models/constants";

import { User } from "../models/user";
import { MessageParameters } from "../models/message-parameters";
import { AuthService } from "./auth.service";
import { take } from "rxjs/operators";
import { HomeService } from "./home.service";
import { BackupType, IBackupFileDetails } from '../service-proxies/api-service-proxies';
import { AppConfigService } from './app-config.service';

@Injectable({
    providedIn: "root",
})
export class SignalRService implements OnDestroy {
    private globalConnection: signalR.HubConnection;
    private user: User;
    private isGlobalConnected: boolean = false;
    private globalListeners: Array<string> = [];
    public disconnectGlobalSignalRDetectedSubject = new BehaviorSubject<
        boolean
    >(false);
    public connectedGlobalSubject = new BehaviorSubject<boolean>(false);
    public reconnectHappened = new BehaviorSubject<boolean>(false);
    public retryGlobalMultiplier: any = 0;
    private disconnectIntended: boolean = false;
    private globalListenersAdded: boolean = false;
    private connectionInProgress: boolean = false;

    constructor(
        private _authService: AuthService
    ) {
        this._authService.currentUser$.subscribe((user) => {
            if (!this.connectionInProgress) {
                if (user) {
                    this.connect(user);
                    this.connectedGlobalSubject
                        .pipe(take(1))
                        .subscribe((connected) => {
                            if (connected) {
                                this.login(user.userName);
                            }
                        });
                } else {
                    this.disconnect();
                }
            }
        });
    }

    private connect(user: User) {
        this.connectionInProgress = true;
        this.user = user;
        this.disconnectIntended = false;
        if (user.token) {
            if (!this.globalConnection) {
                this.globalConnection = new signalR.HubConnectionBuilder()
                    .withUrl(`${AppConfigService.settings.signalr}/hubs/notification`, {
                        accessTokenFactory: () => user.token,
                    })
                    .configureLogging(signalR.LogLevel.Information)
                    .withAutomaticReconnect()
                    .build();
                this.globalConnection.onreconnected(this.onGlobalConnected);
                this.globalConnection.onclose(this.onGlobalDisconnected);
            }
           
            this.start();
     
            if (!this.globalListenersAdded) {
                this.registerGlobalListeners();
            }
        }
        this.connectionInProgress = false;
    }

    private onGlobalConnected = () => {
        this.isGlobalConnected = true;
        if (this.user) {
            this.globalConnection.invoke("connect", this.user.userName);
        }
        this.connectedGlobalSubject.next(true);
        this.disconnectGlobalSignalRDetectedSubject.next(false);
        this.reconnectHappened.next(true);
        console.warn(`Global SignalR connected`);
    };

    private onGlobalDisconnected = () => {
        this.isGlobalConnected = false;
        console.warn(`Global SignalR connection lost`);
        if (this._authService.loggedIn) {
            this.disconnectGlobalSignalRDetectedSubject.next(true);
        }

        this.connectedGlobalSubject.next(false);
    };

    private start(): void {
        this.startGlobal();
    }

    public login(userNumber: string) {
        if (
            this.globalConnection &&
            this.globalConnection.state === "Connected"
        ) {
            this.globalConnection.invoke(SignalRConsts.login, userNumber);
        } else {
            setTimeout(() => this.login(userNumber), 2000);
        }
    }

   

    public startFileCheck(userName: string) {
        //this.localConnection.invoke("startFileCheck", userName);
        
    }

    public startGlobal() {
        if (this.globalConnection && !this.isGlobalConnected) {
            this.globalConnection
                .start()
                .catch((err) => {
                    console.log(
                        "Global SignalR throwing error when try to start the connection.",
                        err
                    );

                    this._authService.logOut();
                })
                .then(() => {
                    this.isGlobalConnected = true;
                    if (this.user) {
                        this.globalConnection.invoke(
                            "connect",
                            this.user.userName
                        );
                    }
                    this.disconnectGlobalSignalRDetectedSubject.next(false);
                    this.connectedGlobalSubject.next(true);
                    this.reconnectHappened.next(true);
                    console.warn(`Global SignalR connected`);
                });
        }
    }

    private registerGlobalListeners() {
        this.registerGlobalListener(
            SignalRConsts.onLoggedIn,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onConnected,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onFileOpened,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onFileClosed,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onFileUploadComplete,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onFileUploadSkipped,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onUserShareRevoked,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onUserAddedToShare,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onUserShareModified,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onFileRegisteredForNotifications,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onRevisionNoteAdded,
            this.defaultCallBack
        );
        this.registerGlobalListener(
            SignalRConsts.onUserRejectedShare,
            this.defaultCallBack
        );

        this.globalListenersAdded = true;
    }
  
    public registerGlobalListener(
        event: SignalREvents | Array<SignalREvents>,
        listener: SignalRListener
    ) {
        if (this.globalConnection) {
            if (event instanceof Array) {
                event.forEach((e) => {
                    this.globalConnection.on(e, listener);
                    this.globalListeners.push(e);
                });
            } else {
                this.globalConnection.on(event, listener);
                this.globalListeners.push(event);
            }
        }
    }
   
    private defaultCallBack(data) {
        console.info(data);
    }

    public disconnect(): any {
        this.disconnectIntended = true;
        this.isGlobalConnected = false;
        this.connectedGlobalSubject.next(false);
        this.globalListenersAdded = false;
        this.connectionInProgress = false;
        this.globalListeners.forEach((listener) =>
            this.globalConnection.off(listener)
        );
               
        if (this.globalConnection) {
            this.globalConnection.stop();
        }
    }

    public registerFileForNotifications(
        userNumber: string,
        file: IBackupFileDetails
    ) {
        if (this.isGlobalConnected) {
            this.globalConnection.invoke(
                SignalRConsts.registerFileForNotifications,
                userNumber,
                {
                    fileName: file.summary.name,
                    fileOwnerNumber: file.summary.userOwnedBy.userName,
                    isShared: file.sharedWith && file.sharedWith.length > 0,
                    fileType: file.summary.fileType,
                    businessId: file.summary.businessId  
                }
            );
        }
    }

    public openFile(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        userFullName: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId?:number
    ) {
        this.invokeMethod(SignalRConsts.openFile, {
            fileName,
            ownerNumber,
            userNumber,
            userFullName,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public closeReadOnlyFile(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        userFullName: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId?: number
    ): any {
        this.invokeMethod(SignalRConsts.closeReadOnlyFile, {
            fileName,
            ownerNumber,
            userNumber,
            userFullName,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public closeFile(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        userFullName: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId? :number
    ) {
        this.invokeMethod(SignalRConsts.closeFile, {
            fileName,
            ownerNumber,
            userNumber,
            userFullName,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public fileUploadComplete(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        userFullName: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId?: number
    ) {
        this.invokeMethod(SignalRConsts.fileUploadComplete, {
            fileName,
            ownerNumber,
            userNumber,
            userFullName,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public fileUploadSkipped(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        userFullName: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId?: number
    ) {
        this.invokeMethod(SignalRConsts.fileUploadSkipped, {
            fileName,
            ownerNumber,
            userNumber,
            userFullName,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public addSharedUser(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId?: number
    ) {
        this.invokeMethod(SignalRConsts.addUserToShare, {
            fileName,
            ownerNumber,
            userNumber,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public modifySharedUserPermissions(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId?: number
    ): any {
        this.invokeMethod(SignalRConsts.modifyUserShare, {
            fileName,
            ownerNumber,
            userNumber,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public revokeSharedUser(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?:number,
        clientServerId?:number
    ) {
        this.invokeMethod(SignalRConsts.revokeUserShare, {
            fileName,
            ownerNumber,
            userNumber,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public rejectShare(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?: number,
        clientServerId?: number
    ) {
        this.invokeMethod(SignalRConsts.userRejectedShare, {
            fileName,
            ownerNumber,
            userNumber,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    public addRevisionNote(
        fileName: string,
        ownerNumber: string,
        userNumber: string,
        userFullName: string,
        fileType: BackupType,
        computerName: string,
        computerLogin: string,
        businessId?:number,
        clientServerId?:number
    ) {
        this.invokeMethod(SignalRConsts.addRevisionNote, {
            fileName,
            ownerNumber,
            userNumber,
            userFullName,
            fileType,
            computerName,
            computerLogin,
            businessId,
            clientServerId
        });
    }

    private invokeMethod(method: SignalRMethod, arg: MessageParameters) {
        if (
            this.globalConnection &&
            this.globalConnection.state === "Connected"
        ) {
            this.globalConnection
                .invoke(method, arg)
                .catch((e) => console.log("Something went wrong: ", e));
        } else {
            setTimeout(() => this.invokeMethod(method, arg), 2000);
        }
    }

    ngOnDestroy(): void {
        this.globalListeners.forEach((listener) =>
            this.globalConnection.off(listener)
        );
    }
}
