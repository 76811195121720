export enum FileStatus {
    Locked = "Locked",
    Downloading = "Downloading",
    Uploading = "Uploading",
    WaitingToUpload = "WaitingToUpload",
    BackedUp = "BackedUp",
    NotBackedUp = "NotBackedUp",
    Offline = "Offline",
    FileInUse = "FileInUse",
    OutOfDate = "OutOfDate",
    Processing = "Processing",
    Verifying = "Verifying",
    SyncError = "SyncError",
    Conflict = "Conflict",
    ProcessingError = "ProcessingError",
}