import { Component, Input, OnInit } from '@angular/core';
import { faInfoCircle } from "@fortawesome/pro-regular-svg-icons";
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HelpCentreLinkServiceProxy } from 'src/shared/service-proxies/api-service-proxies';

@Component({
  selector: 'help-centre-link',
  templateUrl: './help-centre-link.component.html',
  styleUrls: ['./help-centre-link.component.scss']
})
export class HelpCentreLinkComponent implements OnInit {

  @Input() public area: string;
  @Input() public name: string;
  public faInfoCircle = faInfoCircle;

  public helpCentreAddress: string = "";
  private defaultLink: string = "https://support.agrimaster.com.au/hc/en-us";
  constructor(private _helpLinkProxy: HelpCentreLinkServiceProxy) { }

  ngOnInit(): void {
    this._helpLinkProxy
      .helpCentreLink(this.area, this.name)
      .pipe(
        catchError((err) => {
            this.helpCentreAddress = this.defaultLink;
            return throwError(err);
        })
    )
      .subscribe((data) => {
        this.helpCentreAddress = data.url;
      });
            
  }

}
