<mat-card>
    <mat-card-title><fa-icon [icon]="faExclamationTriangle" class="file-status warning"></fa-icon><span class="header-text">Unlicenced - {{file.backupDetails.summary.name}}</span></mat-card-title>
    <mat-card-content>
        <div>
            <div class="container">
                <p>This shared file does not have a licence attached to it.</p>
                <p>Only the file owner ({{file.backupDetails.summary.userOwnedBy.userName}}) using Agrimaster 6.5 or higher can assign a licence.</p>
                <p>Alternatively you can reject the file share.</p>
                <mat-checkbox [color]="'warn'" [(ngModel)]="accepted">I understand and wish to reject the share</mat-checkbox>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Close</button>
        <button mat-raised-button color="warn" [disabled]="!accepted" aria-label="Submit" (click)="rejectShare()">
            Reject
        </button>
    </mat-card-actions>
</mat-card>