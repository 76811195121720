import { Component, OnInit } from '@angular/core';
import { PurchasingServiceProxy, ShopProduct } from 'src/shared/service-proxies/api-service-proxies';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  constructor(private _purchasingService: PurchasingServiceProxy) {}

  public products: ShopProduct[] = [];

  ngOnInit(): void {
    this._purchasingService.productAll().subscribe((response) => {
      this.products = response;
    });
  }
}
