import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { faShareAlt } from "@fortawesome/pro-regular-svg-icons";
import { ShareDetails } from "src/shared/models/share-details";
import { catchError } from "rxjs/operators";
import { throwError } from "rxjs";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { BackupFile, BackupFileDetails, FileServiceProxy, SharingPermission, SharingPermissions } from 'src/shared/service-proxies/api-service-proxies';
import { AuthService } from 'src/shared/services/auth.service';

@Component({
    selector: "app-manage-share",
    templateUrl: "./manage-share.component.html",
    styleUrls: ["./manage-share.component.scss"],
})
export class ManageShareComponent implements OnInit {
    public faShareAlt = faShareAlt;
    public share: SharingPermission;
    public existingShares: SharingPermission[];
    public isNewShare: boolean;
    public permissions: string[] = [];
    public selectedPermission: string;
    public originalPermission: string;
    public shareUserNumber: string;
    public backupFile: BackupFile;
    public shareId: number;
    private configSnackBar: MatSnackBarConfig = {
        panelClass: ["style-bar"],
        duration: 3000,
    };

    constructor(
        public dialogRef: MatDialogRef<ManageShareComponent>,
        private _authService: AuthService,
        private _revisionServiceProxy: FileServiceProxy,
        private _snackBar: MatSnackBar,
        @Inject(MAT_DIALOG_DATA) public sharingDetails: ShareDetails
    ) {
        this.backupFile = sharingDetails.file;
        this.existingShares = sharingDetails.existingShares;
        if (sharingDetails.share) {
            this.isNewShare = false;
            this.selectedPermission = this.getSharingPermissionString(
                sharingDetails.share.permissions
            );
            this.originalPermission = this.getSharingPermissionString(
                sharingDetails.share.permissions
            );
            this.shareUserNumber = sharingDetails.share.userSharedWith;
            this.permissions.push("None");
            this.shareId = sharingDetails.share.id;
        } else {
            this.isNewShare = true;
            this.shareUserNumber = "";
            this.selectedPermission = "";
        }
        this.permissions.push(
            this.getSharingPermissionString(SharingPermissions.Read)
        );
        if (this.backupFile.subscription.planType !== "Agrimaster Read Only") {
            this.permissions.push(
                this.getSharingPermissionString(SharingPermissions.ReadWrite)
            );
        }
    }

    public onNoClick(): void {
        this.dialogRef.close();
    }

    public onAddClick(): void {
        for (let i = 0; i < this.existingShares.length; i++) {
            if (
                this.shareUserNumber === this.existingShares[i].userSharedWith
            ) {
                this._snackBar.open(
                    "Cannot add a new share for a user number that already has an existing one",
                    null,
                    this.configSnackBar
                );
                return;
            }
        }

        let share = this.getSharingPermissionFromString(
            this.selectedPermission
        );

        this._revisionServiceProxy
            .share(
                this.backupFile.name,
                this.shareUserNumber,
                share,
                this.backupFile.fileType
            )
            .pipe(
                catchError((err) => {
                    this._snackBar.open(
                        "Could not add share",
                        null,
                        this.configSnackBar
                    );
                    return throwError(err);
                })
            )
            .subscribe((result) => {
                let results = result as BackupFileDetails;
                this.dialogRef.close(result.sharedWith);
            });
    }

    public onUpdateClick(): void {
        let share = this.getSharingPermissionFromString(
            this.selectedPermission
        );

        if (share) {
            this._revisionServiceProxy
                .share2(
                    this.shareId,
                    share,
                )
                .pipe(
                    catchError((err) => {
                        this._snackBar.open(
                            "Could not add share",
                            null,
                            this.configSnackBar
                        );
                        return throwError(err);
                    })
                )
                .subscribe((result) => {
                    let results = result as BackupFileDetails;
                    this.dialogRef.close(result.sharedWith);
                });
        } else {
            this._revisionServiceProxy
                .share3(
                    this.shareId
                )
                .pipe(
                    catchError((err) => {
                        this._snackBar.open(
                            "Could not revok access",
                            null,
                            this.configSnackBar
                        );
                        return throwError(err);
                    })
                )
                .subscribe((result) => {
                    let results = result as BackupFileDetails;
                    this.dialogRef.close(result.sharedWith);
                });
        }
    }

    public getSharingPermissionString(share?: SharingPermissions): string {
        if (share) {
            if (share == SharingPermissions.Read) {
                return "Read Only";
            }

            if (share == SharingPermissions.ReadWrite) {
                return "Read And Write";
            }
        }
        return "None";
    }

    public getSharingPermissionFromString(
        share?: string
    ): SharingPermissions | null {
        if (share) {
            if (share === "Read Only") {
                return SharingPermissions.Read;
            }

            if (share === "Read And Write") {
                return SharingPermissions.ReadWrite;
            }
        }
        return null;
    }

    public isAddDisabled(): boolean {
        if (!this.shareUserNumber || !this.selectedPermission) {
            return true;
        }
        return false;
    }

    public isUpdateDisabled(): boolean {
        if (this.originalPermission === this.selectedPermission) {
            return true;
        }
        return false;
    }

    ngOnInit() {}
}
