import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { SubscriptionInfo } from 'src/shared/service-proxies/api-service-proxies';

@Component({
  selector: 'app-select-primary-subscription',
  templateUrl: './select-primary-subscription.component.html',
  styleUrls: ['./select-primary-subscription.component.scss']
})
export class SelectPrimarySubscriptionComponent implements OnChanges {
  @Input() primarySubscriptions: SubscriptionInfo[] = [];
  selectedPrimarySubscriptionId: number = 0;
  @Output() outputSelectedPrimarySubscriptionId: EventEmitter<number> = new EventEmitter();

  ngOnChanges(changes: SimpleChanges): void {
    const inputPrimarySubscriptions = changes['primarySubscriptions'];

    if (inputPrimarySubscriptions) {
      this.primarySubscriptions = inputPrimarySubscriptions.currentValue as SubscriptionInfo[];
    }
  }

  modelChange(newPrimarySubscriptionId: number) {
    if (newPrimarySubscriptionId > 0) {
      this.outputSelectedPrimarySubscriptionId.emit(newPrimarySubscriptionId);
    }
  }
}
