import { Observable, BehaviorSubject } from "rxjs";
import { map } from "rxjs/operators";
import { FileStatus } from './file-status';
import { StateAwareFile } from "./state-aware-file";
/**
 * Allow detailed information for a specific file to be retrieved.
 */
export interface IFileInfoLookup {
    readonly files$: Observable<StateAwareFile[]>;
    getFile(file: string | StateAwareFile, fileType?: string): StateAwareFile;
    setFile(details: StateAwareFile);
    getFiles(): StateAwareFile[];
    haveFetchedFiles(): Observable<boolean>;
    setFetchStatus(connected: boolean);
}

export class FileInfoLookup implements IFileInfoLookup {
    private _lookup: IDetailsDictionary = {};
    private _alternateKeys: IAlternateDictionary = {};
    private _files$ = new BehaviorSubject<StateAwareFile[]>([]);
    private _haveFetchedFiles$ = new BehaviorSubject<boolean>(false);

    public haveFetchedFiles(): Observable<boolean> {
        return this._haveFetchedFiles$;
    }

    public setFetchStatus(connected: boolean) {
        this._haveFetchedFiles$.next(connected);
    }

    public get files$(): Observable<StateAwareFile[]> {
        // Pipe the behaviour subject through a map function to force it to read only.
        return this._files$.pipe(map((f) => f));
    }

    public hasValues(): boolean {
        var length = Object.keys(this._lookup).length;
        return length > 0;
    }

    /**
     * Retrieves all files from the lookup.
     */
    public getFiles(): StateAwareFile[] {
        const files = Object.values(this._lookup);
        return files;
    }

    /**
     * Retrieves a specific file from the lookup, or undefined if it isn't present.
     * @param file The object that contains the file name and owner, or a file name (without extension).
     * @param owner If the first parameter is a string, this should specify the owner. Otherwise, leave this as undefined.
     * @param fileType If the first parameter is a string, this should specify the type of file. Otherwise, leave this as undefined.
     */
    public getFile(business: string | StateAwareFile, fileType?: string) {
        let businessId;
        if (typeof business === "string") {
            businessId = business;
        } else {
            businessId = business.backupDetails.summary.businessId;
            fileType = business.backupDetails.summary.fileType;
        }

        return this._lookup[this.getKey(businessId, fileType)];
    }

    public getFileWithAlternateKey(key: string) {
        var originalKey = this._alternateKeys[key];
        return this._lookup[originalKey];
    }

    /**
     * Adds or replaces a file in the lookup.
     * @param details The file to insert into the lookup.
     */
    public setFile(details: StateAwareFile, skipStatus: Boolean = false) {
        let key: string;
        if (details.backupDetails.summary.businessId > 0) {
            key = this.getKey(
                details.backupDetails.summary.businessId,
                details.backupDetails.summary.fileType
            );
        } else {
            key = this.getOfflineKey(
                details.backupDetails.summary.name,
                details.backupDetails.summary.fileType
            );
        }
        const alternateKey = this.getAlternateKey(
            details.backupDetails.summary.name,
            details.backupDetails.summary.userOwnedBy.userName,
            details.backupDetails.summary.fileType
        );
        const existingFile = this._lookup[key];
        this._alternateKeys[alternateKey] = key;

        if (!existingFile) {
            this._lookup[key] = details;
            this._files$.next(this.getFiles());
        } else {
            this._lookup[key].backupDetails.revisions =
                details.backupDetails.revisions;
            this._lookup[key].setFileHistory(details.backupDetails.revisions);

            this._lookup[key].backupDetails.sharedWith =
                details.backupDetails.sharedWith;
            this._lookup[key].setSharingList(details.backupDetails.sharedWith);

            this._lookup[key].backupDetails.summary =
                details.backupDetails.summary;
            this._lookup[key].setSummary(details.backupDetails.summary);

            this._lookup[key].sharedStatus = details.sharedStatus;

            if (details.conflictBackupDetails) {
                this._lookup[key].conflictBackupDetails.summary =
                    details.conflictBackupDetails.summary;
                this._lookup[key].setConflict(
                    details.conflictBackupDetails.summary
                );
            }

            if (details.fileStatus !== FileStatus.Verifying) {
                this._lookup[key].fileStatus = details.fileStatus;
                this._lookup[key].setFileStatus(details.fileStatus);
            }

            this._files$.next(this.getFiles());
        }
    }

    /**
     * Removes a file from the lookup.
     * @param file The file to remove.
     */
    public removeFile(file: StateAwareFile) {
        if (
            file &&
            file.backupDetails.summary.name &&
            file.backupDetails.summary.userOwnedBy &&
            file.backupDetails.summary.userOwnedBy.userName
        ) {
            const alternateKey = this.getAlternateKey(
                file.backupDetails.summary.name,
                file.backupDetails.summary.userOwnedBy.userName,
                file.backupDetails.summary.fileType
            );

            var originalKey = this._alternateKeys[alternateKey];

            const existingFile = this._lookup[originalKey];
            delete this._alternateKeys[alternateKey];
            delete this._lookup[originalKey];
            if (existingFile) {
                this._files$.next(this.getFiles());
            }
        }
    }

    public removeAll() {
        this._lookup = {};
        this._alternateKeys = {};
        this._files$.next([]);
    }

    private getKey(businessId: number, fileType: string) {
        return `${businessId}::${fileType}`.toLowerCase();
    }

    private getOfflineKey(name: string, fileType: string) {
        return `${name}::${fileType}`.toLowerCase();
    }

    private getAlternateKey(fileName: string, owner: string, fileType: string) {
        return `${fileName}::${owner}::${fileType}`.toLowerCase();
    }
}

interface IDetailsDictionary {
    [key: string]: StateAwareFile;
}

interface IAlternateDictionary {
    [key: string]: string;
}
