<mat-card>
    <mat-card-title><fa-icon [icon]="faHistory" class="file-status"></fa-icon><span class="header-text">Open Revision as Read Only</span></mat-card-title>
    <mat-card-content>
        <div>
            <div class="container">
                <p *ngIf="!revisionGuid">Preparing your file</p>
                <p *ngIf="revisionGuid">File is now ready to use</p>
            </div>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" [disabled]="!revisionGuid" aria-label="Submit" (click)="onOpen()">
            Open - Read Only
        </button>
    </mat-card-actions>
</mat-card>