import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(private _router: Router) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => { 

            if (err.status === 401) {

                console.warn(`Received 401 so credentials must be invalid, returning to login`);
                var isReloadNeeded = false;
                if(this._router.url.includes('login') === false)
                {
                    isReloadNeeded = true;
                }
                // auto logout if 401 response returned from api
                this._router.navigateByUrl('/auth/login');
                if(isReloadNeeded)
                {
                    location.reload();
                }
                
            }
            return throwError(err);
        }))
    }
}