import { Component, OnInit, Renderer2, Inject } from '@angular/core';
import { faQuestionCircle, faBell, faUserCircle, faTimes, faBullhorn, faLightbulb, faGift, faSignalSlash} from '@fortawesome/pro-regular-svg-icons';
import { HomeService } from 'src/shared/services/home.service';
import { Router } from '@angular/router';
import { AuthService } from 'src/shared/services/auth.service';
import { from } from 'rxjs';
import * as configcat from "configcat-js";
import { AppConfigService } from 'src/shared/services/app-config.service';
import { DOCUMENT } from '@angular/common';
import { NotificationModel, NotificationServiceProxy } from 'src/shared/service-proxies/api-service-proxies';
import { take } from 'rxjs/operators';
import { SwUpdate } from "@angular/service-worker";

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  public faQuestionCircle = faQuestionCircle;
  public faBell = faBell;
  public faUserCircle = faUserCircle;
  public faTimes = faTimes;
  public faBullhorn = faBullhorn;
  public faLightbulb = faLightbulb;
  public faGift = faGift;
  public faSignalSlash = faSignalSlash;
  public title: string;
  public userDisplayName: string;
  public userVoiceFeedbackWidgetEnabled: boolean = false;

  public notificationUnreadCount: number;
  public isUpdateAvailable: boolean;
  public notificationList: NotificationModel[];
  public over: string = "over";

  widget?: FrillWidget;
  config?: FrillConfig;

  constructor(public homeService: HomeService,
    private _router: Router,
    private _authService: AuthService,
    private _notificationServiceProxy: NotificationServiceProxy,
    private _renderer2: Renderer2,
    private _serviceWorker: SwUpdate,
    @Inject(DOCUMENT) private _document: Document) {
    this.homeService.homeParams.subscribe(data => {
      this.title = data.homeTitle;
    })

    this.notificationUnreadCount = 0;
    this.isUpdateAvailable = false;
    this.notificationList = [] as NotificationModel[];

    this.userDisplayName = this._authService.currentUserValue.userProfile.firstName + ' ' + this._authService.currentUserValue.userProfile.lastName;

    if (this._authService.toggleClient) {
      from(
          this._authService.toggleClient.getValueAsync(
              "userVoiceFeedbackWidget",
              false,
              this._authService.currentUserValue.configUser
          )
      ).subscribe((result) => {
          this.userVoiceFeedbackWidgetEnabled = result;          
      });
    } else {
          this._authService.toggleClient = configcat.createClientWithAutoPoll(
            AppConfigService.settings.configCat,
            {
                pollIntervalSeconds: AppConfigService.settings.configInterval,
            }
          );

          from(
              this._authService.toggleClient.getValueAsync(
                  "userVoiceFeedbackWidget",
                  false,
                  this._authService.currentUserValue.configUser
              )
          ).subscribe((result) => {
              this.userVoiceFeedbackWidgetEnabled = result;
          });
    }

    this._notificationServiceProxy
    .list2(
        this._authService.currentUserValue.userName
    )
    .pipe(take(1))
    .subscribe((notifications) => {
        for (const notification of notifications) {
            if (!notification.isRead) {
                this.notificationUnreadCount++;
            }
        }
        this.notificationList = notifications as NotificationModel[];
    });

    this._serviceWorker.available.subscribe((event) => {
        this.isUpdateAvailable = true;
    });
  }

  public returnToLogin()
  {
    this._authService.logOut();
    this._router.navigateByUrl('/auth/login');
  }

  public goToPasswordChange()
  {
    this._router.navigateByUrl('/home/password/change');
  }

  goToHome() {
    this._router.navigateByUrl("/home");
  }

  public goToFiles() {
    this._router.navigateByUrl("/home/files");
  }

  public setAsRead(notification: NotificationModel) {
    if (!notification.isRead) {
        this._notificationServiceProxy
            .read(
              notification.id,
              this._authService.currentUserValue.userName
            )
            .subscribe(() => {
                this.notificationUnreadCount--;
                notification.isRead = true;
                for (const existing of this.notificationList) {
                    if (existing.id == notification.id) {
                        existing.isRead = true;
                    }
                }
            });
    }
}


  ngOnInit() {
    //this.setupZendeskWidget();
    this.notificationList = [] as NotificationModel[];
  }

  ngAfterContentInit() {
    const profile = this._authService.currentUserValue.userProfile;
    this.config = {
        key: '3f391433-af21-42ec-a989-15607130eaa4',
        ssoToken: profile.voiceToken,
        callbacks: {
        onReady: (frillWidget) => {
          this.widget = frillWidget;
        }
      }
    };

    window.Frill_Config = window.Frill_Config || [];
    window.Frill_Config.push(this.config);

    if ('Frill' in window) {
      this.widget = window.Frill.widget(this.config);
    }
  }

  ngOnDestroy() {
    this.widget?.destroy();
    window.Frill_Config = window.Frill_Config.filter((c) => c !== this.config);
  }

setupZendeskWidget() {


    const profile = this._authService.currentUserValue;
    let script = this._renderer2.createElement('script');

    script.text = `


        zE('webWidget', 'prefill', {
        name: {
            value: '${profile.userProfile.firstName} ${profile.userProfile.lastName}',
            readOnly: true // optional
        },
        email: {
            value: '${profile.userProfile.emailAddress}',
            readOnly: true // optional
        },
        phone: {
            value: '${profile.userProfile.mobile}',
            readOnly: true // optional
        }
        });
    `;

    this._renderer2.appendChild(this._document.body, script);
}

}
