<mat-card>
    <mat-card-title>Password Sent</mat-card-title>
    <mat-card-content>
        <div class="card-box">
            <fa-icon [icon]="faCheckCircle" class="icon-circle"></fa-icon>
            <span>Email has been sent</span>
            <span>Please check your email and try to login again</span>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button id="goToLogin" mat-raised-button color="primary" (click)="this.returnToLogin()">Go To Login</button>
    </mat-card-actions>
</mat-card>