<div [ngClass]="{ 'form-box-left': isStaff, 'form-box-right': !isStaff }">
  <mat-form-field appearance="fill" [ngClass]="{ 'text-box': !isStaff }">
    <mat-label>Email</mat-label>
    <input
      title="email"
      type="email"
      id="email"
      name="email"
      #email="ngModel"
      [disabled]="emailDisabled"
      matInput
      [(ngModel)]="userEmail"
      email="true"
      autocomplete="email"
      required
    />
  </mat-form-field>
  <span *ngIf="validationError && userEmailTested === userEmail" class="error-display" [ngClass]="{ 'error-align': !isStaff }">{{ validationError }}</span>
  <span *ngIf="emailDbValidated && !emailVerified && userEmailTested === userEmail && !isStaff" [ngClass]="{ 'error-align': !isStaff }"
    >A code has been sent to the above email, please enter it below</span
  >
  <countdown
    #cd
    [ngClass]="{ 'error-align': !isStaff, 'countdown-timer-hide': !(emailDbValidated && !emailVerified && userEmailTested === userEmail && !isStaff) }"
    [config]="config"
    (event)="handleEvent($event)"
  ></countdown>
  <mat-form-field appearance="fill" [ngClass]="{ 'text-box': !isStaff }" *ngIf="emailDbValidated && !emailVerified && userEmailTested === userEmail && !isStaff">
    <mat-label>Code</mat-label>
    <input title="code" id="code" matInput digitOnly maxlength="6" value="" [(ngModel)]="emailCode" name="code" autocomplete="Code" />
  </mat-form-field>
  <button
    *ngIf="(!email.invalid && !emailDbValidated) || (!email.invalid && userEmailTested !== userEmail)"
    [disabled]="validateIsDisabled"
    mat-raised-button
    id="validate"
    color="primary"
    class="button-action button-margin"
    (click)="validateEmail()"
  >
    Validate
  </button>
  <button
    *ngIf="emailDbValidated && !emailVerified && userEmailTested === userEmail && !isStaff"
    mat-raised-button
    id="verify"
    color="primary"
    class="button-action button-margin"
    (click)="verifyCode()"
  >
    Verify
  </button>
  <button
    *ngIf="emailDbValidated && !emailVerified && userEmailTested === userEmail && !isStaff"
    mat-stroked-button
    id="resend"
    class="button-action button-margin"
    (click)="resendCode(); cd.restart()"
  >
    Resend
  </button>
  <span *ngIf="emailDbValidated && (emailVerified || isStaff) && userEmailTested === userEmail" class="success-display" [ngClass]="{ 'error-align': !isStaff }"
    >Email verified successfully</span
  >
  <mat-form-field appearance="fill" [ngClass]="{ 'text-box': !isStaff }" *ngIf="emailDbValidated && (emailVerified || isStaff) && userEmailTested === userEmail">
    <mat-label>First Name</mat-label>
    <input id="firstName" title="first Name" matInput value="" [(ngModel)]="firstName" name="firstName" autocomplete="firstName" />
  </mat-form-field>
  <mat-form-field appearance="fill" [ngClass]="{ 'text-box': !isStaff }" *ngIf="emailDbValidated && (emailVerified || isStaff) && userEmailTested === userEmail">
    <mat-label>Last Name</mat-label>
    <input id="lastName" title="last Name" matInput value="" [(ngModel)]="lastName" name="lastName" autocomplete="lastName" />
  </mat-form-field>
  <mat-form-field appearance="fill" [ngClass]="{ 'text-box': !isStaff }" *ngIf="emailDbValidated && (emailVerified || isStaff) && userEmailTested === userEmail">
    <mat-label>Mobile</mat-label>
    <span matPrefix>04 &nbsp;</span>
    <input id="mobile" title="mobile" type="text" digitOnly matInput inputmode="numeric" maxlength="8" value="" name="mobile" [(ngModel)]="mobile" autocomplete="mobile" />
  </mat-form-field>
  <ng-hcaptcha
    [ngClass]="{ 'text-box': !isStaff }"
    [siteKey]="captchaCode"
    *ngIf="emailDbValidated && emailVerified && !isStaff && userEmailTested === userEmail"
    (verify)="onVerify($event)"
    (expired)="onExpired($event)"
    (error)="onError($event)"
  ></ng-hcaptcha>
  <span *ngIf="creationError" class="error-display" [ngClass]="{ 'error-align': !isStaff }">{{ creationError }}</span>
  <button mat-raised-button id="register" color="primary" (click)="onSubmit()" class="button-action" [disabled]="isRegisterDisabled()">Register</button>
  <fa-icon [icon]="faCircleNotch" [spin]="true" [ngClass]="{ 'text-box': !isStaff }" *ngIf="registerDisabled"></fa-icon>
</div>
