import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../shared/services/auth.service';
import {Router, ActivatedRoute} from '@angular/router';
import { faCircleNotch, faEye, faEyeSlash } from '@fortawesome/pro-regular-svg-icons';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public faCircleNotch = faCircleNotch;
  public faEye = faEye;
  public faEyeSlash = faEyeSlash;
  public loading: boolean;
  public hasError: boolean;
  private _return: string;
  public passwordHide: Boolean;
  public form: UntypedFormGroup;
  public offlineUserName: string;
  public interval;
  private configSnackBar: MatSnackBarConfig = {
      panelClass: ["style-bar"],
      duration: 3000,
  };
  
  constructor(
    private _authService: AuthService,
    private _snackBar: MatSnackBar,
    private _formBuilder: UntypedFormBuilder,
    private _router: Router,
    private _route: ActivatedRoute
  ) { 
    this.passwordHide = true;
    this._authService.logOut();
        
    this._authService.inProgress$.subscribe((result) => {
        this.loading = result;
    });

    this._authService.hasError$.subscribe((result) => {
        if (result) {
            this._snackBar.open(
                this._authService.error,
                null,
                this.configSnackBar
            );
        }
    });
    this.form = this._formBuilder.group({
        userName: "",
        password: "",
    });
  }

  ngOnInit() {
    this._route.queryParams
      .subscribe(params => this._return = params['return'] || '/home');

    if(this._authService.loggedIn){
      this._router.navigateByUrl(this._return);
    }
  }

  public onSubmit(authData: any){
    this._authService.logIn(authData.userName, authData.password, this._return);
  }

}
