<mat-drawer-container [hasBackdrop]="true" class="app-container">
  <mat-drawer-content>
      <mat-toolbar class="mat-elevation-z3 sticky">
          <mat-toolbar-row class="topnav">
              <img src="../../assets/images/logo-agrimaster.svg" alt="logo Agrimaster" class="logo" (click)="goToFiles()"/>
              <h1 class="header" >{{title}}</h1>
              <span class="fill-remaining-space"></span>
              <div class="menu-button-row">
                  <button *ngIf="isUpdateAvailable" mat-flat-button color="primary" aria-label="Updates"
                      (click)="showUpdateMessage()">
                      <fa-icon [icon]="faGift" size="lg"></fa-icon><span class="button-text">Update Available</span>
                  </button>
                  <a mat-button class="frill-widget" *ngIf="userVoiceFeedbackWidgetEnabled">
                    <fa-icon [icon]="faLightbulb" size="lg"></fa-icon><span class="button-text">Ideas</span>
                  </a>
                  <a mat-button href="https://support.agrimaster.com.au/hc/en-us?utm_source=File%20Manager&utm_medium=Help%20Button"
                      target="_blank">
                      <fa-icon [icon]="faQuestionCircle" size="lg"></fa-icon><span class="button-text">Help</span>
                  </a>
                  <button *ngIf="notificationUnreadCount > 0" mat-flat-button aria-label="Notifications"
                      color="accent" (click)="notifications.toggle()">
                      <span class="notification-count">{{notificationUnreadCount}}</span><span
                          class="button-text">Notifications</span>
                  </button>
                  <button *ngIf="notificationUnreadCount == 0" mat-button aria-label="Notifications"
                      (click)="notifications.toggle()">
                      <fa-icon [icon]="faBell" size="lg"></fa-icon><span class="button-text">Notifications</span>
                  </button>
                  <button id="userMenu" mat-button aria-label="User" [matMenuTriggerFor]="userMenu">
                      <fa-icon [icon]="faUserCircle" size="lg"></fa-icon><span class="button-text">{{userDisplayName}}</span>
                  </button>
                  <mat-menu #userMenu="matMenu" xPosition="before">
                      <button id="changePasswordMenu" mat-menu-item (click)="goToPasswordChange()">Change Password</button>
                      <button id="logOutMenu" mat-menu-item (click)="returnToLogin()">Log out</button>
                  </mat-menu>
              </div>
          </mat-toolbar-row>
      </mat-toolbar>
      <div class="allow-header">
          <router-outlet></router-outlet>
      </div>
  </mat-drawer-content>
  <mat-drawer #notifications [mode]="over" position="end" class="sidenav">
      <div class="notification-container">
          <div class="notifications-header">Notifications</div>
          <span class="fill-remaining-space"></span>
          <button mat-button aria-label="close" (click)="notifications.toggle()" class="close-icon">
              <fa-icon [icon]="faTimes" size="lg"></fa-icon>
          </button>
      </div>
      <mat-expansion-panel *ngFor="let notification of notificationList"
          [ngClass]="{'notification-panel': !notification.isRead, 'notification-panel-read': notification.isRead}"
          (afterExpand)="setAsRead(notification)">
          <mat-expansion-panel-header [ngClass]="{'notification-panel': !notification.isRead, 'notification-panel-read': notification.isRead}">
              <mat-panel-title class="notification-title">
                  <span>{{notification.title}}</span>
                  <span class="fill-remaining-space"></span>
                  <span>{{notification.creation.fromNow()}}</span>
              </mat-panel-title>
          </mat-expansion-panel-header>
          <div class="notification" [innerHTML]="notification.description"></div>
      </mat-expansion-panel>
  </mat-drawer>
</mat-drawer-container>