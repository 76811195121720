import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { AuthService } from '../services/auth.service';

export class AuthInterceptor implements HttpInterceptor {
    constructor(private _authService: AuthService) {
    }
  
    public intercept(req: HttpRequest<any>, next: HttpHandler) {
      if (this._authService.currentUserValue?.token && !req.headers.has('Authorization')) {
        const clonedRequest = req.clone({
          headers: req.headers.append('Authorization', `Bearer ${this._authService.currentUserValue.token}`)
                              .append('UsingJwtToken', 'true'),
        });
        return next.handle(clonedRequest);
      } else if (this._authService.token && !req.headers.has('Authorization')) {
        const clonedRequest = req.clone({
          headers: req.headers.append('Authorization', `Bearer ${this._authService.token}`)
                              .append('UsingJwtToken', 'true'),
        });
        return next.handle(clonedRequest);
      }
      
      return next.handle(req);
    }
  }