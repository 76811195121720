import { Component, OnInit, Input } from '@angular/core';
import { Messages } from '../../shared/models/messages';

@Component({
  selector: 'app-essentials-ineligible',
  templateUrl: './essentials-ineligible.component.html',
  styleUrls: ['./essentials-ineligible.component.scss']
})
export class EssentialsIneligibleComponent implements OnInit {
  
  @Input() public ineligibilityReason: string;
  @Input("productCode") public productCode: string;
  public shopReturnUrl: string;
  
  constructor() { }

  ngOnInit(): void {
    this.shopReturnUrl = Messages.shopDisqualifiedProductReturnUrl.replace("<PRODUCTCODE>", this.productCode);
  }
}
