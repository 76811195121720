<mat-card>
    <mat-card-title>
        <fa-icon [icon]="faStickyNote" class="file-status"></fa-icon>
        <span class="header-text" *ngIf="!currentFile.note">Add Note</span>
        <span class="header-text" *ngIf="currentFile.note">Edit Note</span>
    </mat-card-title>
    <mat-card-content>
        <div class="container">
            <p>A small description of the changes on this revision</p>
            <mat-form-field appearance="fill">
                <mat-label>Note</mat-label>
                <textarea matInput placeholder="" class="text" [(ngModel)]="note"></textarea>
            </mat-form-field>
        </div>
    </mat-card-content>
    <mat-card-actions [align]="'end'">
        <button mat-button (click)="onNoClick()">Cancel</button>
        <button mat-raised-button color="primary" aria-label="Submit" (click)="onSave()" [disabled]="!note || this.noteDisabled">
            Submit
        </button>
    </mat-card-actions>
</mat-card>