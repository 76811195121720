<strong>Step 1:</strong>
<p id="documentationStepOneParagraph">
    {{ documentationStepOne }}
</p>
<span *ngIf="isPartnershipRegistration">
    <ul>
        <li>Australian Business Registration document</li>
        <li>
            Tax returns indicating all parties involved in the Partnership
            (dollar values are not required)
        </li>
    </ul>
</span>
<span *ngIf="isCompanyRegistration">
    <ul>
        <li>Australian Business Registration document</li>
        <li>ASIC Company Statement</li>
    </ul>
</span>
<strong>Step 2:</strong>
<p id="documentationStepTwoParagraph">
    {{ documentationStepTwo }}
</p>
<ul>
    <li>Australian Business Register document</li>
    <li>ASIC Company Statement</li>
</ul>
<p>
    This documentation is required to verify the directorship of both
    companies is the same.
</p>

<div class="input-row" fxLayout="row" fxLayoutGap="20px">
    <input id="documentationFileInput" #input multiple hidden type="file" onclick="this.value=null"
        (change)="FileChange($event)" accept=".pdf,.jpg,.jpeg,.png,.bmp" />
    <button id="documentationSelectFilesButton" mat-raised-button color="accent" (click)="input.click()">
        Select Files
    </button>
    <fa-icon [icon]="faCircleNotch" [spin]="true" class="file-status" *ngIf="selectingFiles"></fa-icon>
</div>

<div class="input-row" fxLayout="row" fxLayoutGap="20px">
    <mat-list #filesList dense>
        <mat-list-item *ngFor="let file of files">
            <button mat-button matSuffix mat-icon-button aria-label="Delete" color="warn"
                (click)="removeSelected(file.name)">
                <fa-icon [icon]="faTrashAlt"></fa-icon>
            </button>
            {{ file.name }}
        </mat-list-item>
    </mat-list>
</div>

