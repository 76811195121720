import { Component, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { MatDialog } from "@angular/material/dialog";
import { AddNoteComponent } from "../add-note/add-note.component";
import { OpenReadOnlyComponent } from "../open-read-only/open-read-only.component";
import { ManageShareComponent } from "../manage-share/manage-share.component";
import {
    faAward,
    faEllipsisV,
    faHome,
    faShareAlt,
    faHistory,
    faInfoCircle,
    faExclamationTriangle,
    faCircleNotch,
    faCheck,
    faTimes
} from "@fortawesome/pro-regular-svg-icons";
import { faCloud } from "@fortawesome/pro-solid-svg-icons";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "src/shared/services/auth.service";
import { StateAwareFile } from "src/shared/models/state-aware-file";
import { ShareDetails } from "src/shared/models/share-details";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import { catchError } from "rxjs/operators";
import { throwError, Subscription, from } from "rxjs";
import { RestoreFileComponent } from "../restore-file/restore-file.component";
import { DeleteFileComponent } from "../delete-file/delete-file.component";
import { OpenRevisionReadOnlyComponent } from "../open-revision-read-only/open-revision-read-only.component";
import * as configcat from "configcat-js";
import { RefreshLicenseComponent } from "../refresh-license/refresh-license.component";
import { BackupFile, BackupType, FileServiceProxy, SharingPermission, SharingPermissions } from 'src/shared/service-proxies/api-service-proxies';
import { AppConfigService } from 'src/shared/services/app-config.service';
import { FileStatus } from 'src/shared/models/file-status';
import { ShareStatus } from 'src/shared/models/share-status';
import { FileService } from 'src/shared/services/file.service';

export interface DialogData {
    note: string;
}

@Component({
    selector: "app-business-overview",
    templateUrl: "./business-overview.component.html",
    styleUrls: ["./business-overview.component.scss"],
})
export class BusinessOverviewComponent implements OnInit {
    public faCloud = faCloud;
    public faAward = faAward;
    public faEllipsisV = faEllipsisV;
    public faHome = faHome;
    public faShareAlt = faShareAlt;
    public faHistory = faHistory;
    public faInfoCircle = faInfoCircle;
    public faExclamationTriangle = faExclamationTriangle;
    public faCircleNotch = faCircleNotch;
    public faCheck = faCheck;
    public faTimes = faTimes;

    public sharingDisplayedColumns: string[] = [
        "username",
        "permissions",
        "actions",
    ];
    public historyDisplayedColumns: string[] = [
        "icon",
        "modified",
        "modifiedBy",
        "note",
        "license-status-icon",
        "license-status",
        "actions",
    ];
    public sharedToList: MatTableDataSource<SharingPermission>;
    public historyList: MatTableDataSource<BackupFile>;
    public note: string;
    public openDisabled: boolean;
    public retryDisplayed: boolean;
    public currentFileStatus: FileStatus;
    public businessId: string;
    public fileType: string;
    public file: StateAwareFile;
    public FileStatus = FileStatus;
    public ShareStatus = SharingPermissions;
    public loading: boolean;
    public userName: string;
    public summary: BackupFile;
    public isRejectEnabled: boolean;
    public isEditionEnabled: boolean;
    public wagemasterExists: boolean;
    private _sharingListSubscription: Subscription;
    private _fileHistorySubscription: Subscription;
    private _summarySubscription: Subscription;
    private configSnackBar: MatSnackBarConfig = {
        panelClass: ["style-bar"],
        duration: 3000,
    };
    private _processingTimeout: NodeJS.Timeout;
    private _apiUrl: string;
    public normalisationFeatureEnabled: boolean;
    public BackupType = BackupType;

    constructor(
        public dialog: MatDialog,
        private _route: ActivatedRoute,
        private _fileService: FileService,
        private _revisionServiceProxy: FileServiceProxy,
        private _snackBar: MatSnackBar,
        private _router: Router,
        private _authService: AuthService,
        private _fileServiceProxy: FileServiceProxy
    ) {
        //this._processingTimeout = null;
        this.isRejectEnabled = false;
        this.isEditionEnabled = false;
        this.loading = true;
        this.wagemasterExists = false;
        this.openDisabled = false;
        this.retryDisplayed = false;
        this.normalisationFeatureEnabled = false;

        if (this._authService.toggleClient) {
            from(
                this._authService.toggleClient.getValueAsync(
                    "rejectShare",
                    false,
                    this._authService.currentUserValue.configUser
                )
            ).subscribe((result) => {
                this.isRejectEnabled = result;
            });

            from(
                this._authService.toggleClient.getValueAsync(
                    "displayOwnerEdition",
                    false,
                    this._authService.currentUserValue.configUser
                )
            ).subscribe((result) => {
                this.isEditionEnabled = result;
            });

            // from(
            //     this._authService.toggleClient.getValueAsync(
            //         "displayStashFiles",
            //         false,
            //         this._authService.currentUserValue.configUser
            //     )
            // ).subscribe((result) => {
            //     if (result) {
            //         this._fileService
            //             .getStashFiles(
            //                 this.file.backupDetails?.summary.name,
            //                 this.file.backupDetails?.summary.userOwnedBy
            //                     .userName,
            //                 this.file.backupDetails?.summary.fileType
            //             )
            //             .subscribe((stashFiles) => {
            //                 this.stashFiles = stashFiles;
            //             });
            //     }
            // });

            from(
                this._authService.toggleClient.getValueAsync(
                    "normalisationStatusEnabled",
                    false,
                    this._authService.currentUserValue.configUser
                )
            ).subscribe((result) => {
                this.normalisationFeatureEnabled = result;

                if (result) {
                    this.historyDisplayedColumns.splice(
                        this.historyDisplayedColumns.length - 1,
                        0,
                        "normalisation-status-icon"
                    );
                    this.historyDisplayedColumns.splice(
                        this.historyDisplayedColumns.length - 1,
                        0,
                        "normalisation-status"
                    );
                }
            });
        } else {
            this._authService.toggleClient = configcat.createClientWithAutoPoll(
                AppConfigService.settings.configCat,
                {
                    pollIntervalSeconds: AppConfigService.settings.configInterval
                });

            from(
                this._authService.toggleClient.getValueAsync(
                    "rejectShare",
                    false,
                    this._authService.currentUserValue.configUser
                )
            ).subscribe((result) => {
                this.isRejectEnabled = result;
            });

            from(
                this._authService.toggleClient.getValueAsync(
                    "displayOwnerEdition",
                    false,
                    this._authService.currentUserValue.configUser
                )
            ).subscribe((result) => {
                this.isEditionEnabled = result;
            });
        }

        this._route.parent.paramMap.subscribe((params) => {
            this.businessId = params.get("id");
            this.fileType = params.get("type");
            this._fileService
                .getFile(this.businessId, this.fileType)
                .subscribe((result) => {
                    this.file = result as StateAwareFile;
                    if (this.file) {
                        if (
                            this.file.backupDetails.summary.fileType ==
                            BackupType.Wagemaster
                        ) {
                            this.wagemasterExists = true;
                        } else if (
                            this._fileService.confirmWagemasterExists(
                                this.file.backupDetails.summary.name
                            )
                        ) {
                            this.wagemasterExists = true;
                        }

                        this.file.syncStatus$.subscribe((result) => {
                            this.currentFileStatus = result;
                            if (result === FileStatus.FileInUse) {
                                this.openDisabled = false;
                            }

                            if (
                                result === FileStatus.ProcessingError &&
                                this.file.currentSummary
                                    .licenseInjectionAttemptCount < 3
                            ) {
                                this.retryDisplayed = true;
                            } else {
                                this.retryDisplayed = false;
                            }

                            if (result === FileStatus.Processing) {
                                const _processingTimeoutInMs: number = 180000;

                                if (this._processingTimeout === null) {
                                    this._processingTimeout = setTimeout(() => {
                                        console.warn(
                                            `Agrimaster do not receive signalr message that injection has been complete for ${
                                                _processingTimeoutInMs / 60000
                                            } minutes`
                                        );
                                        this._fileService.updateFileFromServer(
                                            this.file
                                        );
                                    }, _processingTimeoutInMs);
                                }
                            } else {
                                if (this._processingTimeout) {
                                    clearTimeout(this._processingTimeout);
                                    this._processingTimeout = null;
                                }
                            }
                        });

                        this._sharingListSubscription = this.file.sharingList$.subscribe(
                            (result) => {
                                this.sharedToList = new MatTableDataSource(
                                    result
                                );
                            }
                        );

                        this._fileHistorySubscription = this.file.fileHistory$.subscribe(
                            (result) => {
                                this.historyList = new MatTableDataSource(
                                    result
                                );
                            }
                        );

                        this._summarySubscription = this.file.summary$.subscribe(
                            (result) => {
                                this.summary = result;
                            }
                        );

                        this.loading = false;
                    }
                });
        });
        this.userName = this._authService.currentUserValue.userName;
    }

    ngOnInit(): void {
    }

    public openDialog(file: BackupFile): void {
        const dialogRef = this.dialog.open(AddNoteComponent, {
            width: "500px",
            data: file,
            panelClass: "agrimaster-dialog-container",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result && file.note !== result) {
                file.note = result;
            }
        });
    }

    public openReadOnly(): void {
        const dialogRef = this.dialog.open(OpenReadOnlyComponent, {
            width: "500px",
            data: this.file,
            panelClass: "agrimaster-dialog-container",
        });
    }

    public openRevisionReadOnly(file: BackupFile): void {
        const dialogRef = this.dialog.open(OpenRevisionReadOnlyComponent, {
            width: "500px",
            data: file,
            panelClass: "agrimaster-dialog-container",
        });
    }

    public openDelete(): void {
        const dialogRef = this.dialog.open(DeleteFileComponent, {
            width: "700px",
            data: this.file.backupDetails,
            panelClass: "agrimaster-dialog-container",
        });
    }

    public openFile() {
        this.openDisabled = true;
        let subscriptionId = 0;

        if (this.summary.subscriptionId) {
            subscriptionId = this.summary.subscriptionId;
        }

        let encodeUriFileChecksum = "";
        if (this.summary.fileChecksum != null) {
            encodeUriFileChecksum = encodeURIComponent(
                this.summary.fileChecksum
            );
        }

        if (this.fileType == "Agrimaster") {
            window.open(
                "agrimaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    this.summary.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(this.summary.name) +
                    "/" +
                    "" + //readonly | stash
                    "/" +
                    "" + //revision guid
                    "/" +
                    encodeUriFileChecksum,
                "_self"
            );
        } else {
            window.open(
                "wagemaster://" +
                    subscriptionId +
                    "/" +
                    this._authService.currentUserValue.userProfile.userName +
                    "/" +
                    this.summary.userOwnedBy.userName +
                    "/" +
                    encodeURIComponent(this.summary.name) +
                    "/" +
                    "" + //readonly | stash
                    "/" +
                    "" + //revision guid
                    "/" +
                    encodeUriFileChecksum,
                "_self"
            );
        }
    }

    public openManageShare(share?: SharingPermission): void {
        let sharingPermission: ShareDetails = {
            file: this.summary,
            share: share,
            existingShares: this.file.currentSharingList,
        };

        const dialogRef = this.dialog.open(ManageShareComponent, {
            width: "500px",
            data: sharingPermission,
            panelClass: "agrimaster-dialog-container",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.file.setSharingList(result);
            }
        });
    }

    public userCreated(revision: BackupFile): boolean {
        if (
            revision.userSavedBy.userName ===
            this._authService.currentUserValue.userName
        ) {
            return true;
        }
        return false;
    }

    // public openWagemasterLink(): void {
    //     const dialogRef = this.dialog.open(WagemasterLinkComponent, {
    //         width: "750px",
    //         data: this.file.backupDetails.summary,
    //         panelClass: "agrimaster-dialog-container",
    //     });

    //     dialogRef.afterClosed().subscribe((result) => {
    //         if (result) {
    //             this.wagemasterExists = true;
    //             this._snackBar.open(
    //                 "Wagemaster file linked successfully",
    //                 null,
    //                 this.configSnackBar
    //             );
    //             this._router.navigateByUrl("/home");
    //         }
    //     });
    // }

    public transformFileSize(value: number): string {
        if (!value) {
            return "0B";
        }

        return this.toHumanReadableFileSize(value);
    }

    public restoreFile(value: BackupFile) {
        const dialogRef = this.dialog.open(RestoreFileComponent, {
            width: "500px",
            data: value,
            panelClass: "agrimaster-dialog-container",
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                this.file.setFileStatus(FileStatus.Processing);
            }
        });
    }

    private toHumanReadableFileSize(size: number): string {
        const threshold = 1024;
        if (Math.abs(size) < threshold) {
            return size + "B";
        }

        const units = ["KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        let u = -1;
        do {
            size /= threshold;
            u++;
        } while (Math.abs(size) >= threshold && u < units.length - 1);

        return size.toFixed(1) + units[u];
    }

    public showBothOpenButtons(): Boolean {
        if (this.summary.isPendingInjection) {
            return false;
        }

        if (this.summary.subscription.planType == "Agrimaster Read Only") {
            return false;
        }

        if (
            this.file.currentStatus === FileStatus.BackedUp &&
            this.file.sharedStatus != ShareStatus.Read
        ) {
            return true;
        }
        return false;
    }

    public refreshLicense() {
        const dialogRef = this.dialog.open(RefreshLicenseComponent, {
            width: "500px",
            data: this.file,
            panelClass: "agrimaster-dialog-container",
        });
    }

    public showOnlyReadOnly(): Boolean {
        if (this.summary.isPendingInjection) {
            return false;
        }

        if (this.summary.subscription.planType == "Agrimaster Read Only") {
            return true;
        }

        if (
            this.file.currentStatus === FileStatus.BackedUp &&
            this.file.sharedStatus != ShareStatus.Read
        ) {
            return false;
        }

        if (this.file.currentStatus === FileStatus.ProcessingError) {
            return false;
        }
        return true;
    }

    public rejectShare() {
        // this._revisionServiceProxy
        //     .rejectShare(
        //         this._authService.currentUserValue.userName,
        //         this.file.backupDetails.sharedWith[0].id,
        //         this.file.backupDetails.summary
        //     )
        //     .pipe(
        //         catchError((err) => {
        //             this._snackBar.open(
        //                 "Could not reject share",
        //                 null,
        //                 this.configSnackBar
        //             );
        //             return throwError(err);
        //         })
        //     )
        //     .subscribe((result) => {
        //         this._fileService.removeFile(this.file);
        //         this._snackBar.open(
        //             "Share rejected successfully",
        //             null,
        //             this.configSnackBar
        //         );
        //         this._router.navigateByUrl("/home");
        //     });
    }

    public getSharingPermissionString(share?: SharingPermissions): string {
        if (share) {
            if (share == SharingPermissions.Read) {
                return "Read Only";
            }

            if (share == SharingPermissions.ReadWrite) {
                return "Read And Write";
            }
        }
        return "None";
    }

    public showWagemasterBanner() {
        if (
            this.summary.subscription.planType === "Agrimaster-Plus" &&
            this.summary.userOwnedBy.userName === this.userName &&
            !this.wagemasterExists
        ) {
            return true;
        }
        return false;
    }

    public injectRevision(): void {
        this._fileServiceProxy
            .injectRevision(this.file.backupDetails.summary.id)
            .pipe(
                catchError((err) => {
                    this._snackBar.open(
                        "Inject Revision Failed",
                        null,
                        this.configSnackBar
                    );
                    return throwError(err);
                })
            )
            .subscribe(() => {
                this._snackBar.open(
                    `${this.file.backupDetails.summary.name} has been sent to our servers to be updated with the license details.`,
                    null,
                    this.configSnackBar
                );
            });
    }

    /**
     * Filter only top two revisions
     * @param revisionId
     */
    public displayLicenseStatus(revision: BackupFile): boolean {
        var result: boolean = false;

        if (revision.fileType === BackupType.Wagemaster) {
            return result;
        }

        let topTwoRevision: Array<BackupFile> = [];
        topTwoRevision = this.file.backupDetails.revisions.slice(0, 2);

        if (
            topTwoRevision.length > 0 &&
            topTwoRevision.find((r) => r.id === revision.id) !== undefined
        ) {
            result = true;
        }

        return result;
    }
}
