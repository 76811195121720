import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { UserProfile } from 'src/shared/service-proxies/api-service-proxies';
import { ClientService } from 'src/shared/services/client.service';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { Messages } from 'src/shared/models/messages';
import { PurchasingService } from 'src/shared/services/purchasing.service';
@Component({
  selector: 'app-search-existing-user',
  templateUrl: './search-existing-user.component.html',
  styleUrls: ['./search-existing-user.component.scss']
})
export class SearchExistingUserComponent implements OnInit, OnChanges {
  /**
   * if this existing parent username is given, means search result will filter the existing parent username
   */
  @Input() parentUserName: string | undefined = undefined;

  /**
   * If this agrimaster subscription criteria is given, means  after we found a user profile,
   * there will be another filter request to make sure that user only has this agrimaster plan
   */
  @Input() agrimasterSubscriptionCriteria: string | undefined = undefined;

  /**
   * If this user with no subscription criteria is true, means after we found a user profile,
   * there will be another filter request to make sure only user that has no subscription
   */
  @Input() userWithNoSubscriptionCriteria: boolean = false;

  existingClientDetailsGroup: UntypedFormGroup;

  public existingClientSearchError = '';
  public existingClientSearching = false;
  public clientLocated = false;

  public faCircleNotch = faCircleNotch;

  @Output() userSearchSuccess: EventEmitter<UserProfile> = new EventEmitter();

  constructor(private _formBuilder: UntypedFormBuilder, private _clientService: ClientService, private _purchasingService: PurchasingService) {}

  ngOnChanges(changes: SimpleChanges): void {
    const inputParentUserName = changes['parentUserName'];
    const inputAgrimasterSubscriptionCriteria = changes['agrimasterSubscriptionCriteria'];
    const inputUserWithNoSubscriptionCriteria = changes['userWithNoSubscriptionCriteria'];

    if (inputParentUserName) {
      this.parentUserName = inputParentUserName.currentValue as string;
      this.reset();
    }

    if (inputAgrimasterSubscriptionCriteria) {
      this.agrimasterSubscriptionCriteria = inputAgrimasterSubscriptionCriteria.currentValue as string;
    }

    if (inputUserWithNoSubscriptionCriteria) {
      this.userWithNoSubscriptionCriteria = inputUserWithNoSubscriptionCriteria.currentValue as boolean;
    }
  }

  ngOnInit(): void {
    this.existingClientDetailsGroup = this._formBuilder.group({
      userName: ['', Validators.required],
      emailAddress: ['', Validators.required && Validators.email]
    });
  }

  reset() {
    this.clientLocated = false;
    this.existingClientSearchError = '';
    this.existingClientSearching = false;
  }

  existingClientDetailChange() {
    if (this.clientLocated) {
      this.reset();
    }
  }

  async clientSearchAsync() {
    this.userSearchSuccess.emit(null);

    this.existingClientSearchError = '';
    this.existingClientSearching = true;

    const username = this.existingClientDetailsGroup.get('userName').value;
    const emailAddress = this.existingClientDetailsGroup.get('emailAddress').value;
    try {
      let userProfile = await this._clientService.searchExistingUserAsync(username, emailAddress);
      this.existingClientSearching = false;

      if (userProfile) {
        if (this.isParentUserNameSameWithSearchResult(userProfile)) {
          this.clientLocated = false;
          this.existingClientSearching = false;
          this.existingClientSearchError = `Cannot assign this username (${this.parentUserName}) as it is same with primary user`;
          return;
        }

        let isCriteriaValid = await this.isUserMatchingFilterCriteriaAsync(userProfile);

        if (!isCriteriaValid) {
          this.clientLocated = false;
          this.existingClientSearching = false;
          this.existingClientSearchError = this.buildErrorMessage();
          return;
        }
        this.clientLocated = true;
        this.userSearchSuccess.emit(userProfile);
      }
    } catch (err) {
      this.clientLocated = false;
      this.existingClientSearching = false;

      this.existingClientSearchError = err.response ? err.response : Messages.cannotFindExistingUserErrorMessage;
    }
  }

  isParentUserNameSameWithSearchResult(userProfile: UserProfile): boolean {
    return this.parentUserName && this.parentUserName === userProfile.userName;
  }

  /**
   * If filter agrimaster subscription and filter user with no subscription are exist then
   * Check if the matching user profile only has that subscription and no subscription at all
   * @param userProfile
   * @returns
   */
  async isUserMatchingFilterCriteriaAsync(userProfile: UserProfile): Promise<boolean> {
    let result = true;
    if (userProfile?.userName && this.checkIfCriteriaFilterIsBeingApplied()) {
      let subscriptions = await this._purchasingService.getSubscriptionsAsync(userProfile.userName);

      //if we found a user with no subscription and user with no subscription criteria matching input is true,
      //then we do not need to filter
      if (subscriptions.length <= 0 && this.userWithNoSubscriptionCriteria) {
        return result;
      }

      //if we found that user has more than subscription other than filter agrimaster subscription
      //means its not longer matching criteria
      result = subscriptions.filter((w) => w.planType !== this.agrimasterSubscriptionCriteria).length <= 0;
    }

    return result;
  }

  checkIfCriteriaFilterIsBeingApplied(): boolean {
    return (this.agrimasterSubscriptionCriteria !== undefined && this.agrimasterSubscriptionCriteria.length > 0) || this.userWithNoSubscriptionCriteria;
  }

  /**
   * Build friendly error message if any filter criteria is being applied
   * @returns
   */
  buildErrorMessage(): string {
    let errorMessage = Messages.cannotFindExistingUserErrorMessage;
    if (this.userWithNoSubscriptionCriteria) {
      errorMessage = Messages.ags7FilterSearchExistingUserErrorMessage;
    }
    if (this.agrimasterSubscriptionCriteria) {
      errorMessage = `an existing user must already be an ${this.agrimasterSubscriptionCriteria} user, or have no subscriptions`;
    }
    return errorMessage;
  }
}
