import { Injectable } from '@angular/core';
import { Ags7SubscriptionRequest, PurchasingServiceProxy, ShopProduct, SubscriptionInfo, SubscriptionRequest } from '../service-proxies/api-service-proxies';
import { Router } from '@angular/router';
import { Observable, lastValueFrom } from 'rxjs';
import { Stripe } from 'src/components/stripe';
import { PaymentMethodData, PaymentMethodResult } from 'src/components/stripe/stripe-definitions/payment-method';
import { PaymentIntentResult } from 'src/components/stripe/stripe-definitions/payment-intent';

@Injectable({
  providedIn: 'root'
})
export class PurchasingService {
  constructor(private _stripe: Stripe, private _purchasingService: PurchasingServiceProxy, private _router: Router) {}

  public getProduct(): Observable<ShopProduct> {
    let productCode: string;

    if (this._router.url.endsWith('essentials')) {
      productCode = 'AGS4';
    } else if (this._router.url.endsWith('lite')) {
      productCode = 'AGS6';
    } else {
      let fullUrl = this._router.url.split('/');

      if (fullUrl !== null && fullUrl.length > 0) {
        productCode = fullUrl[fullUrl.length - 1];
      }

      productCode = productCode.toUpperCase();
    }
    return this._purchasingService.product(productCode);
  }

  public product(productCode: string): Observable<ShopProduct> {
    return this._purchasingService.product(productCode);
  }

  public proration(username: string, productCode: string, discountCode: string | undefined, primarySubscriptionId: number | undefined = undefined) {
    return this._purchasingService.proration(username, productCode, discountCode, primarySubscriptionId);
  }

  public purchase(subscriptionRequest: SubscriptionRequest) {
    return this._purchasingService.purchase(subscriptionRequest);
  }

  public purchaseAgs7Subscription(Ags7SubscriptionRequest: Ags7SubscriptionRequest) {
    return this._purchasingService.purchaseAgs7(Ags7SubscriptionRequest);
  }

  public createPaymentMethod(data: PaymentMethodData): Promise<PaymentMethodResult> {
    return this._stripe.createPaymentMethod(data);
  }

  public confirmCardPayment(clientSecret: string): Promise<PaymentIntentResult> {
    return this._stripe.confirmCardPayment(clientSecret);
  }

  public async getCandidatePrimarySubscriptionsAsync(userName: string): Promise<SubscriptionInfo[]> {
    const subscriptionInfos = await lastValueFrom(this._purchasingService.candidatePrimarySubscriptions(userName));
    return subscriptionInfos;
  }

  /**
   * Get all subscriptions regardless it is expired or not
   * @param userName
   * @returns
   */
  public async getSubscriptionsAsync(userName: string): Promise<SubscriptionInfo[]> {
    const subscriptionInfos = await lastValueFrom(this._purchasingService.subscriptions(userName));
    return subscriptionInfos;
  }
}
