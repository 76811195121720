<div class="input-row" fxLayout="row" fxLayoutGap="20px">
  <mat-button-toggle-group name="fontStyle" aria-label="Font Style" required value="Existing">
    <mat-button-toggle id="ags7ChooseExistingButton" (click)="toggleAgs7NewExistingUser(false)" value="Existing">Existing</mat-button-toggle>
    <mat-button-toggle id="ags7ChooseNewButton" (click)="toggleAgs7NewExistingUser(true)" value="New">New</mat-button-toggle>
  </mat-button-toggle-group>
</div>

<div *ngIf="this.isStaffBuyingForNew; else existingUser">
  <app-create-user id="ags7NewUser" (userCreateSuccess)="onSetAGS7NewUser($event)" [parentUserName]="parentUserName"></app-create-user>
</div>
<ng-template #existingUser>
  <app-search-existing-user
    id="ags7existingUser"
    (userSearchSuccess)="onSetAGS7ExistingUser($event)"
    [parentUserName]="parentUserName"
    [userWithNoSubscriptionCriteria]="true"
  ></app-search-existing-user>
</ng-template>

<div class="input-row">
  <mat-form-field *ngIf="ags7UserDetail && ags7UserDetail.userId && ags7UserDetail.userId > 0">
    <input matInput placeholder="Ags7 User Number" readonly id="ags7userNumber" [value]="ags7UserDetail.userName" />
  </mat-form-field>
</div>
