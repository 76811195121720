import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatSnackBarConfig, MatSnackBar } from '@angular/material/snack-bar';
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { faCircleNotch } from '@fortawesome/pro-regular-svg-icons';
import { ProfileServiceProxy } from 'src/shared/service-proxies/api-service-proxies';

@Component({
  selector: 'app-account-recovery',
  templateUrl: './account-recovery.component.html',
  styleUrls: ['./account-recovery.component.scss']
})
export class AccountRecoveryComponent implements OnInit {
  public form: UntypedFormGroup;
  public loading: boolean;
  public faCircleNotch = faCircleNotch;
  
  private configSnackBar: MatSnackBarConfig = {
    panelClass: ['style-bar'],
    duration: 3000
  };
  
  constructor(private _router: Router, private _snackBar: MatSnackBar,
    private _profileService: ProfileServiceProxy, private _formBuilder: UntypedFormBuilder) {

    this.form = _formBuilder.group({
      userName:  ['', Validators.required],
      emailAddress:  ['', Validators.required],
    });
   }

  ngOnInit() {
  }

  public returnToLogin()
  {
    this._router.navigateByUrl('/auth/login');
  }

  public goToConfirmation()
  {
    this.loading = true;
    this._profileService.resetPassword(this.form.get('userName').value, this.form.get('emailAddress').value).pipe(
      catchError(err => {
        this.loading = false;
        this._snackBar.open("Account details do not match our records", null, this.configSnackBar);
        return throwError(err);
      })
    ).subscribe((response: any) => {
      this._router.navigateByUrl('/auth/confirmation');
    })
    
  }
}
