<div class="product-content-container">
  <div class="product-container mat-elevation-z2">
    <h2>{{ productShortName }}</h2>
    <div class="main-container" *ngIf="ineligibilityReason.length == 0 && !successfulPurchase">
      <mat-vertical-stepper [linear]="true" #stepper>
        <mat-step *ngIf="requiresClientDetail" [stepControl]="clientDetailsGroup" [completed]="clientDetailsComplete">
          <form id="clientDetailsForm" [formGroup]="clientDetailsGroup" fxLayout="column">
            <ng-template matStepLabel>Client Details</ng-template>

            <div *ngIf="purchaserIsStaffMember" class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-button-toggle-group name="fontStyle" aria-label="Font Style" required value="Existing">
                <mat-button-toggle id="forNewUserNoButton" (click)="forNewUserChanged('Existing')" (keydown)="forNewUserChanged('Existing')" value="Existing">Existing</mat-button-toggle>
                <mat-button-toggle id="forNewUserYesButton" (click)="forNewUserChanged('New')" (keydown)="forNewUserChanged('New')" value="New" [disabled]="!isNewUserAllowedToBuy">New</mat-button-toggle>              </mat-button-toggle-group>
            </div>

            <div *ngIf="isStaffBuyingForNew">
              <app-create-user (userCreateSuccess)="onUserCreateSuccess($event)"></app-create-user>
            </div>

            <div *ngIf="isStaffBuyingForNew" class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex *ngIf="newUserHasDetails">
                <input matInput placeholder="New User Number" readonly id="newUserNameView" [value]="newUserProfile.userName" />
              </mat-form-field>
            </div>

            <div *ngIf="!isStaffBuyingForNew">
              <div class="input-row" fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex>
                  <input matInput placeholder="Agrimaster User Number" formControlName="userName" digitOnly required id="userNumber" (change)="ClientDetailChange($event)" />
                </mat-form-field>
                <mat-form-field fxFlex>
                  <input matInput placeholder="Contact Email Address" formControlName="emailAddress" required id="emailAddress" (change)="ClientDetailChange($event)" />
                </mat-form-field>
              </div>
              <div class="input-row" fxLayout="row" fxLayoutGap="20px">
                <button type="button" mat-raised-button color="accent" (click)="clientSearch()" [disabled]="clientLocated" id="clientSearch">Search</button>
                <fa-icon [icon]="faCircleNotch" [spin]="true" class="file-status" *ngIf="clientSearching"></fa-icon>
                <span *ngIf="clientSearchError">{{ clientSearchError }}</span>
              </div>

              <div class="input-row" fxLayout="row" fxLayoutGap="20px">
                <mat-form-field fxFlex *ngIf="clientLocated && this.clientDetailsGroup.get('australianBusinessNumber').value != null">
                  <input matInput placeholder="ABN" readonly formControlName="australianBusinessNumber" id="abn" />
                </mat-form-field>
              </div>
            </div>
            <br />
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <table aria-label="Disclaimer section" aria-hidden="true">
                <tr>
                  <td class="disclaimer-align">
                    <mat-checkbox color="accent" labelPosition="after" formControlName="disclaimer" required id="confirmationCheckBox"></mat-checkbox>
                  </td>
                  <td class="disclaimer-align">
                    <ol class="disclaimer-margin" *ngIf="purchaserIsProfPartner">
                      <li>
                        By purchasing this software on your Customer’s behalf, you confirm you are acting in accordance to your own agreed terms of engagement with, and on the
                        express agency of, the Customer.
                      </li>
                      <li>
                        You acknowledge and agree that you have read and understand our
                        <a href="https://mastergroup.net.au/terms-and-conditions/#SAAS" target="_blank" rel="noopener">Terms and Conditions</a> and that:
                        <ul class="disclaimer">
                          <li>You are purchasing our products on behalf of the end user customer in the customer’s name.</li>
                          <li>
                            You will purchase a separate product subscription for each end user customer and will not bundle multiple customers on a single product
                            subscription.
                          </li>
                          <li>You are not acquiring our products for the re-sale of your own Firms services using the product.</li>
                          <li>By breaching any of these undertakings, you may be committing an act of digital piracy and will be in default of our Terms and Conditions.</li>
                        </ul>
                      </li>
                    </ol>

                    <ol class="no-style disclaimer-margin" *ngIf="purchaserIsStaffMember">
                      <li>
                        You are purchasing this on behalf of the customer. You are accepting the terms and conditions on their behalf and they will have an additional
                        opportunity to review and accept the terms and conditions when installing the software.
                      </li>
                    </ol>
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <div class="container-button">
              <span class="fill-remaining-space"></span>
              <button mat-raised-button matStepperNext color="primary" [disabled]="!clientDetailsComplete" id="nextClientDetailsbutton" type="button">
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step *ngIf="this.productCode === 'AGS7'" [stepControl]="this.primarySubscriptionId > 0" [completed]="this.primarySubscriptionId > 0">
          <ng-template matStepLabel>Primary Subscriptions</ng-template>
          <app-select-primary-subscription
            [primarySubscriptions]="this.candidatePrimarySubscriptions"
            (outputSelectedPrimarySubscriptionId)="onSelectedPrimarySubscription($event)"
          >
          </app-select-primary-subscription>
          <br />
          <div class="container-button">
            <span class="fill-remaining-space"></span>
            <button id="backSelectPrimarySubscriptionButton" type="button" mat-raised-button matStepperPrevious>Back</button>
            <button
              id="nextSelectPrimarySubscriptionButton"
              type="button"
              mat-raised-button
              matStepperNext
              color="primary"
              [disabled]="this.primarySubscriptionId <= 0"
              (click)="startCalculateOrderSummary()"
            >
              Next
            </button>
          </div>
        </mat-step>
        <mat-step *ngIf="this.productCode === 'AGS7'" [stepControl]="this.ags7User?.userId > 0" [completed]="this.ags7User?.userId > 0">
          <ng-template matStepLabel>AGS7 User</ng-template>
          <app-assign-ags7-user id="ags7User" (ags7User)="onSetAgs7User($event)" [parentUserName]="this.companyDetailsGroup.get('userName').value"></app-assign-ags7-user>
          <br />
          <div class="container-button">
            <span class="fill-remaining-space"></span>
            <button id="backSelectAgs7UserButton" type="button" mat-raised-button matStepperPrevious>Back</button>
            <button id="nextSelectAgs7UserButton" type="button" mat-raised-button matStepperNext color="primary" [disabled]="this.ags7User === null || !this.ags7User.userId">
              Next
            </button>
          </div>
        </mat-step>
        <mat-step [stepControl]="newSubscriberDetails" *ngIf="(isStaffBuyingForNew || this.subscriber.userProfile.isNewUser) && this.productCode !== 'AGS7'" [completed]="companyDetailsGroup.valid">
          <form id="newSubscriberDetailsForm" [formGroup]="companyDetailsGroup" fxLayout="column">
            <ng-template matStepLabel>Subscriber Details</ng-template>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="newUserAddressLine1Input" matInput placeholder="Address Line 1" formControlName="addressLine1" required />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input id="newUserAddressLine2Input" matInput placeholder="Address Line 2" formControlName="addressLine2" />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="newUserCityInput" matInput placeholder="City" formControlName="city" required />
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>State</mat-label>
                <select title="State" matNativeControl formControlName="state" name="state" required >
                  <option value="" selected></option>
                  <option *ngFor="let state of states" [value]="state">
                    {{ state }}
                  </option>
                </select>
              </mat-form-field>
              <mat-form-field fxFlex>
                <input id="newUserPostCodeInput" matInput digitOnly maxlength="4" placeholder="Post Code" formControlName="postCode" required/>
              </mat-form-field>
            </div>
            <div class="container-button">
              <span class="fill-remaining-space"></span>
              <button id="backNewSubscriberDetailsButton" type="button" mat-raised-button matStepperPrevious >Back</button>
              <button id="nextNewSubscriberDetailsButton" type="button" mat-raised-button matStepperNext color="primary" [disabled]="companyDetailsGroup.invalid">Next</button>
              </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="companyDetailsGroup" *ngIf="!isStaffBuyingForNew && !this.subscriber.userProfile.isNewUser && this.productCode !== 'AGS7'">
          <form id="companyDetailsForm" [formGroup]="companyDetailsGroup" fxLayout="column">
            <ng-template matStepLabel>Current Business Details</ng-template>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="userNumberInput" matInput placeholder="Agrimaster User Number" formControlName="userName" readonly required />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input id="abnInput" matInput placeholder="ABN" formControlName="australianCompanyNumber" digitOnly readonly />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="companyNameInput" matInput placeholder="Business Name" readonly formControlName="companyName" readonly />
              </mat-form-field>
            </div>
        
            <br />
            <h4 mat-subheader>Address</h4>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="addressLine1Input" matInput placeholder="Address Line 1" readonly formControlName="addressLine1" />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input id="addressLine2Input" matInput placeholder="Address Line 2" readonly formControlName="addressLine2" />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="cityInput" matInput placeholder="City" readonly formControlName="city" />
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>State</mat-label>
                <select title="State" matNativeControl formControlName="state" readonly name="state" >
                  <option value="" selected></option>
                  <option *ngFor="let state of states" [value]="state">
                    {{ state }}
                  </option>
                </select>
              </mat-form-field>
              <mat-form-field fxFlex>
                <input id="postCodeInput" matInput placeholder="Post Code" readonly formControlName="postCode" />
              </mat-form-field>
            </div>
            <h4 mat-subheader>Contact Details</h4>
            <mat-form-field fxFlex>
              <input id="contactNameInput" matInput placeholder="Contact Name" readonly formControlName="contactName" required />
            </mat-form-field>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="contactPhoneInput" matInput placeholder="Contact Phone" readonly formControlName="contactPhone" required />
              </mat-form-field>
              <mat-form-field fxFlex>
                <input id="emailInput" matInput placeholder="Email" readonly formControlName="contactEmail" required />
              </mat-form-field>
            </div>
            <div class="container-button">
              <span class="fill-remaining-space"></span>
              <button id="backCurrentBusinessDetailButton" type="button" mat-raised-button matStepperPrevious *ngIf="requiresClientDetail" >Back</button>
              <button id="nextCurrentBusinessDetailButton" type="button" mat-raised-button matStepperNext color="primary">Next</button>
            </div>
          </form>
        </mat-step>
        <mat-step [stepControl]="newEntityDetailsGroup" *ngIf="this.productCode !== 'AGS7'"[completed]="newEntityDetailsGroup.valid">          
          <form id="entityDetailsForm" [formGroup]="newEntityDetailsGroup" fxLayout="column">
            <ng-template matStepLabel>New Entity Details</ng-template>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="abnEntityDetailsInput" matInput placeholder="ABN" formControlName="australianCompanyNumber" digitOnly />
              </mat-form-field>
              <mat-error *ngIf="newEntityDetailsGroup.get('australianCompanyNumber').invalid"> {{ getEntityAbnErrorMessage() }}</mat-error>
              <mat-error *ngIf="isInvalidAbnLookup">ABN does not validate, please check the numbers and try again.</mat-error>
              <mat-error *ngIf="isExpired">ABN must be active.</mat-error>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="nameEntityDetailsInput" matInput placeholder="Entity Name" formControlName="entityName" [readonly]="setEntityNameToReadOnly()" />
                <fa-icon [icon]="faCircleNotch" [spin]="true" class="file-status" *ngIf="gettingEntityName"></fa-icon>
              </mat-form-field>
              <mat-form-field fxFlex>
                <mat-label>Business Name</mat-label>
                <select title="State" matNativeControl formControlName="businessName" name="businessName" required >
                  <option *ngFor="let name of businessNames" [value]="name">
                    {{ name }}
                  </option>
                </select>
                <mat-hint>Please select the appropriate Business Name.</mat-hint>
              </mat-form-field>
            </div>
            <br />
            <table aria-label="Matching directors section" *ngIf="this.displayFreeTrialOption" aria-hidden="true">
              <tr>
                <td>Is this a free trial?</td>
                <td>
                  <mat-button-toggle-group name="freeTrialGroup" aria-label="Font Style" formControlName="freeTrial" requried>
                    <mat-button-toggle id="freeTrialYesButton" value="Yes">Yes</mat-button-toggle>
                    <mat-button-toggle id="freeTrialNoButton" value="No">No</mat-button-toggle>
                  </mat-button-toggle-group>
                </td>
              </tr>
            </table>
            <div *ngIf="this.isLiteOrEssentialSubscription" class="input-row" fxLayout="row" fxLayoutGap="20px">
              <table aria-label="Matching directors section" aria-hidden="true">
                <tr>
                  <td>
                    {{ entityDetailsDirectorship }}
                  </td>
                  <td>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="matchingDirectors" required>
                      <mat-button-toggle id="matchingDirectorsYesButton" value="Yes">Yes</mat-button-toggle>
                      <mat-button-toggle id="matchingDirectorsNoButton" value="No">No</mat-button-toggle>
                    </mat-button-toggle-group>
                  </td>
                </tr>

                <tr>
                  <td>Do you require Full Budgets?*</td>
                  <td>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="fullBudgetsRequired" required>
                      <mat-button-toggle id="fullBudgetsRequiredYesButton" value="Yes">Yes</mat-button-toggle>
                      <mat-button-toggle id="fullBudgetsRequiredNoButton" value="No">No</mat-button-toggle>
                    </mat-button-toggle-group>
                  </td>
                </tr>
                <tr>
                  <td>Do you require Payroll?*</td>
                  <td>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="payrollRequired" required>
                      <mat-button-toggle id="payrollRequiredYesButton" value="Yes">Yes</mat-button-toggle>
                      <mat-button-toggle id="payrollRequiredNoButton" value="No">No</mat-button-toggle>
                    </mat-button-toggle-group>
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <div class="container-button">
              <span class="fill-remaining-space"></span>
              <button id="backEntityDetailsButton" type="button" mat-raised-button matStepperPrevious >Back</button>
              <button
                id="nextEntityDetailsButton"
                type="button"
                mat-raised-button
                matStepperNext
                color="primary"
                [disabled]="!newEntityDetailsGroup.valid || isInvalidEntityName"
                (click)="validateEntityDetails()"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step *ngIf="this.isLiteOrEssentialSubscription" [completed]="files.length > 0">
          <ng-template matStepLabel>Supporting Documentation</ng-template>
          <app-supporting-documentation
            (outputDocumentationfilesSuccess)="onUploadSupportingDocumentations($event)"
            [productCode]="productCode"
            [isPartnershipRegistration]="isPartnershipRegistration"
            [isCompanyRegistration]="isCompanyRegistration"
          ></app-supporting-documentation>
          <div class="container-button">
            <span class="fill-remaining-space"></span>
            <button id="backSupportingDocumentationButton" mat-raised-button matStepperPrevious >Back</button>
            <button
              id="nextSupportingDocumentationButton"
              mat-raised-button
              matStepperNext
              color="primary"
              [disabled]="files.length === 0"
              (click)="startCalculateOrderSummary()"
            >
              Next
            </button>
          </div>
        </mat-step>
        <mat-step [stepControl]="fileSetupDetails" *ngIf="this.productCode !== 'AGS7'">
          <form id="fileSetupDetailsForm" [formGroup]="fileSetupDetails" fxLayout="column">
            <ng-template matStepLabel>File Details</ng-template>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <table aria-label="New file required section" aria-hidden="true">
                <tr *ngIf="this.displayNewFileRequiredFileOption">
                  <td>Is a new file required?</td>
                  <td>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="fileRequired" required>
                      <mat-button-toggle id="newFileRequiredYesButton" (click)="fileRequiredChanged('Y')" (keydown)="fileRequiredChanged('Y')" value="Yes">Yes</mat-button-toggle>
                      <mat-button-toggle id="newFileRequiredNoButton" (click)="fileRequiredChanged('N')" (keydown)="fileRequiredChanged('N')" value="No">No</mat-button-toggle>
                    </mat-button-toggle-group>
                  </td>
                </tr>
                <tr *ngIf="this.displayAgrimasterSetupYourFileOption">
                  <td>Do you want Agrimaster to setup your file?</td>
                  <td>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="setupRequired" required>
                      <mat-button-toggle id="setupRequiredYesButton" value="Yes">Yes</mat-button-toggle>
                      <mat-button-toggle id="setupRequiredNoButton" value="No">No</mat-button-toggle>
                    </mat-button-toggle-group>
                  </td>
                </tr>

                <tr *ngIf="this.displayLinkExistingUnlicensedFileOption">
                  <td>Do you want to link to an existing unlicensed file?</td>
                    <td>
                    <mat-button-toggle-group name="fontStyle" aria-label="Font Style" formControlName="linkExistingUnlicensedFile" required>
                      <mat-button-toggle id="useExistingBusinessOptionYesButton" (click)="fileRequiredChanged('Y')" (keydown)="fileRequiredChanged('Y')" value="Yes">Yes</mat-button-toggle>
                      <mat-button-toggle id="useExistingBusinessOptionNoButton" (click)="fileRequiredChanged('N')" (keydown)="fileRequiredChanged('N')" value="No">No</mat-button-toggle>
                    </mat-button-toggle-group>
                    </td>
                </tr>

                <tr *ngIf="this.displayUnlicensedBusinessList">
                  <td>Which Business is this Subscription Required for?*</td>
                  <td>
                    <mat-form-field fxFlex>
                      <select matNativeControl formControlName="businessId" name="businessId" required id="fileDetailsBusinessDropDown">
                        <option *ngFor="let bus of unlicensedFiles" [value]="bus.id">
                          {{ bus.name }}
                        </option>
                      </select>
                    </mat-form-field>
                  </td>
                </tr>
                <tr *ngIf="this.displayFileUpload">
                  <td>
                    <span>Please select a file to upload for this new subscription*</span><br />
                    <mat-hint *ngIf="fileSetupDetails.get('fileRequired').value == 'No' && requiresClientDetail">This file will be uploaded and shared back to you.</mat-hint>
                    <div class="input-row" fxLayout="row" fxLayoutGap="20px">
                      <input
                        id="fileSetupDetailsFileInput"
                        #aginput
                        multiple
                        hidden
                        type="file"
                        onclick="this.value=null"
                        (change)="AgrimasterFileChange($event)"
                        accept=".agm"
                      />
                      <button id="fileSetupDetailsSelectFilesButton" type="button" mat-raised-button color="accent" (click)="aginput.click()">Select File</button>
                      <fa-icon [icon]="faCircleNotch" [spin]="true" class="file-status" *ngIf="selectingAgrimasterFiles"></fa-icon>
                    </div>
                    <br />
                    <div class="error-text" *ngIf="isUploadingAgrimasterFileInvalid">Please upload valid Agrimaster file. Check your file ends with .agm and try again</div>
                  </td>
                  <td>
                    <div class="input-row" fxLayout="row" fxLayoutGap="20px">
                      <mat-form-field fxFlex (click)="aginput.click()">
                        <input
                          id="partnerSuppliedFileInput"
                          matInput
                          placeholder="File Name"
                          formControlName="partnerSuppliedFile"
                          [required]="this.displayFileUpload"
                          readonly
                        />
                      </mat-form-field>
                    </div>
                  </td>
                </tr>
              </table>
            </div>
            <br />
            <div class="container-button">
              <span class="fill-remaining-space"></span>
              <button id="backFileSetupDetailsButton" type="button" mat-raised-button matStepperPrevious>Back</button>
              <button
                id="nextFileSetupDetailsButton"
                mat-raised-button
                matStepperNext
                color="primary"
                type="button"
                [disabled]="!IsFileSetupDetailsValid"
                (click)="startCalculateOrderSummary()"
              >
                Next
              </button>
            </div>
          </form>
        </mat-step>
        <mat-step [completed]="this.IsOrderSummaryCompleted">
          <ng-template matStepLabel>Order Summary</ng-template>
          <app-order-summary
            *ngIf="this.orderSummaryAllowToBeCalculated"
            (outputProrataDetailSuccess)="onOrderSummarySuccess($event)"
            [productCode]="productCode"
            [userName]="this.companyDetailsGroup.get('userName').value"
            [primarySubscriptionId]="this.primarySubscriptionId"
          >
          </app-order-summary>
          <br />
          <div class="container-button">
            <span class="fill-remaining-space"></span>
            <button id="backOrderSummaryButton" mat-raised-button matStepperPrevious >Back</button>
            <button id="nextOrderSummaryButton" mat-raised-button matStepperNext color="primary" >Next</button>
          </div>
        </mat-step>
        <mat-step [completed]="successfulPurchase">
          <form id="paymentDetailsForm" [formGroup]="paymentDetailsGroup" fxLayout="column">
            <ng-template matStepLabel>Payment Details</ng-template>
            <span>We accept Visa and Mastercard only.</span>
            <br />
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <input id="cardNameInput" matInput placeholder="Card Name" formControlName="name" required />
              </mat-form-field>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-form-field fxFlex>
                <mat-label>Credit card</mat-label>
                <wm-stripe-card id="cardNumberInput" matStripe formControlName="card" name="card" hidePostalCode="true" required #formCard> </wm-stripe-card>
                <mat-error *ngIf="formCard.error">{{ formCard.error.message }}</mat-error>

                <mat-hint>Powered by Stripe</mat-hint>
              </mat-form-field>
              <span *ngIf="!successfulPurchase" class="error-text">{{ cardError }}</span>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px" *ngIf="this.isLiteOrEssentialSubscription">
              <mat-checkbox id="finalConfirmationCheckBox" color="accent" labelPosition="after" formControlName="directorshipDisclaimer">
                {{ finalConfirmation }}
              </mat-checkbox>
            </div>
            <div class="input-row" fxLayout="row" fxLayoutGap="20px">
              <mat-checkbox id="finalTermsAndConditionsConfirmationCheckBox" color="accent" labelPosition="after" formControlName="termsAndConditionsDisclaimer">
                I have read and agree to the <a href="https://mastergroup.net.au/terms-and-conditions/#SAAS" target="_blank" rel="noopener">Terms and Conditions.</a>
              </mat-checkbox>
            </div>
            <br />
            <div class="container-button">
              <span class="fill-remaining-space"></span>
              <button id="backPaymentDetailsButton" type="button" mat-raised-button matStepperPrevious [disabled]="purchaseProcessing">Back</button>
              <button
                *ngIf="paymentPendingAllowed"
                id="paymentPendingButton"
                type="button"
                mat-raised-button
                matStepperNext
                color="accent"
                [disabled]="!paymentDetailsGroup.get('termsAndConditionsDisclaimer').value || !paymentDetailsGroup.get('directorshipDisclaimer').value || purchaseProcessing"
                (click)="buyWithPendingPayment(stepper)"
              >
                Payment Pending
              </button>
              <button
                id="completePurchaseButton"
                type="button"
                mat-raised-button
                matStepperNext
                color="primary"
                [disabled]="
                  !paymentDetailsGroup.get('name').value ||
                  !paymentDetailsGroup.get('termsAndConditionsDisclaimer').value ||
                  !paymentDetailsGroup.get('directorshipDisclaimer').value ||
                  formCard.empty ||
                  !formCard.complete ||
                  formCard.error ||
                  purchaseProcessing
                "
                (click)="buyWithCardPayment(stepper)"
              >
                Complete Purchase
              </button>
              <fa-icon [icon]="faCircleNotch" [spin]="true" class="file-status" *ngIf="purchaseProcessing"></fa-icon>
            </div>
          </form>
        </mat-step>
      </mat-vertical-stepper>
      <br />
      <button id="returnToShopButton" [disabled]="purchaseProcessing" mat-raised-button color="warn" (click)="returnToShop()">Return to Shop</button>
    </div>

    <app-essentials-ineligible *ngIf="ineligibilityReason.length > 0" [ineligibilityReason]="ineligibilityReason" [productCode]="productCode"> </app-essentials-ineligible>

    <app-purchase-complete
      *ngIf="successfulPurchase"
      [invoiceUrl]="invoiceUrl"
      [purchaserIsStaffMember]="purchaserIsStaffMember"
      [paymentIsPending]="paymentIsPending"
      [productCode]="productCode"
      [onBehalfOfClient]="onBehalfOfClient"
    >
    </app-purchase-complete>
  </div>
</div>
