import { Injectable }  from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable()
export class AppConfigService {
    static settings: IAppConfig;

    constructor(private http: HttpClient) {}

    load() {

        const jsonFile = `assets/appConfig.json`;
        return new Promise<void>((resolve, reject) => {
            this.http
            .get(jsonFile)
            .toPromise()
            .then((response : IAppConfig) => {
               AppConfigService.settings = <IAppConfig>response;

               if(!environment.production)
               {
                console.log('Config Loaded');
                console.log( AppConfigService.settings);
               }

               resolve();

            }).catch((response: any) => {
               reject(`Could not load the config file, ` + response.message);
            });
        });
    }

    getApiUrl(): string {
        return AppConfigService.settings.backend;
    }
}

export interface IAppConfig {
  backend: string;
  signalr: string;
  stripeKey: string;
  configInterval: number;
  captchaSiteKey: string;
  configCat: string;
}
